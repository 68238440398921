import { reduce } from 'lodash/collection';

import {
  ACTIVITY_REPORTS,
  ALL_USERS_OTHER_THAN_CRA_DASHBOARD,
  AUDIT_LOG_ALL_STUDY_DATA_REPORT,
  CRA_AUDIT_REPORT,
  FINANCE_REPORTS,
  INVOICE_DETAILS_EXPORT_REPORT,
  LEADERSHIP_METRICS_REPORTS,
  OPEN_ENCOUNTERS_DASHBOARD,
  PRE_SCREENING_REPORTS,
  ROLES_FINANCE_AND_FINANCE_ANALYST,
  SITE_PERFORMANCE_DASHBOARD,
  SSU_KPI_DASHBOARD,
  SSU_METRICS_REPORTS,
  STUDY_REPORTS
} from '../../../constants/accessToPages';
import { useCurrentRoute } from '../../root/router';

import { useAllowedDashboards } from './modeReportsAccessService';
import { optionsToIds } from './modeReportsFiltersService';

export const DATE_FORMAT = 'YYYY-MM-DD';

const STUDY_CONDUCT = 'Study Conduct';
const STUDY_RECRUITMENT = 'Study Recruitment';
const STUDY_METRICS = 'Study Metrics';
const SITES = 'Sites';
const FINANCIAL = 'Financial';
const AUDIT = 'Audit';

export const SECTIONS = Object.freeze([STUDY_CONDUCT, STUDY_RECRUITMENT, STUDY_METRICS, SITES, FINANCIAL, AUDIT]);

export const dashboardsMap = {
  study: {
    name: 'Study',
    description: 'Study milestone and performance for a single study and related sites',
    apiId: 'study',
    access: [STUDY_REPORTS],
    filters: [
      {
        filterKey: 'studies',
        filterLabel: 'Studies',
        filterType: 'select',
        onChange: 'ON_STUDY_SELECT_CHANGE'
      },
      {
        filterKey: 'pcn',
        filterLabel: 'PCNs',
        filterType: 'select',
        onChange: 'ON_PCN_SELECT_CHANGE'
      },
      {
        filterKey: 'sites',
        filterLabel: 'Sites',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE',
        onInit: 'SET_DATA_FILTER_SITES_BY_CANCELED_SSUS'
      }
    ],
    navSection: STUDY_CONDUCT
  },
  site: {
    name: 'Site',
    description: 'Site performance overview including projected study events and workload',
    autosavedParameters: true,
    apiId: 'site',
    access: [ALL_USERS_OTHER_THAN_CRA_DASHBOARD],
    filters: [
      {
        filterKey: 'sites',
        filterLabel: 'Sites',
        filterType: 'multiselect',
        onChange: 'ON_SITE_SELECT_STUDIES_PCNS'
      },
      {
        filterKey: 'ssu_status',
        filterLabel: 'SSU Status',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'studies',
        filterLabel: 'Studies',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'pcn',
        filterLabel: 'Pcns',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'start_date',
        filterLabel: 'Start Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATA_WITH_START_AND_END_DATE_DEFAULT_3_MONTHS_AGO'
      },
      {
        filterKey: 'end_date',
        filterLabel: 'End Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATE_TO_NOW'
      }
    ],
    navSection: SITES
  },
  calendar: {
    name: 'Calendar',
    description: 'Calendar event counts, details, and status for a time period across studies and sites',
    apiId: 'calendar',
    access: [ACTIVITY_REPORTS],
    filters: [
      {
        filterKey: 'studies',
        filterLabel: 'Studies',
        filterType: 'multiselect',
        onChange: 'ON_STUDY_CHANGE_WITH_STUDY_TYPE_AND_AREAS_FILTER_CANCELED_SSUS'
      },
      {
        filterKey: 'pcn',
        filterLabel: 'Pcns',
        filterType: 'multiselect',
        onChange: 'ON_PCN_CHANGE_WITH_STUDY_TYPE_AND_AREAS_FILTER_CANCELED_SSUS'
      },
      {
        filterKey: 'sites',
        filterLabel: 'Sites',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE',
        onInit: 'SET_DATA_FILTER_SITES_BY_CANCELED_SSUS'
      },
      {
        filterKey: 'therapeutic_areas',
        filterLabel: 'Therapeutic areas',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'study_type',
        filterLabel: 'Study type',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'appointment_statuses',
        filterLabel: 'Appointment statuses',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'appointment_types',
        filterLabel: 'Appointment types',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'start_date',
        filterLabel: 'Start Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATA_WITH_START_AND_END_DATE_DEFAULT_6_MONTH_AGO'
      },
      {
        filterKey: 'end_date',
        filterLabel: 'End Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATE_TO_NOW'
      }
    ],
    navSection: STUDY_CONDUCT
  },
  milestones: {
    name: 'Milestones',
    description: 'Predicted vs. actual study and site milestone comparison for specified time period',
    apiId: 'milestones',
    access: [ACTIVITY_REPORTS],
    filters: [
      {
        filterKey: 'studies',
        filterLabel: 'Studies',
        filterType: 'multiselect',
        onChange: 'ON_STUDY_CHANGE_WITH_STUDY_TYPE_AND_AREAS_FILTER_CANCELED_SSUS'
      },
      {
        filterKey: 'pcn',
        filterLabel: 'Pcns',
        filterType: 'multiselect',
        onChange: 'ON_PCN_CHANGE_WITH_STUDY_TYPE_AND_AREAS_FILTER_CANCELED_SSUS'
      },
      {
        filterKey: 'sites',
        filterLabel: 'Sites',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE',
        onInit: 'SET_DATA_FILTER_SITES_BY_CANCELED_SSUS'
      },
      {
        filterKey: 'ssu_status',
        filterLabel: 'SSU Status',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'therapeutic_areas',
        filterLabel: 'Therapeutic areas',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'study_type',
        filterLabel: 'Study type',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'milestones',
        filterLabel: 'Milestones',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'start_date',
        filterLabel: 'Start Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATA_WITH_START_AND_END_DATE_DEFAULT_6_MONTH_AGO'
      },
      {
        filterKey: 'end_date',
        filterLabel: 'End Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATE_TO_NOW'
      }
    ],
    navSection: STUDY_CONDUCT
  },
  prescreening: {
    name: 'Prescreening',
    description:
      'Summary and details of patient prescreening activities (chart review and outreach) for specified time period',
    apiId: 'pre-screening-encounters',
    access: [PRE_SCREENING_REPORTS],
    filters: [
      {
        filterKey: 'studies',
        filterLabel: 'Studies',
        filterType: 'multiselect',
        onChange: 'ON_STUDY_MULTISELECT_CHANGE'
      },
      {
        filterKey: 'pcn',
        filterLabel: 'Pcns',
        filterType: 'multiselect',
        onChange: 'ON_PCN_MULTISELECT_CHANGE_FILTER_CANCELED_SSUS'
      },
      {
        filterKey: 'sites',
        filterLabel: 'Sites',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'ssu_status',
        filterLabel: 'SSU status',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'aggregation',
        filterLabel: 'Aggregation',
        filterType: 'select',
        clearable: false,
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'start_date',
        filterLabel: 'Start Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATA_WITH_START_AND_END_DATE_DEFAULT_6_MONTH_AGO'
      },
      {
        filterKey: 'end_date',
        filterLabel: 'End Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATE_TO_NOW'
      }
    ],
    navSection: STUDY_RECRUITMENT
  },
  encounters: {
    name: 'Encounters',
    description: 'Visit counts and details by status, dates for specified studies or sites across a time period',
    apiId: 'encounters',
    access: [ACTIVITY_REPORTS],
    filters: [
      {
        filterKey: 'studies',
        filterLabel: 'Studies',
        filterType: 'multiselect',
        onChange: 'ON_STUDY_CHANGE_WITH_STUDY_TYPE_AND_AREAS_FILTER_CANCELED_SSUS'
      },
      {
        filterKey: 'pcn',
        filterLabel: 'Pcns',
        filterType: 'multiselect',
        onChange: 'ON_PCN_CHANGE_WITH_STUDY_TYPE_AND_AREAS_FILTER_CANCELED_SSUS'
      },
      {
        filterKey: 'sites',
        filterLabel: 'Sites',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE',
        onInit: 'SET_DATA_FILTER_SITES_BY_CANCELED_SSUS'
      },
      {
        filterKey: 'ssu_status',
        filterLabel: 'SSU status',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'therapeutic_areas',
        filterLabel: 'Therapeutic areas',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'study_type',
        filterLabel: 'Study type',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'status',
        filterLabel: 'Status',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'record_type',
        filterLabel: 'Record type',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'start_date',
        filterLabel: 'Start Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATA_WITH_START_AND_END_DATE_DEFAULT_6_MONTH_AGO'
      },
      {
        filterKey: 'end_date',
        filterLabel: 'End Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATE_TO_NOW'
      }
    ],
    navSection: STUDY_CONDUCT
  },
  procedures: {
    name: 'Procedures',
    description:
      'Count and status of procedures for specified studies, sites and time period to identify incomplete procedures',
    apiId: 'procedures',
    access: [ACTIVITY_REPORTS],
    filters: [
      {
        filterKey: 'studies',
        filterLabel: 'Studies',
        filterType: 'multiselect',
        onChange: 'ON_STUDY_CHANGE_WITH_STUDY_TYPE_AND_AREAS_FILTER_CANCELED_SSUS'
      },
      {
        filterKey: 'pcn',
        filterLabel: 'Pcns',
        filterType: 'multiselect',
        onChange: 'ON_PCN_CHANGE_WITH_STUDY_TYPE_AND_AREAS_FILTER_CANCELED_SSUS'
      },
      {
        filterKey: 'sites',
        filterLabel: 'Sites',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE',
        onInit: 'SET_DATA_FILTER_SITES_BY_CANCELED_SSUS'
      },
      {
        filterKey: 'ssu_status',
        filterLabel: 'SSU status',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'therapeutic_areas',
        filterLabel: 'Therapeutic areas',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'study_type',
        filterLabel: 'Study type',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'status',
        filterLabel: 'Status',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'record_type',
        filterLabel: 'Record type',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'start_date',
        filterLabel: 'Start Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATA_WITH_START_AND_END_DATE_DEFAULT_6_MONTH_AGO'
      },
      {
        filterKey: 'end_date',
        filterLabel: 'End Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATE_TO_NOW'
      }
    ],
    navSection: STUDY_CONDUCT
  },
  initial_consent_icf_reconsenting: {
    name: 'Initial Consent and ICF Reconsenting Report',
    description: 'Informed consent dates and time with protocol and ICF versions for specified studies, sites',
    apiId: 'initial-consent-icf-reconsenting',
    access: [ALL_USERS_OTHER_THAN_CRA_DASHBOARD],
    filters: [
      {
        filterKey: 'studies',
        filterLabel: 'Studies',
        filterType: 'multiselect',
        onChange: 'ON_STUDY_MULTISELECT_CHANGE_FILTER_CANCELED_SSUS'
      },
      {
        filterKey: 'pcn',
        filterLabel: 'Pcns',
        filterType: 'multiselect',
        onChange: 'ON_PCN_MULTISELECT_CHANGE_FILTER_CANCELED_SSUS'
      },
      {
        filterKey: 'sites',
        filterLabel: 'Sites',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE',
        onInit: 'SET_DATA_FILTER_SITES_BY_CANCELED_SSUS'
      },
      {
        filterKey: 'start_date',
        filterLabel: 'Start Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATA_WITH_START_AND_END_DATE_DEFAULT_WEEK_AGO'
      },
      {
        filterKey: 'end_date',
        filterLabel: 'End Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATE_TO_NOW'
      },
      {
        filterKey: 'ssu_status',
        filterLabel: 'SSU Status',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      }
    ],
    navSection: STUDY_CONDUCT
  },
  adverse_events: {
    name: 'Adverse Events',
    description: 'Adverse events created for specified studies, sites across a time period',
    apiId: 'adverse-events',
    access: [ALL_USERS_OTHER_THAN_CRA_DASHBOARD],
    filters: [
      {
        filterKey: 'studies',
        filterLabel: 'Studies',
        filterType: 'multiselect',
        onChange: 'ON_STUDY_MULTISELECT_CHANGE_FILTER_CANCELED_SSUS'
      },
      {
        filterKey: 'pcn',
        filterLabel: 'Pcns',
        filterType: 'multiselect',
        onChange: 'ON_PCN_MULTISELECT_CHANGE_FILTER_CANCELED_SSUS'
      },
      {
        filterKey: 'sites',
        filterLabel: 'Sites',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE',
        onInit: 'SET_DATA_FILTER_SITES_BY_CANCELED_SSUS'
      },
      {
        filterKey: 'start_date',
        filterLabel: 'Start Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATA_WITH_START_AND_END_DATE_DEFAULT_WEEK_AGO'
      },
      {
        filterKey: 'end_date',
        filterLabel: 'End Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATE_TO_NOW'
      },
      {
        filterKey: 'ssu_status',
        filterLabel: 'SSU Status',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      }
    ],
    navSection: STUDY_CONDUCT
  },
  open_encounters: {
    name: 'Open Encounters Dashboard',
    description: 'Details for open encounters, including aging and site performance',
    autosavedParameters: true,
    apiId: 'open-encounters',
    access: [OPEN_ENCOUNTERS_DASHBOARD],
    filters: [
      {
        filterKey: 'studies',
        filterLabel: 'Studies',
        filterType: 'multiselect',
        onChange: 'ON_STUDY_MULTISELECT_CHANGE'
      },
      {
        filterKey: 'pcn',
        filterLabel: 'PCNs',
        filterType: 'multiselect',
        onChange: 'ON_PCN_MULTISELECT_CHANGE'
      },
      {
        filterKey: 'sites',
        filterLabel: 'Sites',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'ssu_status',
        filterLabel: 'SSU status',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'therapeutic_areas',
        filterLabel: 'Therapeutic Area',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'indication',
        filterLabel: 'Indication',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'central_local',
        filterLabel: 'Central/Local',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'encounter_type',
        filterLabel: 'Encounter Type',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      }
    ],
    navSection: STUDY_CONDUCT
  },
  finance: {
    name: 'Finance',
    description: 'Study and site financial performance for scheduled and completed encounters across a time period',
    apiId: 'finance',
    access: [FINANCE_REPORTS],
    filters: [
      {
        filterKey: 'studies',
        filterLabel: 'Studies',
        filterType: 'multiselect',
        onChange: 'ON_STUDY_CHANGE_WITH_STUDY_TYPE_AND_AREAS'
      },
      {
        filterKey: 'pcn',
        filterLabel: 'Pcns',
        filterType: 'multiselect',
        onChange: 'ON_PCN_CHANGE_WITH_STUDY_TYPE_AND_AREAS'
      },
      {
        filterKey: 'sites',
        filterLabel: 'Sites',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'ssu_status',
        filterLabel: 'SSU Status',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'therapeutic_areas',
        filterLabel: 'Therapeutic areas',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'study_type',
        filterLabel: 'Study type',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'levels',
        filterLabel: 'Levels',
        filterType: 'select',
        clearable: false,
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'aggregation',
        filterLabel: 'Aggregation',
        filterType: 'select',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'start_date',
        filterLabel: 'Start Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATA_WITH_START_AND_END_DATE_DEFAULT_6_MONTH_AGO'
      },
      {
        filterKey: 'end_date',
        filterLabel: 'End Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATE_TO_NOW'
      }
    ],
    navSection: FINANCIAL
  },
  accrued_revenue: {
    name: 'Accrued Revenue',
    description: 'Summary of total revenue generated per SSU, per month or calendar year',
    apiId: 'accrued-revenue',
    access: [ROLES_FINANCE_AND_FINANCE_ANALYST],
    filters: [
      {
        filterKey: 'studies',
        filterLabel: 'Studies',
        filterType: 'multiselect',
        onChange: 'ON_STUDY_MULTISELECT_CHANGE'
      },
      {
        filterKey: 'pcn',
        filterLabel: 'PCNs',
        filterType: 'multiselect',
        onChange: 'ON_PCN_MULTISELECT_CHANGE'
      },
      {
        filterKey: 'sites',
        filterLabel: 'Sites',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'protocol_id',
        filterLabel: 'Protocol ID',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'start_date',
        filterLabel: 'Revenue Start Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATA_WITH_START_AND_END_DATE_DEFAULT_BEGINNING_OF_CURRENT_MONTH'
      },
      {
        filterKey: 'end_date',
        filterLabel: 'Revenue End Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATE_TO_NOW'
      },
      {
        filterKey: 'aggregation',
        filterLabel: 'Aggregation',
        filterType: 'select',
        onChange: 'ON_SELECT_CHANGE'
      }
    ],
    navSection: FINANCIAL
  },
  aged_accounts_receivable: {
    name: 'Aged Accounts Receivable (AR)',
    description: 'Summary of all outstanding AR balances per SSU',
    apiId: 'aged-accounts-receivable',
    access: [ROLES_FINANCE_AND_FINANCE_ANALYST],
    filters: [
      {
        filterKey: 'studies',
        filterLabel: 'Studies',
        filterType: 'multiselect',
        onChange: 'ON_STUDY_MULTISELECT_CHANGE_NON_FILTERED'
      },
      {
        filterKey: 'pcn',
        filterLabel: 'PCNs',
        filterType: 'multiselect',
        onChange: 'ON_PCN_MULTISELECT_CHANGE_NON_FILTERED'
      },
      {
        filterKey: 'sites',
        filterLabel: 'Sites',
        filterType: 'multiselect',
        onChange: 'ON_SITE_SELECT_STUDIES_PCNS_NON_FILTERED'
      },
      {
        filterKey: 'protocol_id',
        filterLabel: 'Protocol ID',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'start_date',
        filterLabel: 'Revenue Start Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATA_WITH_START_AND_END_DATE_DEFAULT_BEGINNING_OF_CURRENT_MONTH'
      },
      {
        filterKey: 'end_date',
        filterLabel: 'Revenue End Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATE_TO_NOW'
      }
    ],
    navSection: FINANCIAL
  },
  invoice_details_export: {
    name: 'Invoice Details Export',
    description: 'Exportable invoice details',
    apiId: 'invoice-details-export',
    access: [INVOICE_DETAILS_EXPORT_REPORT],
    filters: [
      {
        filterKey: 'studies',
        filterLabel: 'Study',
        filterType: 'multiselect',
        onChange: 'ON_STUDY_MULTISELECT_CHANGE_FILTER_CANCELED_SSUS'
      },
      {
        filterKey: 'pcn',
        filterLabel: 'PCN',
        filterType: 'multiselect',
        onChange: 'ON_PCN_MULTISELECT_CHANGE_FILTER_CANCELED_SSUS'
      },
      {
        filterKey: 'sites',
        filterLabel: 'Site',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE',
        onInit: 'SET_DATA_FILTER_SITES_BY_CANCELED_SSUS'
      },
      {
        filterKey: 'budget_event_type',
        filterLabel: 'Budget Event Type',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'start_date',
        filterLabel: 'Start Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATA_WITH_START_AND_END_DATE_DEFAULT_BEGINNING_OF_CURRENT_MONTH'
      },
      {
        filterKey: 'end_date',
        filterLabel: 'End Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATE_TO_NOW'
      },
      {
        filterKey: 'event_status',
        filterLabel: 'Event Status',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      }
    ],
    navSection: FINANCIAL
  },
  study_financial_summary: {
    name: 'Study Financial Summary',
    description: 'Summary of earned revenue, payment reconciliation amounts, and outstanding balances, per SSU',
    apiId: 'study-financial-summary',
    access: [ROLES_FINANCE_AND_FINANCE_ANALYST],
    filters: [
      {
        filterKey: 'studies',
        filterLabel: 'Studies',
        filterType: 'multiselect',
        onChange: 'ON_STUDY_MULTISELECT_CHANGE'
      },
      {
        filterKey: 'pcn',
        filterLabel: 'PCNs',
        filterType: 'multiselect',
        onChange: 'ON_PCN_MULTISELECT_CHANGE'
      },
      {
        filterKey: 'sites',
        filterLabel: 'Sites',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'protocol_id',
        filterLabel: 'Protocol ID',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'start_date',
        filterLabel: 'Revenue Start Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATA_WITH_START_AND_END_DATE_DEFAULT_BEGINNING_OF_CURRENT_MONTH'
      },
      {
        filterKey: 'end_date',
        filterLabel: 'Revenue End Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATE_TO_NOW'
      }
    ],
    navSection: FINANCIAL
  },
  audit_log_all_study_data: {
    name: 'Audit Log - All Study Data',
    description: 'All fields updated in IntElligo with original and new values and associated user name',
    apiId: 'audit-log-all-study-data',
    access: [AUDIT_LOG_ALL_STUDY_DATA_REPORT],
    filters: [
      {
        filterKey: 'studies',
        filterLabel: 'Studies',
        filterType: 'multiselect',
        onChange: 'ON_STUDY_MULTISELECT_CHANGE'
      },
      {
        filterKey: 'pcn',
        filterLabel: 'Pcns',
        filterType: 'multiselect',
        onChange: 'ON_PCN_MULTISELECT_CHANGE'
      },
      {
        filterKey: 'sites',
        filterLabel: 'Sites',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'start_date',
        filterLabel: 'Start Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATA_WITH_START_AND_END_DATE_DEFAULT_WEEK_AGO'
      },
      {
        filterKey: 'end_date',
        filterLabel: 'End Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATE_TO_NOW'
      }
    ],
    navSection: AUDIT
  },
  cra_audit: {
    name: 'CRA Audit',
    description:
      'List of all IntElligo fields and corresponding values for specified studies, sites and time period to track changes',
    apiId: 'cra-audit',
    access: [CRA_AUDIT_REPORT],
    filters: [
      {
        filterKey: 'studies',
        filterLabel: 'Studies',
        filterType: 'multiselect',
        onChange: 'ON_STUDY_CHANGE_WITH_SITE'
      },
      {
        filterKey: 'sites',
        filterLabel: 'Sites',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'start_date',
        filterLabel: 'Start Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATA_WITH_START_AND_END_DATE_DEFAULT_WEEK_AGO'
      },
      {
        filterKey: 'end_date',
        filterLabel: 'End Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATE_TO_NOW'
      }
    ],
    navSection: AUDIT
  },
  site_performance: {
    name: 'Site Performance',
    description: 'Site past performance overview including enrollment performance and retention rate',
    autosavedParameters: true,
    apiId: 'site-performance',
    access: [SITE_PERFORMANCE_DASHBOARD],
    filters: [
      {
        filterKey: 'sites',
        filterLabel: 'Sites',
        filterType: 'multiselect',
        onChange: 'ON_SITE_SELECT_STUDIES_PCNS'
      },
      {
        filterKey: 'ssu_status',
        filterLabel: 'SSU status',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'studies',
        filterLabel: 'Studies',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'pcn',
        filterLabel: 'PCNs',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'start_date',
        filterLabel: 'Start Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATA_WITH_START_AND_END_DATE_DEFAULT_1_YEAR_AGO'
      },
      {
        filterKey: 'end_date',
        filterLabel: 'End Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATE_TO_NOW'
      }
    ],
    navSection: SITES
  },
  ssu_kpi: {
    name: 'Study and Site KPIs',
    description: 'Generate study and site level measurements of key performance (KPIs)',
    autosavedParameters: true,
    apiId: 'ssu-kpi',
    access: [SSU_KPI_DASHBOARD],
    filters: [
      {
        filterKey: 'studies',
        filterLabel: 'Studies',
        filterType: 'multiselect',
        onChange: 'ON_STUDY_MULTISELECT_CHANGE'
      },
      {
        filterKey: 'pcn',
        filterLabel: 'PCNs',
        filterType: 'multiselect',
        onChange: 'ON_PCN_MULTISELECT_CHANGE'
      },
      {
        filterKey: 'sites',
        filterLabel: 'Sites',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'therapeutic_areas',
        filterLabel: 'Therapeutic areas',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'indication',
        filterLabel: 'Indication',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'ssu_status',
        filterLabel: 'SSU status',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'start_date',
        filterLabel: 'Sponsor Greenlight From',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onClear: 'ON_SELECT_DATE_CLEAR',
        onInit: 'SET_DATA_WITH_START_AND_END_DATE_DEFAULT_6_MONTH_AGO'
      },
      {
        filterKey: 'end_date',
        filterLabel: 'Sponsor Greenlight To',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onClear: 'ON_SELECT_DATE_CLEAR',
        onInit: 'SET_DATE_TO_NOW'
      }
    ],
    navSection: STUDY_METRICS
  },
  study_metrics: {
    name: 'Study Metrics',
    description: 'Count of published and greenlight across multiple studies and a time period',
    apiId: 'study-metrics',
    access: [LEADERSHIP_METRICS_REPORTS],
    filters: [
      {
        filterKey: 'studies',
        filterLabel: 'Studies',
        filterType: 'multiselect',
        onChange: 'ON_STUDY_METRICS_STUDY_CHANGE'
      },
      {
        filterKey: 'pcn',
        filterLabel: 'Pcns',
        filterType: 'multiselect',
        onChange: 'ON_STUDY_METRICS_PCN_CHANGE'
      },
      {
        filterKey: 'therapeutic_areas',
        filterLabel: 'Therapeutic areas',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'indication',
        filterLabel: 'Indication',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'start_date',
        filterLabel: 'Published Date From',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATA_WITH_START_AND_END_DATE_DEFAULT_1_YEAR_AGO'
      },
      {
        filterKey: 'end_date',
        filterLabel: 'Published Date To',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATE_TO_NOW'
      },
      {
        filterKey: 'study_status',
        filterLabel: 'Study Status',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      }
    ],
    navSection: STUDY_METRICS
  },
  ssu_metrics: {
    name: 'Study Site Metrics',
    description:
      'Summary of study-site (SSU) performance and status for specified SSUs, including published and greenlight',
    apiId: 'ssu-metrics',
    access: [SSU_METRICS_REPORTS],
    filters: [
      {
        filterKey: 'studies',
        filterLabel: 'Studies',
        filterType: 'multiselect',
        onChange: 'ON_STUDY_MULTISELECT_CHANGE'
      },
      {
        filterKey: 'pcn',
        filterLabel: 'Pcns',
        filterType: 'multiselect',
        onChange: 'ON_PCN_MULTISELECT_CHANGE'
      },
      {
        filterKey: 'sites',
        filterLabel: 'Sites',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'ssu_status',
        filterLabel: 'SSU status',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      }
    ],
    navSection: STUDY_METRICS
  },
  patient_metrics: {
    name: 'Patient Metrics',
    description:
      'Visual summary of patients for specified studies and sites, including status, demographics, location and trends',
    apiId: 'patient-metrics',
    access: [LEADERSHIP_METRICS_REPORTS],
    filters: [
      {
        filterKey: 'studies',
        filterLabel: 'Studies',
        filterType: 'multiselect',
        onChange: 'ON_STUDY_MULTISELECT_CHANGE'
      },
      {
        filterKey: 'pcn',
        filterLabel: 'Pcns',
        filterType: 'multiselect',
        onChange: 'ON_PCN_MULTISELECT_CHANGE'
      },
      {
        filterKey: 'sites',
        filterLabel: 'Sites',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'ssu_status',
        filterLabel: 'SSU status',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'patient_status',
        filterLabel: 'Patient status',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      },
      {
        filterKey: 'therapeutic_areas',
        filterLabel: 'Therapeutic areas',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE'
      }
    ],
    navSection: STUDY_METRICS
  },
  encounter_metrics: {
    name: 'Encounter Metrics',
    description: 'Summary of visit counts and status by type for specified studies, sites and time period',
    apiId: 'encounter-metrics',
    access: [LEADERSHIP_METRICS_REPORTS],
    filters: [
      {
        filterKey: 'studies',
        filterLabel: 'Studies',
        filterType: 'multiselect',
        onChange: 'ON_STUDY_MULTISELECT_CHANGE_FILTER_CANCELED_SSUS'
      },
      {
        filterKey: 'pcn',
        filterLabel: 'Pcns',
        filterType: 'multiselect',
        onChange: 'ON_PCN_MULTISELECT_CHANGE_FILTER_CANCELED_SSUS'
      },
      {
        filterKey: 'sites',
        filterLabel: 'Sites',
        filterType: 'multiselect',
        onChange: 'ON_SELECT_CHANGE',
        onInit: 'SET_DATA_FILTER_SITES_BY_CANCELED_SSUS'
      },
      {
        filterKey: 'start_date',
        filterLabel: 'Start Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATA_WITH_START_AND_END_DATE_DEFAULT_1_YEAR_AGO'
      },
      {
        filterKey: 'end_date',
        filterLabel: 'End Date',
        filterType: 'date picker',
        onChange: 'ON_SELECT_DATE_CHANGE',
        onInit: 'SET_DATE_TO_NOW'
      }
    ],
    navSection: STUDY_METRICS
  }
};

export const isValidParameter = param => {
  if (Array.isArray(param)) {
    return param.length > 0;
  } else if (param?.constructor === Object) {
    return Object.keys(param).length > 0;
  } else if (param === '') {
    return true;
  }
  return !!param;
};

export function usePageName(reportKey) {
  const filteredDashboards = useAllowedDashboards();
  const currentRoute = useCurrentRoute();
  if (filteredDashboards[reportKey]?.name && currentRoute.key !== 'Home') {
    return filteredDashboards[reportKey]?.name;
  }
  if (!filteredDashboards[reportKey]?.name && currentRoute.key !== 'Home') {
    return 'Dashboards';
  }
  return 'Home';
}

export function getApiParam(reportKey) {
  return dashboardsMap[reportKey].apiId;
}

export function generatePayload(data) {
  return reduce(
    data,
    function(result, value, key) {
      if (Array.isArray(value)) {
        result[key] = optionsToIds(value);
      } else if (typeof value === 'string') {
        result[key] = value;
      } else {
        result[key] = value?.id;
      }
      return result;
    },
    {}
  );
}
