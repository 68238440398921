import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { CLOSED } from '../../../../constants/ssuStatuses';
import {
  ROLE_PATIENT_LIAISON,
  ROLE_PATIENT_REPRESENTATIVE,
  ROLE_PRINCIPAL_INVESTIGATOR,
  ROLE_RECRUITER,
  ROLE_STUDY_MANAGER,
  ROLE_SYSTEM_ADMINISTRATOR
} from '../../../../constants/userRoles';
import { userHasRole } from '../../../../services/auth';

import StartEncounterButton from './StartEncounterButton';
import { generateLink } from './worklistService';

function Button({ state }) {
  if (state === 'TO_DO') {
    return <i className="material-icons">add</i>;
  }
  if (state === 'NOT_REQUIRED') {
    return <i className="glyphicon glyphicon-ok worklist-icon-grey" />;
  }
  if (state === 'UNAVAILABLE') {
    return <span />;
  }
  if (state === 'DONE') {
    return <i className="glyphicon glyphicon-ok green" />;
  }
}

export default function WorklistButtonOrLink({ type, studySiteStatus, disabled, ...props }) {
  const isAllowed = useMemo(
    function() {
      if (!userHasRole(ROLE_SYSTEM_ADMINISTRATOR) && studySiteStatus === CLOSED) {
        return false;
      }
      return userHasRole([
        ROLE_SYSTEM_ADMINISTRATOR,
        ROLE_STUDY_MANAGER,
        ROLE_RECRUITER,
        ROLE_PATIENT_REPRESENTATIVE,
        ROLE_PATIENT_LIAISON,
        ROLE_PRINCIPAL_INVESTIGATOR
      ]);
    },
    [studySiteStatus]
  );
  if (props.linkConstructor) {
    if (disabled) {
      return (
        <span className={props.state === 'TO_DO' ? 'add-btn' : ''}>
          <Button state={props.state} />
        </span>
      );
    } else {
      const link = generateLink(props.linkConstructor);
      return (
        <Link className={props.state === 'TO_DO' ? 'add-btn' : ''} to={link}>
          <Button state={props.state} />
        </Link>
      );
    }
  } else {
    return (
      isAllowed && (
        <StartEncounterButton {...props} disabled={disabled}>
          <Button state={props.state} />
        </StartEncounterButton>
      )
    );
  }
}
