export const ACTIVITY = Symbol('ACTIVITY');
export const ACTIVITY_REPORTS = Symbol('ACTIVITY_REPORTS');
export const BSS = Symbol('BSS');
export const CALENDAR = Symbol('CALENDAR');
export const CAS = Symbol('CAS');
export const CUSTOM_REPORTS = Symbol('CUSTOM_REPORTS');
export const REIMBURSEMENT_WORKLIST = Symbol('REIMBURSEMENT_WORKLIST');
export const ENROLMENTS = Symbol('ENROLMENTS');
export const FINANCE = Symbol('FINANCE');
export const FINANCE_REPORTS = Symbol('FINANCE_REPORTS');
export const INVOICE_DETAILS_EXPORT_REPORT = Symbol('INVOICE_DETAILS_EXPORT_REPORT');
export const GLOBAL_SEARCH = Symbol('GLOBAL_SEARCH');
export const ALL_USERS_OTHER_THAN_CRA_DASHBOARD = Symbol('ALL_USERS_OTHER_THAN_CRA_DASHBOARD');
export const SITE_PERFORMANCE_DASHBOARD = Symbol('SITE_PERFORMANCE_DASHBOARD');
export const HELP = Symbol('HELP');
export const INVOICE = Symbol('INVOICE');
export const RECONCILE = Symbol('RECONCILE');
export const LOAD_PATIENTS = Symbol('LOAD_PATIENTS');
export const PATIENT_BULK_UPDATE = Symbol('PATIENT_BULK_UPDATE');
export const MILESTONE_REPORT = Symbol('MILESTONE_REPORT');
export const MILESTONES_DASHBORDS = Symbol('MILESTONES_DASHBORDS');
export const DASHBOARDS = Symbol('DASHBOARDS');
export const PI_WORK_LIST = Symbol('PI_WORK_LIST');
export const SM_WORKLIST = Symbol('SM_WORK_LIST');
export const SCHEDULING_WORK_LIST = Symbol('SCHEDULING_WORK_LIST');
export const PL = Symbol('PL');
export const LOGS = Symbol('LOGS');
export const PLA = Symbol('PLA');
export const PS = Symbol('PS');
export const REVENUE = Symbol('REVENUE');
export const PRE_SCREEN = Symbol('PRE_SCREEN');
export const PROJECTED_REVENUE = Symbol('PROJECTED_REVENUE');
export const SAS = Symbol('SAS');
export const SBS = Symbol('SBS');
export const SS = Symbol('SS');
export const ST = Symbol('ST');
export const STD = Symbol('STD');
export const PROTOCOL_GROUPS_SETUP = Symbol('PROTOCOL_GROUPS_SETUP');
export const STUDY_ENROLMENTS = Symbol('STUDY_ENROLMENTS');
export const STUDY_EXPENSES = Symbol('STUDY_EXPENSES');
export const STUDY_REPORTS = Symbol('STUDY_REPORTS');
export const TIMELINE = Symbol('TIMELINE');
export const TRANSACTION_LEDGER = Symbol('TRANSACTION_LEDGER');
export const VAS = Symbol('VAS');
export const WORK_LIST = Symbol('WORK_LIST');
export const PATIENT_INFO = Symbol('PATIENT_INFO');
export const PATIENT_PROFILE = Symbol('PATIENT_PROFILE');
export const SITE_BILL = Symbol('SITE_BILL');
export const SITE_PAYMENTS = Symbol('SITE_PAYMENTS');
export const READY_FOR_APPROVAL = Symbol('READY_FOR_APPROVAL');
export const PI_REVIEW = Symbol('PI_REVIEW');
export const SM_REVIEW = Symbol('SM_REVIEW');
export const CRA_REVIEW = Symbol('CRA_REVIEW');
export const EXPORT_CASE_REPORT_FORMS = Symbol('EXPORT_CASE_REPORT_FORMS');
export const MANAGE_TASKS = Symbol('MANAGE_TASKS');
export const TASK_WORK_LIST = Symbol('TASK_WORK_LIST');
export const ITEM_GROUP_TEMPLATES = Symbol('ITEM_GROUP_TEMPLATES');
export const PRE_SCREENING_REPORTS = Symbol('PRE_SCREENING_REPORTS');
export const LEADERSHIP_METRICS_REPORTS = Symbol('LEADERSHIP_METRICS_REPORTS');
export const SSU_METRICS_REPORTS = Symbol('SSU_METRICS_REPORTS');
export const CRA_AUDIT_REPORT = Symbol('CRA_AUDIT_REPORT');
export const AUDIT_LOG_ALL_STUDY_DATA_REPORT = Symbol('AUDIT_LOG_ALL_STUDY_DATA_REPORT');
export const ROLES_FINANCE_AND_FINANCE_ANALYST = Symbol('ROLES_FINANCE_AND_FINANCE_ANALYST');
export const USER_PREFERENCES = Symbol('USER_PREFERENCES');
export const CALENDAR_INVITATIONS_PREFERENCES = Symbol('CALENDAR_INVITATIONS_PREFERENCES');
export const NOTIFICATION_PREFERENCES = Symbol('NOTIFICATION_PREFERENCES');
export const HOME_PAGE_DASHBOARDS = Symbol('HOME_PAGE_DASHBOARDS');
export const EXTERNAL_INTEGRATION = Symbol('EXTERNAL_INTEGRATION');
export const REIMBURSEMENT_REQUEST = Symbol('REIMBURSEMENT_REQUEST');
export const TRAVEL_REQUEST = Symbol('TRAVEL_REQUEST');
export const TRAVEL_REQUEST_WORKLIST = Symbol('TRAVEL_REQUEST_WORKLIST');
export const SSU_KPI_DASHBOARD = Symbol('SSU_KPI_DASHBOARD');
export const OPEN_ENCOUNTERS_DASHBOARD = Symbol('OPEN_ENCOUNTERS_DASHBOARD');

export const ALL = Symbol('ALL');
