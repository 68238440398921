import { PARENT, SITE_CREDIT, SITE_DEBIT, WITHHOLDING } from './SitePaymentsTable/SitePaymentsConstants';

export const resolveEventSuffix = (eventNumber, eventType) => {
  switch (eventType) {
    case PARENT:
      return eventNumber;
    case WITHHOLDING:
      return eventNumber + '-WH';
    case SITE_CREDIT:
      return eventNumber + '-SC';
    case SITE_DEBIT:
      return eventNumber + '-SD';
    default:
      return eventNumber;
  }
};

export function eventNumberComparator(v1, v2) {
  const result = +v1.replace(/-WH$|-SC$|-SD$/, '') - +v2.replace(/-WH$|-SC$|-SD$/, '');
  return result === 0 ? v1 > v2 : result;
}
