import axios from 'axios';

const ROOT = 'api/v1/travel-worklist';

const PatientTravelWorklistApi = {
  getStudySitesWithTravels(patientId) {
    return axios.get(`${ROOT}/study-sites`, { hideLoader: true });
  },
  getPersonnelWithTravels(ssuIds) {
    return axios.post(`${ROOT}/personnel`, ssuIds, { hideLoader: true });
  },
  getWorklistTravels(request) {
    return axios.post(`${ROOT}`, request, { hideLoader: true });
  },
  getAssignees(ssuIds) {
    return axios.post(`${ROOT}/assignees`, ssuIds, { hideLoader: true });
  },
  updateWorklistRow(travelRequestId, data) {
    return axios.patch(`${ROOT}/rows/${travelRequestId}`, data, {
      hideLoader: true
    });
  }
};

export default PatientTravelWorklistApi;
