import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import { IconButton, Typography } from '@mui/material';
import { grey, lightBlue } from '@mui/material/colors';
import { has } from 'lodash/object';
import moment from 'moment-timezone';

import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { dateTime12, DD_SLASH_MMM_SLASH_YYYY } from '../../../../constants/dateFormat';
import { toBill } from '../../../../services/financial';
import { generateUrlByKey } from '../../../root/router';
import {
  RBS_APPROVED,
  RBS_PENDING,
  reimbursementStatusesMap
} from '../../patient-source/Patients/PatientInfo/EncountersSection/PaymentsSection/reimbursementConstants';
import { statusChangeNotification } from '../../patient-source/Patients/PatientInfo/ReimbursementRequest/ReimbursementRequest';

import AssigneeChangeDropdown from './AssigneeChangeDropdown/AssigneeChangeDropdown';
import EditNoteModal from './EditNoteModal/EditNoteModal';
import StatusChangeDropdown from './StatusChangeDropdown/StatusChangeDropdown';
import ViewNoteModal from './ViewNoteModal/ViewNoteModal';

export default function useDataGrid() {
  const [gridData, setGridData] = useState([]);

  const columns = useMemo(
    () => [
      {
        field: 'patientFullName',
        headerName: 'Patient',
        flex: 1,
        renderCell({ value, row }) {
          return (
            <Link
              to={generateUrlByKey('Patients.Patient Profile.Patient Studies', {
                patientId: row.patientId,
                ssuPatientId: row.ssuPatientId
              })}
            >
              {value}
            </Link>
          );
        }
      },
      {
        field: 'eventNumber',
        headerName: 'EventID',
        flex: 1
      },
      {
        field: 'submissionTime',
        headerName: 'Submission date',
        flex: 1,
        valueGetter({ value }) {
          return value ? new Date(value) : null;
        },
        renderCell({ value }) {
          return value ? moment(value).format(dateTime12) : '';
        },
        type: 'date',
        align: 'center',
        minWidth: 180
      },
      {
        field: 'approvedAt',
        headerName: 'Approved At',
        flex: 1,
        valueGetter({ value }) {
          return value ? new Date(value) : null;
        },
        renderCell({ value }) {
          return value ? moment(value).format(DD_SLASH_MMM_SLASH_YYYY) : '';
        },
        type: 'date',
        align: 'center'
      },
      {
        field: 'approvedBy',
        headerName: 'Approved By',
        flex: 1
      },
      {
        field: 'studyName',
        headerName: 'Study',
        flex: 1
      },
      {
        field: 'siteName',
        headerName: 'Site',
        flex: 1
      },
      {
        field: 'encounterName',
        headerName: 'Encounter',
        flex: 1
      },
      {
        field: 'encounterDate',
        headerName: 'Encounter date',
        flex: 1,
        valueGetter({ value }) {
          return value ? new Date(value) : null;
        },
        renderCell({ value }) {
          return value ? moment(value).format(DD_SLASH_MMM_SLASH_YYYY) : '';
        },
        type: 'date',
        align: 'center'
      },
      {
        field: 'subjectId',
        headerName: 'SubjectID',
        flex: 1
      },
      {
        field: 'totalAmount',
        headerName: 'Amount',
        flex: 1,
        type: 'number',
        maxWidth: 100,
        valueGetter({ value }) {
          return toBill(value);
        },
        renderCell({ value, row }) {
          return (
            <Link
              to={generateUrlByKey('Reimbursements.Reimbursement request', {
                reimbursementId: row.id,
                ssuPatientId: row.ssuPatientId
              })}
            >
              ${value}
            </Link>
          );
        }
      },
      {
        field: 'numberOfItems',
        headerName: '# of items',
        flex: 1,
        type: 'number',
        maxWidth: 100,
        align: 'center'
      },
      {
        field: 'assigneePersonnelName',
        headerName: 'Assigned',
        flex: 1,
        minWidth: 200,
        renderCell({ row }) {
          if (row?.status !== RBS_APPROVED) {
            return (
              <AssigneeChangeDropdown
                row={row}
                onChangeSuccess={function(data) {
                  setGridData(function(state) {
                    return state.map(function(r) {
                      if (r.id !== row.id) return r;
                      return {
                        ...r,
                        ...data
                      };
                    });
                  });
                }}
              />
            );
          }
          return <Typography variant="body1">{row?.assigneePersonnelName || ''}</Typography>;
        }
      },
      {
        field: 'note',
        headerName: 'Note',
        flex: 1,
        maxWidth: 100,
        renderCell({ value, row }) {
          return (
            <IconButton
              onClick={function() {
                ModalBoxes.open({
                  component: row.status !== RBS_APPROVED ? <EditNoteModal row={row} /> : <ViewNoteModal row={row} />,
                  onClose(data) {
                    if (has(data, 'note') && has(data, 'isNoteRead')) {
                      setGridData(function(state) {
                        return state.map(function(r) {
                          if (r.id !== row.id) return r;
                          return {
                            ...r,
                            ...data
                          };
                        });
                      });
                    }
                  }
                });
              }}
            >
              {value && !row?.isNoteRead ? (
                <MarkEmailUnreadIcon htmlColor={lightBlue[500]} />
              ) : (
                <EmailIcon htmlColor={grey[500]} />
              )}
            </IconButton>
          );
        }
      },
      {
        field: 'status',
        headerName: 'Action',
        flex: 1,
        minWidth: 200,
        valueGetter({ value }) {
          return reimbursementStatusesMap[value] || '';
        },
        renderCell({ value, row }) {
          if (row.status === RBS_PENDING) {
            return (
              <StatusChangeDropdown
                row={row}
                onChangeSuccess={function(data) {
                  NotificationManager.success(statusChangeNotification(data?.status));
                  setGridData(function(state) {
                    return state.map(function(r) {
                      if (r.id !== row.id) return r;
                      return { ...r, ...data };
                    });
                  });
                }}
              />
            );
          }
          return <Typography variant="body1">{value}</Typography>;
        }
      }
    ],
    []
  );

  return [columns, gridData, setGridData];
}
