import axios from 'axios';

const ROOT = 'api/v1/finance/site-payments';

const SitePaymentsApi = {
  getSitePayments(filters) {
    return axios.post(`${ROOT}`, filters);
  },
  readyForApproval(billEvents) {
    return axios.put(`${ROOT}/ready-for-approval`, billEvents);
  },
  resetToOpen(itemSiteIds) {
    return axios.put(`${ROOT}/reset-to-open`, itemSiteIds);
  },
  createSiteBill(request) {
    return axios.post(`${ROOT}/bills`, request);
  },
  getUnbilledSiteEventsSummary(studyId, siteId) {
    return axios.get(`${ROOT}/unbilled-summary?studyId=${studyId}&siteId=${siteId}`);
  },
  setAsApproved(itemSiteIds) {
    return axios.put(`${ROOT}/approve`, itemSiteIds);
  },
  rejectSitePayments(itemSiteIds, rejectionReason) {
    return axios.put(`${ROOT}/reject`, { itemSiteIds, rejectionReason });
  },
  setAsPaid(itemSiteIds) {
    return axios.put(`${ROOT}/paid`, itemSiteIds);
  }
};

export default SitePaymentsApi;
