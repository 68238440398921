import { useMemo, useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import PatientTravelWorklistApi from 'api/patient/PatientTravelWorklistApi';
import { onRequestError } from 'services/handlers';

import {
  TR_BOOKED,
  TR_CANCELED,
  TR_DENIED,
  TR_PENDING,
  travelStatuses,
  travelStatusMap
} from 'components/pages/patient-source/Patients/PatientInfo/EncountersSection/TravelSection/travelConstants';

export default function StatusChangeDropdown({ row, onChangeSuccess }) {
  const { id: reimbursementId } = row;
  const [saving, setSaving] = useState(false);

  const allowedStatuses = useMemo(function() {
    return travelStatuses
      .filter(function({ id }) {
        return [TR_BOOKED, TR_DENIED, TR_CANCELED].includes(id);
      })
      .map(function(value) {
        return prepareStatus({ ...value });
      });
    function prepareStatus(value) {
      /*if ((value.id === RBS_APPROVED || value.id === RBS_DENIED) && !row.canBeApprovedOrDenied) {
          value.disabled = true;
        }*/
      return value;
    }
  }, []);

  return (
    <Select
      value={TR_PENDING}
      disableUnderline={true}
      size="small"
      variant="standard"
      disabled={saving}
      fullWidth
      renderValue={value => travelStatusMap[value]}
      onChange={statusOnChange}
    >
      {allowedStatuses.map(e => (
        <MenuItem key={e.id} value={e.id} disabled={e.disabled}>
          {e.name}
        </MenuItem>
      ))}
    </Select>
  );

  function statusOnChange({ target }) {
    const status = target.value;

    setSaving(true);
    PatientTravelWorklistApi.updateWorklistRow(reimbursementId, {
      status
    })
      .then(function({ data }) {
        onChangeSuccess(data);
      })
      .catch(onRequestError)
      .finally(() => setSaving(false));
  }
}
