import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash/lang';

import SitePaymentsApi from '../../../../api/finance/SitePaymentsApi';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { toCoins } from '../../../../services/financial';
import { SitePaymentsContext } from '../SitePayments/SitePaymentsContext';

export const ReadyForApprovalContext = React.createContext(null);

const ReadyForApprovalProvider = ({ children }) => {
  const { applyFilter } = useContext(SitePaymentsContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [events, setEvents] = useState([]);
  const [checkedEvents, setCheckedEvents] = useState([]);
  const [comment, setComment] = useState('');

  useEffect(() => {
    const checkedEvents = location.state?.checkedEvents;
    if (!isEmpty(checkedEvents)) {
      setEvents(checkedEvents);
    }
  }, [location.state?.checkedEvents]);

  const onTableDataChange = useCallback((eventIdForUpdate, newValue, columnForUpdate) => {
    setEvents(prevState =>
      prevState.map(event => ({
        ...event,
        [columnForUpdate]: event.itemSiteId === eventIdForUpdate ? newValue : event[columnForUpdate]
      }))
    );
  }, []);

  const onReadyForApproval = useCallback(() => {
    const preparedData = events.map(event => ({
      itemSiteId: event.itemSiteId,
      sitePaymentComment: event.sitePaymentComment,
      approvedAmount: toCoins(event.approvedAmount),
      siteName: event.siteName,
      pcn: event.pcn
    }));
    SitePaymentsApi.readyForApproval(preparedData)
      .then(() => {
        NotificationManager.success('Event(s) successfully submitted as Ready for Approval');
        navigate('/site-payments');
        applyFilter();
      })
      .catch(() => {
        NotificationManager.error('Event(s) unable to be submitted as Ready for Approval, please try again');
      });
  }, [applyFilter, events, navigate]);

  const onMultipleCommentApply = useCallback(() => {
    setEvents(prevState =>
      prevState.map(event => {
        return {
          ...event,
          sitePaymentComment: checkedEvents.includes(event.itemSiteId) ? comment : event.sitePaymentComment
        };
      })
    );
    setComment('');
  }, [checkedEvents, comment]);

  const value = useMemo(
    () => ({
      events,
      onTableDataChange,
      checkedEvents,
      setCheckedEvents,
      comment,
      setComment,
      onMultipleCommentApply,
      onReadyForApproval
    }),
    [events, onTableDataChange, checkedEvents, comment, onMultipleCommentApply, onReadyForApproval]
  );

  return <ReadyForApprovalContext.Provider value={value}>{children}</ReadyForApprovalContext.Provider>;
};

export function withReadyForApprovalContext(Component) {
  return function WrapperComponent(props) {
    return (
      <ReadyForApprovalProvider>
        <Component {...props} />
      </ReadyForApprovalProvider>
    );
  };
}
