import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserApiApi } from '../../../api';
import ModalBoxes from '../../../common/feedback/ModalBoxes/ModalBoxes';
import {
  notifyOnStudyPermissionsRoleSwitch,
  selectRole,
  useUserActiveRoleIsGlobalAndSsuRolesExists
} from '../../../services/userRoleService';
import ConfirmRoleSwitchModal from '../../UserRoleSwitcher/ConfirmRoleSwitchModal';
import { filterRolesByRoute } from '../../UserRoleSwitcher/WithMultiRoleCheck';

import { useCurrentRoute } from './index';

export function withMultiAccess(Component) {
  return function(props) {
    const [allowed, setAllowed] = useState(false);

    const route = useCurrentRoute();
    const navigate = useNavigate();
    const userActiveRoleIsGlobalAndSsuRolesExists = useUserActiveRoleIsGlobalAndSsuRolesExists();

    useEffect(
      function() {
        if (!userActiveRoleIsGlobalAndSsuRolesExists) {
          setAllowed(true);
          return;
        }
        UserApiApi.getUserSsuRelatedRoles(route.params.ssuPatientId).then(({ data }) => {
          const roles = filterRolesByRoute({ pathname: route.pathname }, data, []);
          if (roles.length === 0) {
            setAllowed(true);
          } else if (roles.length === 1) {
            selectRole(roles[0], null, notifyOnStudyPermissionsRoleSwitch);
          } else {
            const modalBox = ModalBoxes.open({
              component: (
                <ConfirmRoleSwitchModal
                  roles={roles}
                  selectRole={function(role) {
                    selectRole(role, null, notifyOnStudyPermissionsRoleSwitch);
                    modalBox.close();
                  }}
                />
              ),
              onClose() {
                navigate(-1);
              }
            });
          }
        });
      },
      [navigate, route.pathname, route.params.ssuPatientId, userActiveRoleIsGlobalAndSsuRolesExists]
    );

    if (allowed) {
      return <Component {...props} />;
    }
    return null;
  };
}
