import { useMemo } from 'react';
import { Autocomplete, TextField, Unstable_Grid2 as Grid } from '@mui/material';
import { isEmpty } from 'lodash/lang';
import { get } from 'lodash/object';

import { countries, getCountryById, getRegion, getRegions } from '../../../../../../../services/geographic';
import { normalizeZipCode } from '../../../../../../../services/normalizers';

import Address1Autocomplete from './Address1Autocomplete/Address1Autocomplete';
import CityAutocomplete from './CityAutocomplete/CityAutocomplete';
import AddressValidator from './AddressValidator';

export default function AddressFields({
  path,
  values,
  errors,
  setAddressField,
  setCountryId,
  setCity,
  readOnly = false
}) {
  const data = get(values, path);

  const [country, regions] = useMemo(
    function() {
      return [getCountryById(data.countryId) || '', getRegions(data.countryId)];
    },
    [data.countryId]
  );

  const region = useMemo(
    function() {
      return data.countryId ? getRegion(data.regionId, data.countryId) || '' : '';
    },
    [data.countryId, data.regionId]
  );

  return (
    <>
      <Grid xs={4}>
        <Autocomplete
          disabled={readOnly}
          value={country}
          onChange={function({ target }, country) {
            setCountryId(path, country?.id || '');
          }}
          onBlur={function(e) {
            AddressValidator.revalidate(path);
          }}
          fullWidth
          getOptionKey={option => option.id}
          getOptionLabel={option => option.name ?? option}
          disablePortal
          options={countries}
          renderInput={params => (
            <TextField
              {...params}
              name={`${path}.countryId`}
              label="Country"
              variant="standard"
              required
              error={getErrorBoolean('countryId')}
              helperText={getHelperText('countryId')}
              autoComplete="off"
            />
          )}
        />
      </Grid>
      <Grid xs={4}>
        <Autocomplete
          disabled={readOnly || isEmpty(regions)}
          value={region}
          onChange={function({ target }, region) {
            setAddressField(`${path}.regionId`, region?.id || '');
          }}
          onBlur={function(e) {
            AddressValidator.revalidate(path);
          }}
          fullWidth
          getOptionKey={option => option.id}
          getOptionLabel={option => option.name ?? option}
          disablePortal
          options={regions}
          renderInput={params => (
            <TextField
              {...params}
              name={`${path}.regionId`}
              label={data.countryId === 'CA' ? 'Province' : 'State'}
              variant="standard"
              required
              error={getErrorBoolean('regionId')}
              helperText={
                getHelperText('regionId')
                  ? (data.countryId === 'CA' ? 'Province' : 'State') + getHelperText('regionId')
                  : null
              }
              autoComplete="off"
            />
          )}
        />
      </Grid>
      <Grid xs={4}>
        <CityAutocomplete
          readOnly={readOnly}
          name={`${path}.city`}
          countryId={data.countryId}
          regionId={data.regionId}
          city={data.city}
          onChange={function(value) {
            setCity(path, value);
          }}
          onBlur={function(e) {
            AddressValidator.revalidate(path);
          }}
          error={getErrorBoolean('city')}
          helperText={getHelperText('city')}
        />
      </Grid>
      <Grid xs={6}>
        <Address1Autocomplete
          readOnly={readOnly}
          name={`${path}.address1`}
          countryId={data.countryId}
          regionId={data.regionId}
          city={data.city}
          address1={data.address1}
          valid={data.valid}
          onChange={function(value) {
            setAddressField(`${path}.address1`, value || '');
          }}
          onBlur={function(e) {
            AddressValidator.revalidate(path);
          }}
          error={getErrorBoolean('address1')}
          helperText={getHelperText('address1')}
        />
      </Grid>
      <Grid xs={3}>
        <TextField
          disabled={readOnly}
          name={`${path}.address2`}
          value={data.address2 || ''}
          onChange={function({ target }) {
            setAddressField(target.name, target.value || '');
          }}
          error={getErrorBoolean('address2')}
          helperText={getHelperText('address2')}
          fullWidth
          label="Address 2"
          variant="standard"
          autoComplete="off"
        />
      </Grid>
      <Grid xs={3}>
        <TextField
          disabled={readOnly}
          name={`${path}.zipCode`}
          value={data.zipCode || ''}
          onChange={function({ target }) {
            setAddressField(target.name, normalizeZipCode(target.value, data.countryId === 'US'));
          }}
          onBlur={function(e) {
            AddressValidator.revalidate(path);
          }}
          error={getErrorBoolean('zipCode')}
          helperText={getHelperText('zipCode')}
          fullWidth
          label="Zip"
          variant="standard"
          required
          autoComplete="off"
        />
      </Grid>
    </>
  );
  function getErrorBoolean(fieldName) {
    return Boolean(get(errors, `${path}.${fieldName}`));
  }
  function getHelperText(fieldName) {
    return get(errors, `${path}.${fieldName}`);
  }
}
