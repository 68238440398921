export const eventTypeLabel = {
  EXPENSE_FIXED: 'Expense - Fixed',
  ENCOUNTER: 'Encounter',
  UNEXPECTED_ENCOUNTER: 'Encounter',
  SITUATIONAL_ENCOUNTER: 'Encounter',
  ENCOUNTER_ITEM_GROUP: 'Encounter - Item Group',
  SITUATIONAL_ITEM_GROUP: 'Encounter - Item Group',
  STATUS_CHANGE: 'Status Change',
  RATIO_STATUS_CHANGE: 'Status Change with Ratio',
  PATIENT_REIMBURSEMENT: 'Patient Reimbursement',
  ITEM_GROUP_PATIENT_STIPEND: 'Patient Stipend',
  ITEM_GROUP_GENERAL: 'Item Group',
  UNEXPECTED_ITEM_GROUP: 'Encounter - Item Group',
  ENCOUNTER_STATUS_CHANGE: 'Encounter - Status Change'
};

export const BUDGET_ITEM = 'Budget Item';
export const MISSING_EVENTS = 'Missing Events';
export const REASON = 'Reason';
