import { PROTOCOL_GROUPS_SETUP, STD } from '../../../../constants/accessToPages';
import ElementSetup from '../shared/ElementSetupNew/ElementSetupNew';
import TableSetupTemplate, { TableTemplates } from '../shared/ElementSetupNew/TableSetup/TableSetupTemplate';

import ProtocolElementSetup from './ProtocolSetup/EncounterSetup/ProtocolEncounterSetup';
import { ItemGroupList } from './ProtocolSetup/ItemGroupList/ItemGroupList';
import ProtocolGroupsSetup from './ProtocolSetup/ProtocolGroupsSetup/ProtocolGroupsSetup';
import ProtocolSetup from './ProtocolSetup/ProtocolSetup';
import PreviewProtocolSetup from './SourceDataPreview/SourceDataPreview';
import ProtocolDetails from './ProtocolDetails';

export const protocolRouterConfig = {
  name: 'Setup Protocol',
  path: '/study-protocol',
  component: ProtocolDetails,
  access: [STD],
  inherited: [
    {
      name: 'Source Data Setup',
      path: '/setup/:studyId/:protocolIdentity',
      component: ProtocolSetup,
      access: [STD],
      inherited: [
        {
          name: 'Item Groups List',
          path: '/item-groups',
          component: ItemGroupList,
          access: [STD],
          inherited: [
            {
              name: 'Item Group Setup',
              path: '/elementSetup',
              component: ElementSetup,
              access: [STD],
              inherited: [
                {
                  name: 'Item Group Edit',
                  path: '/:element_identifier',
                  component: ElementSetup,
                  access: [STD]
                }
              ]
            },
            {
              name: 'Table Setup',
              path: '/table-setup',
              component: TableSetupTemplate,
              access: [STD],
              inherited: [
                {
                  name: 'Table Edit',
                  path: '/:element_identifier',
                  component: TableSetupTemplate,
                  access: [STD]
                }
              ]
            },
            {
              name: 'Item Group Template Setup',
              path: '/template/setup/:itemGroupTemplateId',
              component: ElementSetup,
              access: [STD]
            },
            {
              name: 'Item Group Table Template Setup',
              path: '/table-template-setup/:itemGroupTemplateId',
              component: TableTemplates,
              access: [STD]
            }
          ]
        },
        {
          name: 'Encounter Setup',
          path: '/encounterSetup',
          component: ProtocolElementSetup,
          access: [STD],
          inherited: [
            {
              name: 'Item Group Edit',
              path: '/elementSetup/:element_identifier',
              component: ElementSetup,
              access: [STD]
            },
            {
              name: 'Table Edit',
              path: '/:element_identifier',
              component: TableSetupTemplate,
              access: [STD]
            }
          ]
        },
        {
          name: 'Groups Setup',
          path: '/groups-setup/:groupId?',
          component: ProtocolGroupsSetup,
          access: [PROTOCOL_GROUPS_SETUP]
        }
      ]
    },
    {
      name: 'Source Data Preview',
      path: '/preview/:studyId/:protocolIdentity',
      component: PreviewProtocolSetup,
      access: [STD]
    }
  ]
};
