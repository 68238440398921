import React, { useMemo } from 'react';

import Common from '../../../../../../../common/common';
import Select from '../../../../../../../common/data-entry/Select';

export const BudgetEventsTableFilter = ({ changeFilterValue }) => {
  const types = useMemo(
    () => [
      { name: 'All', accessor: 'all', targetName: 'selectedType' },
      { name: 'Item Group', accessor: 'ITEM_GROUP_GENERAL', targetName: 'selectedType' },
      { name: 'Encounter', accessor: 'ENCOUNTER', targetName: 'selectedType' },
      { name: 'Encounter - Item Group', accessor: 'ENCOUNTER_ITEM_GROUP', targetName: 'selectedType' },
      { name: 'Expense - Fixed', accessor: 'EXPENSE_FIXED', targetName: 'selectedType' },
      { name: 'Expense - Variable', accessor: 'EXPENSE_VARIABLE', targetName: 'selectedType' },
      { name: 'Status Change', accessor: 'STATUS_CHANGE', targetName: 'selectedType' },
      { name: 'Status Change with Ratio', accessor: 'RATIO_STATUS_CHANGE', targetName: 'selectedType' },
      { name: 'Patient Reimbursement', accessor: 'PATIENT_REIMBURSEMENT', targetName: 'selectedType' },
      { name: 'Patient Stipend', accessor: 'ITEM_GROUP_PATIENT_STIPEND', targetName: 'selectedType' },
      { name: 'Encounter Status Change', accessor: 'ENCOUNTER_STATUS_CHANGE', targetName: 'selectedType' }
    ],
    []
  );

  const entities = useMemo(
    () =>
      [
        { name: 'All', accessor: 'all', targetName: 'selectedEntity' },
        { name: 'Site', accessor: 'siteAmount', targetName: 'selectedEntity' },
        { name: 'Patient', accessor: 'patientAmount', targetName: 'selectedEntity' },
        { name: 'Vendor', accessor: 'vendorAmount', targetName: 'selectedEntity' },
        { name: 'Elligo', accessor: 'elligoAmount', targetName: 'selectedEntity' }
      ].sort(Common.generateSortFuncObjectFieldAlphabetical('name')),
    []
  );
  return (
    <div className="pt-2 ml-3">
      <div className="row">
        <div className="d-flex flex-row justify-content-end">
          <div className="form-group col-auto pr-0">
            <Select
              label="Type"
              clearSearchOnSelection
              dataSource={types}
              optionLabelKey="name"
              optionValueKey="name"
              searchable
              onChange={changeFilterValue}
              clearable={false}
              deselectOnSelectedOptionClick={false}
              validate={false}
            />
          </div>
          <div className="form-group col-auto pr-0">
            <Select
              label="Entity"
              clearSearchOnSelection
              dataSource={entities}
              optionLabelKey="name"
              optionValueKey="name"
              searchable
              onChange={changeFilterValue}
              clearable={false}
              deselectOnSelectedOptionClick={false}
              validate={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
