import React from 'react';
import { useNavigate } from 'react-router-dom';
import { union } from 'lodash/array';
import { filter, reduce } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import { UserApiApi } from '../../api';
import ModalBoxes from '../../common/feedback/ModalBoxes/ModalBoxes';
import {
  notifyOnStudyPermissionsRoleSwitch,
  selectRole,
  useUserActiveRoleIsGlobalAndSsuRolesExists
} from '../../services/userRoleService';
import { rolesAccessToPageMap } from '../root/Container/CurrentUserContainer';
import { getCurrentRouteByLocation } from '../root/router/service';

import ConfirmRoleSwitchModal from './ConfirmRoleSwitchModal';

export default function WithMultiRoleCheck({ excludedRoles = [], Component, ssuPatientId, ...restProps }) {
  const { to } = restProps;
  const navigate = useNavigate();
  const userActiveRoleIsGlobalAndSsuRolesExists = useUserActiveRoleIsGlobalAndSsuRolesExists();

  return (
    <Component
      {...restProps}
      onClick={function(e) {
        if (userActiveRoleIsGlobalAndSsuRolesExists) {
          e.preventDefault();
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          UserApiApi.getUserSsuRelatedRoles(ssuPatientId).then(function({ data }) {
            const roles = filterRolesByRoute({ pathname: to }, data, excludedRoles);
            if (roles.length === 0) {
              navigate(to);
            } else if (roles.length === 1) {
              selectRole(roles[0], to, notifyOnStudyPermissionsRoleSwitch);
            } else {
              const modalBox = ModalBoxes.open({
                component: (
                  <ConfirmRoleSwitchModal
                    roles={roles}
                    selectRole={function(role) {
                      selectRole(role, to, notifyOnStudyPermissionsRoleSwitch);
                      modalBox.close();
                    }}
                  />
                )
              });
            }
          });
        }
      }}
    />
  );
}

export function filterRolesByRoute(location, roles, excludedRoles) {
  const route = getCurrentRouteByLocation(location);
  const access = getAccessByRoute(route);
  const allowedRoles = getAllowedRolesByAccess(access);
  return filter(roles, function(role) {
    return allowedRoles.includes(role) && !excludedRoles.includes(role);
  });
}

function getAccessByRoute(route) {
  if (!isEmpty(route.access)) {
    return route.access;
  }

  if (!isEmpty(route.nested)) {
    return reduce(
      route.nested,
      function(accumulator, route) {
        if (!isEmpty(route.access)) {
          return accumulator.concat(route.access);
        }
        return accumulator;
      },
      []
    );
  }

  return route.parent?.access;
}

function getAllowedRolesByAccess(access) {
  return reduce(
    access,
    function(accumulator, access) {
      return union(accumulator, rolesAccessToPageMap[access]);
    },
    []
  );
}
