import React from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';

import Button from '../../../../../common/general/Button';
import { pendoTrackDefaultSortingChange } from '../../../../../pendo/PendoUtils';
import { CellFormattedDate } from '../../../../CellFormattedDate/CellFormattedDate';
import { generateUrlByKey } from '../../../../root/router';

const columns = [
  {
    id: 'patientName',
    Header: 'Patient',
    accessor({ patientFirstName, patientLastName }) {
      return `${patientFirstName} ${patientLastName}`;
    }
  },
  {
    Header: 'Subject ID',
    accessor: 'subjectId',
    Cell: ({ value }) => resolveCellValue(value)
  },
  {
    Header: 'Study',
    accessor: 'studyName'
  },
  {
    Header: 'Site',
    accessor: 'siteName'
  },
  {
    Header: 'Encounter',
    accessor: 'encounterName',
    Cell: ({ value, original }) => (
      <Link
        onClick={function(e) {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
        to={getUrlToEncounterDetailsPage(original)}
      >
        {value}
      </Link>
    )
  },
  {
    id: 'date',
    Header: 'Visit Date',
    accessor: 'edcVisitDate',
    Cell: ({ value }) => <CellFormattedDate date={value} defaultValue={'--'} />
  },
  {
    Header: 'Review',
    accessor: 'review',
    sortable: false,
    width: 115,
    Cell: ({ original }) => getReview(original)
  }
];

export default function AwaitingSignOffTopTenWidgetTable(props) {
  return (
    <ReactTable
      columns={columns.map(e => ({ ...e, show: props.showColumns.includes(e.Header) }))}
      showPagination
      onSortedChange={pendoTrackDefaultSortingChange}
      manual
      minRows={1}
      {...props}
    />
  );
}

export function getReview(el) {
  let reviewButtonDisabled = false;
  if (el.encounterStatus === 'PI_REVIEW' && !el.piReviewItemGroupId && !el.groupAssignStudy) {
    reviewButtonDisabled = true;
  }
  if (el.encounterStatus === 'SM_REVIEW' && !el.smReviewItemGroupId && !el.groupAssignStudy) {
    reviewButtonDisabled = true;
  }
  let text = '';

  switch (el.encounterStatus) {
    case 'PI_REVIEW':
      text = 'PI Review';
      break;
    case 'SM_REVIEW':
      text = 'SM Review';
      break;
    default:
      text = 'Review';
      break;
  }
  return (
    <React.Fragment>
      <span className="tooltip-cell">
        <Button disabled={reviewButtonDisabled} size="h28" to={getUrlToReviewPage(el)} priority="high">
          {text}
        </Button>
      </span>
    </React.Fragment>
  );
}

export function getUrlToEncounterDetailsPage(pathDetails) {
  const { patientIdentifier, ssuPatientId, patientEncounterId } = pathDetails;
  return generateUrlByKey('Home Page.Patient Profile.Patient Studies.Encounter Details', {
    patientId: patientIdentifier,
    ssuPatientId,
    patientEncounterId
  });
}

export function getUrlToReviewPage(original) {
  const {
    ssuPatientId,
    piReviewItemGroupId,
    smReviewItemGroupId,
    patientEncounterId,
    patientIdentifier,
    groupAssignStudy,
    encounterStatus,
    hasInvestigatorReview,
    hasSmReview
  } = original;

  const hasNoSmReview = groupAssignStudy ? !hasSmReview : !smReviewItemGroupId;
  const hasNoInvestigatorReview = groupAssignStudy ? !hasInvestigatorReview : !piReviewItemGroupId;

  if (encounterStatus === 'PI_REVIEW' ? hasNoInvestigatorReview : hasNoSmReview) {
    return;
  }

  const params = {
    ssuPatientId,
    patientId: patientIdentifier,
    patientEncounterId
  };

  if (!groupAssignStudy) {
    params.reviewPatientItemGroupId = encounterStatus === 'PI_REVIEW' ? piReviewItemGroupId : smReviewItemGroupId;
  }

  return generateUrlByKey(`Home Page.Patient Profile.Patient Studies.Encounter Details.${getReviewPageName()}`, params);

  function getReviewPageName() {
    if (encounterStatus === 'PI_REVIEW') {
      return groupAssignStudy ? `Investigator Encounter Review` : `Investigator Review`;
    }
    return groupAssignStudy ? `Study Manager Encounter Review` : `SM Review`;
  }
}

function resolveCellValue(value) {
  return value ? <span> {value}</span> : 'N/A';
}
