import axios from 'axios';
import { isEmpty } from 'lodash';

const ROOT = 'api/v1/studies';

const StudyApi = {
  populateStudyList() {
    return axios.get(`${ROOT}/`);
  },
  retryCard(proxyNum, patientId, hideLoader = false) {
    return axios.post(`${ROOT}/patients/${patientId}/retry/${proxyNum}`, null, {
      hideLoader
    });
  },
  addPatient(data) {
    return axios.post(`${ROOT}/patients/${data.studySiteIdentifier}`, data, {
      hideLoader: true
    });
  },
  getEnrolledPatientsCountBySsus(studySitesIds) {
    return axios.post(`${ROOT}/enrolled-patients-count/by-study-sites`, studySitesIds);
  },
  getScreenFailedPatientsCountBySsus(studySitesIds) {
    return axios.post(`${ROOT}/screen-failed-patients-count/by-study-sites`, studySitesIds);
  },
  getListOfSites() {
    return axios.get(`${ROOT}/sites`);
  },
  populateNonCanceledStudyList() {
    return axios.get(`${ROOT}/non-canceled`);
  },
  populateStudyDetailsByIdentifier(data) {
    return axios.get(`${ROOT}/${data}`);
  },
  getProtocolsByStudyIdentifier(studyIdentifier) {
    return axios.get(`${ROOT}/${studyIdentifier}/protocols`);
  },
  geWithdrawnPatientsCountBySsus(studySitesIds) {
    return axios.post(`${ROOT}/withdrawn-patients-count/by-study-sites`, studySitesIds);
  },
  saveItemGroupWithRows(data) {
    if (isEmpty(data.patientEncounterFormIdentifier)) {
      return axios.post(`${ROOT}/patients/${data.ssuPatientId}/item-groups/logs/${data.itemGroupTemplateId}`, data);
    }
    return axios.post(
      `${ROOT}/patients/${data.ssuPatientId}/encounter/item-groups/${data.patientEncounterFormIdentifier}`,
      data
    );
  },
  updateContactNumber(data) {
    return axios.put(`${ROOT}/patients/${data.patientUniqueIdentifier}/contact-number`, data);
  },
  getPatientRelatedStudies(data) {
    return axios.get(`${ROOT}/patients/studies/${data}`);
  },
  isStudyGroupAssign(studyId) {
    return axios.get(`${ROOT}/${studyId}/is-group-assigns`);
  },
  getSiteDetails(data) {
    return axios.get(`${ROOT}/sites/${data}`);
  },
  getPersonnelDetailsInStudy(ssuId, personnelId) {
    return axios.get(`${ROOT}/sites/${ssuId}/personnels/${personnelId}`);
  },
  editPersonnelInSite(data) {
    return axios.post(`${ROOT}/personnels`, data);
  },
  addPatientStudy(data) {
    return axios.put(`${ROOT}/patients/${data.studySiteIdentifier}/assign-study`, data);
  },
  getPatientEncounters(ssuPatientId, ssuPatientEncounterId) {
    return axios.get(
      `${ROOT}/patients/encounters?ssuPatientId=${ssuPatientId}&ssuPatientEncounterId=${ssuPatientEncounterId}`
    );
  },
  setIsPresentEpochs(studyIdentifier, isPresentEpochs) {
    return axios.put(`${ROOT}/${studyIdentifier}/toggle-present-epochs`, isPresentEpochs);
  },
  toggleSmsOptIn(studyIdentifier, smsOptIn) {
    return axios.patch(`${ROOT}/${studyIdentifier}/toggle-sms-opt-in/${smsOptIn}`);
  },
  getStudyTypes(studyIdentifier) {
    return axios.get(`${ROOT}/${studyIdentifier}/types`);
  },
  updateStudyTypes(studyIdentifier, selectedStudyTypeIds) {
    return axios.put(`${ROOT}/${studyIdentifier}/types`, selectedStudyTypeIds);
  },
  updateCostPerMileage(studyIdentifier, costPerMileagePayload) {
    return axios.patch(`${ROOT}/${studyIdentifier}/cost-per-mileage`, costPerMileagePayload);
  }
};

export default StudyApi;
