import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { dateTime12 } from 'constants/dateFormat';
import { MANAGE_PATIENT_TRAVEL_REQUEST } from 'constants/userOperations';
import { sortBy } from 'lodash';
import moment from 'moment';
import { userHasAccessTo } from 'services/auth';

import { travelServiceTypeMap, travelStatusMap } from './travelConstants';

export default function useTravelGridData() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const columns = useMemo(
    () => [
      {
        field: 'encounterName',
        headerName: 'Encounter',
        flex: 1
      },
      {
        field: 'visitDate',
        headerName: 'Visit Date',
        flex: 1,
        valueGetter({ value }) {
          return value ? new Date(value) : null;
        },
        renderCell({ value }) {
          return value ? moment(value).format(dateTime12) : '';
        },
        type: 'date'
      },
      {
        field: 'requestedServices',
        headerName: 'Type of Travel',
        flex: 1,
        valueGetter({ value }) {
          return value ? sortBy(value.map(type => travelServiceTypeMap[type])).join(' & ') : '';
        }
      },
      {
        field: 'status',
        headerName: 'Request Status',
        flex: 1,
        minWidth: 180,
        valueGetter({ value }) {
          return travelStatusMap[value] || value;
        }
      },
      {
        field: 'actions',
        headerName: 'Action',
        flex: 1,
        maxWidth: 172,
        renderCell({ value, row }) {
          if (row.status || !userHasAccessTo(MANAGE_PATIENT_TRAVEL_REQUEST)) {
            return '';
          }
          return (
            <Button
              size="small"
              variant="contained"
              onClick={function() {
                navigate(
                  `./travel-request?ssuPatientEncounterId=${row.sitePatientEncounterId ||
                    ''}&protocolEncounterKey=${row.protocolEncounterKey || ''}`
                );
              }}
            >
              Add Travel Request
            </Button>
          );
        }
      }
    ],
    [navigate]
  );

  return [columns, data, setData];
}
