import { ACTIVITY, STUDY_EXPENSES } from '../../../../constants/accessToPages';

import StudyExpenses from './StudyExpenses';

export const studyExpenseRouterConfig = {
  name: 'Expenses',
  rootBreadcrumbName: 'Study',
  path: '/study-expenses',
  component: StudyExpenses,
  access: [STUDY_EXPENSES, ACTIVITY]
};
