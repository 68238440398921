import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';

import SitePaymentsApi from '../../../../../../../api/finance/SitePaymentsApi';
import Loader from '../../../../../../../common/elements/Loader/Loader';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { toFormattedBill } from '../../../../../../../services/financial';
import { onRequestError } from '../../../../../../../services/handlers';

export const CreateBillModal = ({ modalBox, checkedEvents, applyFilter }) => {
  const [unbilledEventSummary, setUnbilledEventSummary] = useState({});
  const [loading, setLoading] = useState(true);
  const checkedEventsSiteAmount = checkedEvents.reduce((acc, event) => acc + event.siteAmount, 0);

  useEffect(() => {
    const { studyId, siteId } = checkedEvents[0];
    SitePaymentsApi.getUnbilledSiteEventsSummary(studyId, siteId)
      .then(({ data }) => {
        setUnbilledEventSummary(data);
        setLoading(false);
      })
      .catch(onRequestError);
  }, [checkedEvents]);

  const handleCreateBill = useCallback(() => {
    const itemSiteIds = checkedEvents.map(event => ({
      ledgerEventId: event.id,
      sitePaymentItemId: event.itemSiteId,
      sitePaymentType: event.sitePaymentType,
      siteName: event.siteName,
      pcn: event.pcn
    }));
    SitePaymentsApi.createSiteBill(itemSiteIds)
      .then(() => {
        modalBox.close();
        applyFilter();
        NotificationManager.success('Bill #(s) successfully created for selected event(s)');
      })
      .catch(() => {
        NotificationManager.error('Unable to create Bill #(s) for selected event(s), please try again');
      });
  }, [applyFilter, checkedEvents, modalBox]);

  const checkedEventsCount = useMemo(() => checkedEvents.length, [checkedEvents]);

  return (
    <>
      <ModalBoxes.Body>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="125px">
            <Loader dark />
          </Box>
        ) : (
          <>
            <strong>
              {`This will create Bill #(s) for ${checkedEventsCount} Event IDs for ${checkedEvents[0].studyName} and ${
                checkedEvents[0].siteName
              } for a total Site Amount of ${toFormattedBill(
                checkedEventsSiteAmount
              )}. An additional ${unbilledEventSummary?.unbilledEventsCount -
                checkedEventsCount} Event IDs and ${toFormattedBill(
                unbilledEventSummary?.totalUnbilledAmount - checkedEventsSiteAmount
              )} for the SSU are outstanding.`}
            </strong>
            <Box sx={{ marginTop: '38px' }}>Do you wish to Continue?</Box>
          </>
        )}
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="low" onClick={modalBox.close}>
            NO
          </Button>
          <Button onClick={handleCreateBill}>YES</Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
};
