import { setIn } from 'formik';
import { filter, forEach } from 'lodash/collection';
import { isEmpty, isEqual, isString } from 'lodash/lang';
import { has } from 'lodash/object';

import setAddressType from './stateProcessors/setAddressType';
import setCity from './stateProcessors/setCity';

export default function reducer(previousState, action) {
  const processedState = processState(previousState, action);
  return compareAndFix(previousState, processedState, action.type, action.payload?.value);
}

function processState(previousState, action) {
  if (action.type === 'SET_FIELD_VALUE') {
    return setFieldValueProcessor(previousState, action);
  }
  if (action.type === 'SET_CHECKBOX_GROUP_VALUE') {
    return setCheckboxGroupValueProcessor(previousState, action);
  }
  if (action.type === 'SET_ADDRESS_FIELD') {
    return setAddressField(previousState, action);
  }
  if (action.type === 'SET_ADDRESS_FIELDS') {
    return setAddressFields(previousState, action);
  }
  if (action.type === 'SET_COUNTRY_ID') {
    return setCountryId(previousState, action);
  }
  if (action.type === 'SET_CITY') {
    return setCity(previousState, action);
  }
  if (action.type === 'SET_ADDRESS_TYPE') {
    return setAddressType(previousState, action);
  }
  if (action.type === 'SET_ERRORS') {
    return setErrorsProcessor(previousState, action);
  }
  return previousState;
}

function compareAndFix(previousState, processedState, type, value = {}) {
  if (previousState.values !== processedState.values) {
    if (!has(value, 'valid')) {
      doValidRecalculation(previousState, processedState);
    }
    resetErrorsRecursively(processedState.errors, previousState.values, processedState.values);
  }

  return processedState;
}

function resetErrorsRecursively(errors, prev, next) {
  if (!isEmpty(errors)) {
    forEach(errors, function(value, key) {
      if (isString(value)) {
        if (prev[key] !== next[key]) {
          delete errors[key];
        }
        return;
      }
      resetErrorsRecursively(errors[key], prev[key], next[key]);
    });
  }
}

function doValidRecalculation(previousState, processedState) {
  ['pickupAddress', 'dropOffAddress'].forEach(function(path) {
    if (compare(previousState.values[path], processedState.values[path])) {
      processedState.values[path].valid = false;
    }
  });
}

function compare(a, b) {
  return !isEqual(
    {
      countryId: a.countryId,
      regionId: a.regionId,
      city: a.city,
      address1: a.address1,
      zipCode: a.zipCode
    },
    {
      countryId: b.countryId,
      regionId: b.regionId,
      city: b.city,
      address1: b.address1,
      zipCode: b.zipCode
    }
  );
}

function setFieldValueProcessor(previousState, action) {
  return {
    ...previousState,
    values: {
      ...previousState.values,
      [action.payload.name]: action.payload.value
    }
  };
}

function setCheckboxGroupValueProcessor(previousState, action) {
  const { name, value } = action.payload;
  const prevValue = previousState.values[name];
  const nextValue = value.checked ? [...prevValue, value.id] : filter(prevValue, t => t !== value.id);

  return {
    ...previousState,
    values: {
      ...previousState.values,
      [action.payload.name]: nextValue
    }
  };
}

function setAddressField(state, { type, payload: { path, value } }) {
  return setIn(state, 'values.' + path, value);
}

function setAddressFields(state, { type, payload: { path, value } }) {
  return {
    ...state,
    values: {
      ...state.values,
      [path]: {
        ...state.values[path],
        ...value
      }
    }
  };
}

function setCountryId(state, { type, payload: { path, value } }) {
  return {
    ...state,
    values: {
      ...state.values,
      [path]: {
        ...state.values[path],
        countryId: value,
        regionId: '',
        city: '',
        address1: '',
        address2: '',
        zipCode: ''
      }
    }
  };
}

function setErrorsProcessor(previousState, action) {
  return {
    ...previousState,
    errors: action.payload || {}
  };
}
