import { useEffect, useMemo, useRef, useState } from 'react';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import { Autocomplete, Box, InputAdornment, TextField } from '@mui/material';
import { debounce } from 'lodash/function';
import { isEmpty } from 'lodash/lang';

import { MapsApi } from '../../../../../../../../api';
import { getCountryName, getRegionName } from '../../../../../../../../services/geographic';
import { onRequestError } from '../../../../../../../../services/handlers';

export default function Address1Autocomplete({
  name,
  countryId,
  regionId,
  city,
  address1,
  valid,
  onChange,
  onBlur,
  error,
  helperText,
  readOnly = false
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const prevRef = useRef(open);

  const value = useMemo(
    function() {
      if (!address1) {
        return { address: '', description: '' };
      }
      if (!isEmpty(options)) {
        const f = options.find(function(option) {
          return address1 === option.address;
        });
        if (!isEmpty(f)) return f;
      }
      return { address: address1, description: '' };
    },
    [address1, options]
  );

  const getPredictions = useMemo(function() {
    return debounce(function(req, resolve, reject, finish) {
      MapsApi.autocompleteAddress(req)
        .then(resolve, reject)
        .finally(finish);
    }, 1000);
  }, []);

  useEffect(
    function() {
      if (!open) {
        return;
      }
      if (address1?.length < 2 || !countryId || !regionId || !city) {
        setLoading(false);
        setOptions([]);
        return;
      }
      const request = prepareRequest(address1, city, countryId, regionId);
      const uid = Object.values(request).join('.');
      if (uid === prevRef.current) {
        return;
      }
      prevRef.current = uid;
      let canceled = false;
      setLoading(true);
      getPredictions(
        request,
        function({ data }) {
          if (!canceled) {
            setOptions(data.predictions);
          }
        },
        onRequestError,
        function() {
          if (!canceled) {
            setLoading(false);
          }
        }
      );
      return function() {
        canceled = true;
      };
    },
    [city, countryId, getPredictions, address1, regionId, open]
  );

  return (
    <Autocomplete
      disabled={readOnly}
      freeSolo
      loading={loading}
      filterOptions={x => x}
      noOptionsText={address1?.length < 2 ? 'Type at least 2 characters' : 'No options'}
      value={value}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onBlur={onBlur}
      fullWidth
      getOptionKey={option => option.description}
      getOptionLabel={option => option.address}
      renderOption={function(props, option) {
        return (
          <Box component="li" {...props}>
            {option.description}
          </Box>
        );
      }}
      disablePortal
      options={loading ? [] : options}
      onInputChange={(event, newInputValue) => {
        if (address1 !== newInputValue) {
          onChange(newInputValue);
        }
      }}
      renderInput={params => (
        <TextField
          {...params}
          name={name}
          label="Address 1"
          variant="standard"
          required
          error={error}
          helperText={helperText}
          autoComplete="off"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                {valid ? <FmdGoodIcon color="primary" fontSize="small" /> : <WrongLocationIcon fontSize="small" />}
              </InputAdornment>
            )
          }}
        />
      )}
    />
  );
}

function prepareRequest(address, city, countryId, regionId) {
  const obj = { address, city };
  if (countryId) {
    obj.country = getCountryName(countryId) || null;
  }
  if (obj.country) {
    obj.region = getRegionName(regionId, countryId) || null;
  }
  return obj;
}
