import { useCallback, useContext, useEffect, useState } from 'react';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Box, Stack, TextField, Unstable_Grid2 as Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { setIn } from 'formik';
import { reduce } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import { useCurrentRoute } from 'components/root/router';

import { PatientTravelApi } from '../../../../../../api';
import Section from '../../../../../../common/data-display/Section/Section';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { onRequestError } from '../../../../../../services/handlers';
import { getPatientAgeFromDob, getPatientPreferredName } from '../../../../../../services/patient';
import { PageInfoHeader } from '../../../../../PageInfoHeader/PageInfoHeader';
import { PatientInfoHeaderContext, PatientInfoHeaderProvider } from '../../PatientInfoHeaderContext';
import { TR_BOOKED, TRST_FLIGHT } from '../EncountersSection/TravelSection/travelConstants';
import { TAB_TRAVEL } from '../EncountersSection/useActiveTab';

import AddressValidator from './AddressFields/AddressValidator';
import MainSection from './MainSection/MainSection';
import PreferredDropOffAddress from './PreferredDropOffAddress/PreferredDropOffAddress';
import PreferredPickupAddress from './PreferredPickupAddress/PreferredPickupAddress';
import PreferredTravelDateAndTime from './PreferredTravelDateAndTime/PreferredTravelDateAndTime';
import useTravelRequestState from './useTravelRequestState/useTravelRequestState';
import { validationSchema } from './useTravelRequestState/validationSchema';

import './TravelRequest.scss';

function TravelRequestComponent({ travelPreferences, encounterInfo, travelRequest }) {
  const navigate = useNavigate();
  const currentRoute = useCurrentRoute();
  const { ssuPatientId, travelRequestId } = useParams();
  const { patientInfo } = useContext(PatientInfoHeaderContext);

  const [{ values, errors }, actions] = useTravelRequestState(travelRequest, encounterInfo, travelPreferences);

  const [saving, setSaving] = useState(false);

  const isEdit = !!travelRequest;
  const readOnly = values.status === TR_BOOKED;
  const parentRouterName = currentRoute?.parent?.name;
  const backPath = parentRouterName === 'Travel Requests' ? '../../../' : isEdit ? '../../' : '../';

  const navigateBack = useCallback(
    function() {
      navigate(
        {
          pathname: backPath,
          search: createSearchParams({
            encounterTab: TAB_TRAVEL
          }).toString()
        },
        { relative: 'path' }
      );
    },
    [navigate, backPath]
  );

  return (
    <>
      <PageInfoHeader
        objectRecordLabel={patientInfo?.name + getPatientPreferredName(patientInfo)}
        pageInfo={
          <PageInfoHeader.CollapsibleList>
            <PageInfoHeader.AdditionalInfo title="Patient ID">{patientInfo?.patientId}</PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo title="Subject ID">
              {patientInfo?.subjectId || 'No Subject ID'}
            </PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo tooltip="Date of Birth">
              {patientInfo?.dob} ({getPatientAgeFromDob(patientInfo)})
            </PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo tooltip="Study">{patientInfo?.studyName}</PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo tooltip="Site">{patientInfo?.siteName}</PageInfoHeader.AdditionalInfo>
          </PageInfoHeader.CollapsibleList>
        }
      />
      <div className="eds-travel-request">
        <Section>
          <Box sx={{ flexGrow: 1, pb: 3 }} className="eds-travel-request-container">
            <MainSection
              readOnly={readOnly}
              isEdit={isEdit}
              encounterInfo={encounterInfo}
              values={values}
              errors={errors}
              actions={actions}
              navigateBack={navigateBack}
              travelRequestId={travelRequestId}
              ssuPatientId={ssuPatientId}
            />
            {values.requestedServices.includes(TRST_FLIGHT) && (
              <PreferredTravelDateAndTime readOnly={readOnly} values={values} errors={errors} actions={actions} />
            )}
            <PreferredPickupAddress
              readOnly={readOnly}
              preferredPickupAddress={travelPreferences.preferredPickupAddress}
              values={values}
              errors={errors}
              actions={actions}
            />
            <PreferredDropOffAddress
              readOnly={readOnly}
              preferredPickupAddress={travelPreferences.preferredPickupAddress}
              values={values}
              errors={errors}
              actions={actions}
            />
            <Grid container spacing={2} alignItems="flex-end" sx={{ pt: 3 }}>
              <Grid xs>
                <TextField
                  disabled={readOnly}
                  name="comments"
                  value={values.comments}
                  onChange={actions.handleChange}
                  fullWidth
                  label="Comments"
                  multiline
                  maxRows={4}
                  variant="standard"
                  inputProps={{ maxLength: 100 }}
                  sx={{ maxWidth: 400 }}
                />
              </Grid>
              <Grid xs="auto">
                <Stack spacing={2} direction="row">
                  <Button disabled={readOnly} variant="outlined" onClick={navigateBack}>
                    CANCEL
                  </Button>
                  <Button disabled={readOnly || saving} variant="contained" color="primary" onClick={submitForm}>
                    {isEdit ? 'SAVE' : 'SUBMIT REQUEST'}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Section>
      </div>
      <AddressValidator values={values} setAddressFields={actions.setAddressFields} />
    </>
  );
  function submitForm() {
    setSaving(true);
    validationSchema.validate(values, { abortEarly: false }).then(
      function() {
        (isEdit
          ? PatientTravelApi.updatePatientTravelRequest(ssuPatientId, travelRequestId, { encounterInfo, ...values })
          : PatientTravelApi.createPatientTravelRequest(ssuPatientId, { encounterInfo, ...values })
        )
          .then(function() {
            navigateBack();
            NotificationManager.success('Travel request submitted successfully');
          })
          .catch(({ response }) => {
            if (response.data.message) {
              NotificationManager.error(response.data.message);
            } else {
              NotificationManager.error('Unable to add Travel Request');
            }
          })
          .finally(function() {
            setSaving(false);
          });
      },
      function({ inner }) {
        if (inner) {
          actions.setErrors(parseValidationErrors(inner));
        }
        setSaving(false);
      }
    );
  }
}

function parseValidationErrors(inner) {
  return reduce(
    inner,
    function(accumulator, { path, message }) {
      return setIn(accumulator, path, message);
    },
    {}
  );
}

export default function TravelRequest() {
  const [searchParams] = useSearchParams();
  const { patientId, ssuPatientId, travelRequestId } = useParams();
  const [state, setState] = useState({});
  const ssuPatientEncounterId = searchParams.get('ssuPatientEncounterId');
  const protocolEncounterKey = searchParams.get('protocolEncounterKey');
  const protocolSituationalEncounterId = searchParams.get('protocolSituationalEncounterId');
  const encounterLookUpId = ssuPatientEncounterId || protocolEncounterKey || protocolSituationalEncounterId;

  useEffect(
    function() {
      const promises = [];

      if (patientId) {
        promises.push(PatientTravelApi.getTravelPreferences(patientId));
      }

      if (ssuPatientId) {
        if (encounterLookUpId) {
          promises.push(PatientTravelApi.getEncounter(ssuPatientId, encounterLookUpId));
        }

        if (travelRequestId) {
          promises.push(PatientTravelApi.getPatientTravelRequest(ssuPatientId, travelRequestId));
        }
      }

      Promise.all(promises).then(function([res1, res2, res3]) {
        setState({
          travelPreferences: res1?.data || null,
          encounterInfo: res2?.data || null,
          travelRequest: res3 || null
        });
      }, onRequestError);
    },
    [patientId, ssuPatientId, encounterLookUpId, travelRequestId]
  );

  return (
    <PatientInfoHeaderProvider>
      {isEmpty(state) ? null : (
        <TravelRequestComponent
          travelPreferences={state.travelPreferences}
          encounterInfo={state.encounterInfo}
          travelRequest={state.travelRequest}
        />
      )}
    </PatientInfoHeaderProvider>
  );
}
