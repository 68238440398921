import React, { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import modalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import { APPROVE_SITE_PAYMENTS, MANAGE_SITE_PAYMENTS } from '../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../services/auth';
import { toBill } from '../../../../../../services/financial';
import { useCurrentUser } from '../../../../../root/Container/CurrentUserContainer';
import { SitePaymentsContext } from '../../SitePaymentsContext';

import { CreateBillModal } from './CreateBillModal/CreateBillModal';
import { RejectModal } from './RejectModal/RejectModal';

export const SelectedItemsMenu = () => {
  const { tableData = [], checkedEvents, onResetToOpen, applyFilter, onApprove, onMarkAsPaid } = useContext(
    SitePaymentsContext
  );
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const fullSelectedEvents = useMemo(() => tableData.filter(({ itemSiteId }) => checkedEvents.includes(itemSiteId)), [
    tableData,
    checkedEvents
  ]);

  const readyForApprovalEvents = useMemo(() => prepareItemsForReadyForApproval(fullSelectedEvents), [
    fullSelectedEvents
  ]);

  const allEventsHaveSameSSU = useMemo(
    () => fullSelectedEvents.every(ledgerEvent => ledgerEvent.ssuId === fullSelectedEvents[0].ssuId),
    [fullSelectedEvents]
  );

  const showResetToOpen = useMemo(() => {
    return fullSelectedEvents.every(ledgerEvent =>
      ['READY_FOR_APPROVAL', 'REJECTED'].includes(ledgerEvent.siteApprovalStatus)
    );
  }, [fullSelectedEvents]);

  const readyForApprovalEnabled = useMemo(() => {
    const everyEventHasSiteBillNumber = fullSelectedEvents.every(ledgerEvent => ledgerEvent.siteBillNumber);
    const allEventsHaveSiteApprovalStatusOpenOrRejected = fullSelectedEvents.every(ledgerEvent =>
      ['OPEN', 'REJECTED'].includes(ledgerEvent.siteApprovalStatus)
    );
    return everyEventHasSiteBillNumber && allEventsHaveSameSSU && allEventsHaveSiteApprovalStatusOpenOrRejected;
  }, [allEventsHaveSameSSU, fullSelectedEvents]);

  const createSiteBillEnabled = useMemo(() => {
    const everyEventHasNoSiteBillNumber = fullSelectedEvents.every(ledgerEvent => !ledgerEvent.siteBillNumber);
    const allEventsHaveSiteApprovalStatusOpen = fullSelectedEvents.every(ledgerEvent =>
      ['OPEN'].includes(ledgerEvent.siteApprovalStatus)
    );
    const everyEventHasParentWithBillNumberOrParentWasSelected = fullSelectedEvents.every(ledgerEvent => {
      const parentEvent = tableData.find(
        ev => ev.eventNumber === ledgerEvent.eventNumber && ev.adjustmentSequence === 0
      );
      return (
        ledgerEvent.withoutParent || ledgerEvent.parentSiteBillNumber || checkedEvents.includes(parentEvent?.itemSiteId)
      );
    });
    return (
      everyEventHasNoSiteBillNumber &&
      allEventsHaveSameSSU &&
      allEventsHaveSiteApprovalStatusOpen &&
      everyEventHasParentWithBillNumberOrParentWasSelected
    );
  }, [allEventsHaveSameSSU, checkedEvents, fullSelectedEvents, tableData]);

  const approveEnabled = useMemo(() => {
    const allEventsWasMarkedAsReadyForApprovalNotByCurrentUser = fullSelectedEvents.every(
      ({ readyForApprovalBy }) => readyForApprovalBy !== currentUser.personnelIdentifier
    );
    const allEventsHaveSiteApprovalStatusReadyForApproval = fullSelectedEvents.every(({ siteApprovalStatus }) =>
      ['READY_FOR_APPROVAL'].includes(siteApprovalStatus)
    );
    return (
      allEventsHaveSameSSU &&
      allEventsWasMarkedAsReadyForApprovalNotByCurrentUser &&
      allEventsHaveSiteApprovalStatusReadyForApproval
    );
  }, [allEventsHaveSameSSU, currentUser.personnelIdentifier, fullSelectedEvents]);

  const rejectEnabled = useMemo(() => {
    const allEventsHaveSiteApprovalStatusReadyForApprovalOrApproved = fullSelectedEvents.every(
      ({ siteApprovalStatus }) => ['READY_FOR_APPROVAL', 'APPROVED'].includes(siteApprovalStatus)
    );
    const allEventsHaveSitePaymentStatusNotPaid = fullSelectedEvents.every(
      ({ sitePaymentStatus }) => !['PAID'].includes(sitePaymentStatus)
    );
    return (
      allEventsHaveSameSSU &&
      allEventsHaveSiteApprovalStatusReadyForApprovalOrApproved &&
      allEventsHaveSitePaymentStatusNotPaid
    );
  }, [allEventsHaveSameSSU, fullSelectedEvents]);

  const markAsPaidEnabled = useMemo(() => {
    const allEventsHaveSiteApprovalStatusApproved = fullSelectedEvents.every(({ siteApprovalStatus }) =>
      ['APPROVED'].includes(siteApprovalStatus)
    );
    const allEventsHaveSitePaymentStatusPending = fullSelectedEvents.every(({ sitePaymentStatus }) =>
      ['PENDING'].includes(sitePaymentStatus)
    );
    return allEventsHaveSameSSU && allEventsHaveSiteApprovalStatusApproved && allEventsHaveSitePaymentStatusPending;
  }, [allEventsHaveSameSSU, fullSelectedEvents]);

  return checkedEvents.length ? (
    <div className="invoice-selected-results-MUI">
      <div>{`${checkedEvents.length} Event IDs selected`}</div>
      <div>
        <>
          {userHasAccessTo(MANAGE_SITE_PAYMENTS) && showResetToOpen && (
            <Button priority="low" size="h28" onClick={() => onResetToOpen(checkedEvents)}>
              Reset to Open
            </Button>
          )}
          {userHasAccessTo(MANAGE_SITE_PAYMENTS) && readyForApprovalEnabled && (
            <Button
              priority="low"
              size="h28"
              onClick={() => {
                navigate('/site-payments/ready-for-approval', {
                  state: { checkedEvents: readyForApprovalEvents }
                });
              }}
            >
              Ready for Approval
            </Button>
          )}
          {userHasAccessTo(MANAGE_SITE_PAYMENTS) && createSiteBillEnabled && (
            <Button priority="low" size="h28" onClick={() => openCreateBillModal(fullSelectedEvents, applyFilter)}>
              Create Bill
            </Button>
          )}
          {userHasAccessTo(APPROVE_SITE_PAYMENTS) && approveEnabled && (
            <Button priority="low" size="h28" onClick={() => onApprove(checkedEvents)}>
              Approve
            </Button>
          )}
          {userHasAccessTo(APPROVE_SITE_PAYMENTS) && rejectEnabled && (
            <Button priority="low" size="h28" onClick={() => openRejectModal(checkedEvents, applyFilter)}>
              Reject
            </Button>
          )}
          {userHasAccessTo(APPROVE_SITE_PAYMENTS) && markAsPaidEnabled && (
            <Button priority="low" size="h28" onClick={() => onMarkAsPaid(checkedEvents)}>
              Mark as Paid
            </Button>
          )}
        </>
      </div>
    </div>
  ) : null;
};

export const prepareItemsForReadyForApproval = selectedEvents => {
  return selectedEvents.map(ledgerEvent => {
    return {
      sitePaymentType: ledgerEvent.sitePaymentType,
      adjustmentSequence: ledgerEvent.adjustmentSequence,
      eventNumber: ledgerEvent.eventNumber,
      siteAmount: ledgerEvent.siteAmount,
      sitePaidAmount: ledgerEvent.sitePaidAmount,
      approvedAmount: toBill(ledgerEvent.siteAmount),
      variance: -(ledgerEvent.siteAmount - ledgerEvent.sitePaidAmount),
      sitePaymentComment: ledgerEvent.sitePaymentComment,
      siteApprovalStatus: ledgerEvent.siteApprovalStatus,
      itemSiteId: ledgerEvent.itemSiteId,
      siteName: ledgerEvent.siteName,
      pcn: ledgerEvent.pcn
    };
  });
};

export const openCreateBillModal = (checkedEvents, applyFilter) => {
  modalBoxes.open({
    component: <CreateBillModal checkedEvents={checkedEvents} applyFilter={applyFilter} />,
    title: 'Confirm Bill Creation',
    size: 'w650'
  });
};

export const openRejectModal = (checkedEvents, applyFilter) => {
  modalBoxes.open({
    component: <RejectModal checkedEvents={checkedEvents} applyFilter={applyFilter} />,
    title: 'Reject',
    size: 'w350'
  });
};
