import { READY_FOR_APPROVAL, SITE_PAYMENTS } from '../../../../constants/accessToPages';
import ReadyForApproval from '../ReadyForApproval/ReadyForApproval';

import SitePayments from './SitePayments';

export const sitePaymentRouterConfig = {
  name: 'Site Payments',
  path: '/site-payments',
  rootBreadcrumbName: 'Finance',
  component: SitePayments,
  access: [SITE_PAYMENTS],
  nested: [
    {
      name: 'Ready for Approval',
      path: '/ready-for-approval',
      component: ReadyForApproval,
      access: [READY_FOR_APPROVAL]
    }
  ]
};
