import { useEffect } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useFormikContext } from 'formik';

import { MapsApi } from '../../../../../../../../api';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../../../../constants/dateFormat';
import { toFormattedBill } from '../../../../../../../../services/financial';
import { getCountryName, getRegionName } from '../../../../../../../../services/geographic';
import { getDistance } from '../services';

import AddressFields from './AddressFields/AddressFields';
import DistanceField from './DistanceField/DistanceField';
import {
  useMileageAddressesActions,
  useMileageAddressesState,
  withMileageAddressesProvider
} from './MileageAddressesContext/MileageAddressesContext';
import AddressValidator from './AddressValidator';

function MileageAddresses() {
  const { ssuPatientDetails, mileageAddresses, syncing } = useMileageAddressesState(),
    { setAddressField, setAddressType } = useMileageAddressesActions();

  const { values, handleBlur, touched, errors } = useFormikContext();
  const { travelDate, startAddress, endAddress, distance, calculatedDistance, roundTrip } = mileageAddresses;

  useEffect(
    function() {
      if (startAddress.valid && endAddress.valid) {
        MapsApi.getDistance({
          origin: {
            address: startAddress.address1,
            city: startAddress.city,
            country: getCountryName(startAddress.countryId),
            region: getRegionName(startAddress.regionId, startAddress.countryId),
            zip: startAddress.zipCode
          },
          destination: {
            address: endAddress.address1,
            city: endAddress.city,
            country: getCountryName(endAddress.countryId),
            region: getRegionName(endAddress.regionId, endAddress.countryId),
            zip: endAddress.zipCode
          }
        }).then(
          function({ data }) {
            setAddressField('mileageAddresses.calculatedDistance', data.distance);
          },
          () => {
            setAddressField('mileageAddresses.calculatedDistance', '');
          }
        );
      } else {
        setAddressField('mileageAddresses.calculatedDistance', '');
      }
    },
    [
      endAddress.address1,
      endAddress.city,
      endAddress.countryId,
      endAddress.regionId,
      endAddress.valid,
      endAddress.zipCode,
      setAddressField,
      startAddress.address1,
      startAddress.city,
      startAddress.countryId,
      startAddress.regionId,
      startAddress.valid,
      startAddress.zipCode
    ]
  );

  return (
    <>
      <AddressValidator />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={4}>
          <Grid
            xs={12}
            sx={{ backgroundColor: '#ffffff', mt: 2, borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.12)' }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid xs={3}>
                  <Typography variant="subtitle1">Start Address</Typography>
                </Grid>
                <Grid xs={6}>
                  <FormControl>
                    <RadioGroup
                      row
                      value={startAddress.type}
                      onChange={function({ target }) {
                        setAddressType('startAddress', target.value);
                        AddressValidator.revalidate('startAddress');
                      }}
                    >
                      <FormControlLabel
                        sx={{ margin: 0 }}
                        value="PATIENT_HOME_ADDRESS"
                        control={<Radio />}
                        label="Patient home address"
                      />
                      <FormControlLabel sx={{ margin: 0 }} value="OTHER" control={<Radio />} label="Other" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid xs={3}>
                  <DesktopDatePicker
                    disableFuture={true}
                    name="travelDate"
                    value={travelDate}
                    onChange={function(date) {
                      setAddressField('mileageAddresses.travelDate', date);
                    }}
                    label="Travel Date"
                    format={DD_SLASH_MMM_SLASH_YYYY}
                    views={['year', 'month', 'day']}
                    slotProps={{
                      textField: {
                        variant: 'standard',
                        required: true,
                        fullWidth: true,
                        error: touched.mileageAddresses?.travelDate && Boolean(errors?.mileageAddresses?.travelDate),
                        helperText: touched.mileageAddresses?.travelDate && errors?.mileageAddresses?.travelDate,
                        onBlur: handleBlur
                      }
                    }}
                    required
                  />
                </Grid>
                <AddressFields path="startAddress" />
              </Grid>
            </Box>
          </Grid>
          <Grid
            xs={12}
            sx={{ backgroundColor: '#ffffff', mt: 2, borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.12)' }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid xs={3}>
                  <Typography variant="subtitle1">End Address</Typography>
                </Grid>
                <Grid xs={6}>
                  <FormControl>
                    <RadioGroup
                      row
                      value={endAddress.type}
                      onChange={function({ target }) {
                        setAddressType('endAddress', target.value);
                        AddressValidator.revalidate('endAddress');
                      }}
                    >
                      <FormControlLabel
                        sx={{ margin: 0 }}
                        value="SITE_ADDRESS"
                        control={<Radio />}
                        label="Site Address"
                      />
                      <FormControlLabel sx={{ margin: 0 }} value="OTHER" control={<Radio />} label="Other" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <AddressFields path="endAddress" />
              </Grid>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid xs={3}>
                  <FormControlLabel
                    label="Round Trip"
                    disabled={!calculatedDistance}
                    sx={{ margin: 0 }}
                    control={
                      <Checkbox
                        checked={roundTrip}
                        onChange={function(e, value) {
                          setAddressField('mileageAddresses.roundTrip', value);
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid xs={9}>
                  Calculated distance:{' '}
                  {calculatedDistance
                    ? `${getDistance(roundTrip, calculatedDistance)} Mileage`
                    : 'Unable to calculate distance'}
                </Grid>
                <DistanceField
                  distance={distance}
                  setAddressField={setAddressField}
                  handleBlur={handleBlur}
                  error={!syncing && touched.mileageAddresses?.distance && Boolean(errors.mileageAddresses?.distance)}
                  helperText={!syncing && touched.mileageAddresses?.distance && errors.mileageAddresses?.distance}
                />
                <Grid xs={9}>Calculated reimbursement: {calculatedReimbursement()}</Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
  function calculatedReimbursement() {
    if (ssuPatientDetails.costPerMileage) {
      return toFormattedBill(values.amount);
    }
    return (
      <Typography variant="body2" sx={{ color: 'error.main' }} component="span">
        No cost per mileage is defined in Study Details page
      </Typography>
    );
  }
}

export default withMileageAddressesProvider(MileageAddresses);
