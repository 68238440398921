import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { Menu, MenuGroup, MenuItem } from '@szhsin/react-menu';
import { isEmpty } from 'lodash';

import { PatientTravelApi } from '../../../../../../../api';
import { STDT_TRAVEL } from '../../../../../../../constants/study/studyTypes';
import { MANAGE_PATIENT_TRAVEL_REQUEST } from '../../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../../services/auth';
import { useCurrentRoute } from '../../../../../../root/router';
import { PatientInfoContext } from '../../PatientInfoContext';

export function AddUnexpectedTravelButton() {
  const navigate = useNavigate();
  const [situationalEncounters, setSituationalEncounters] = useState([]);
  const { currentStudy } = useContext(PatientInfoContext);
  const currentRoute = useCurrentRoute();
  const ssuPatientId = currentRoute.params?.ssuPatientId;
  const studyTypes = currentStudy.types;

  useEffect(() => {
    if (ssuPatientId && isTravelStudy(studyTypes) && userHasAccessTo(MANAGE_PATIENT_TRAVEL_REQUEST)) {
      PatientTravelApi.getSituationalEncounters(ssuPatientId).then(res => {
        setSituationalEncounters(res.data);
      });
    }
  }, [ssuPatientId, studyTypes]);

  if (!isTravelStudy(studyTypes)) {
    return <></>;
  }

  if (isEmpty(situationalEncounters)) {
    return (
      <Button variant="outlined" size="small" disabled={true}>
        Add unexpected Travel
      </Button>
    );
  }

  if (situationalEncounters.length === 1) {
    return (
      <Button
        variant="outlined"
        size="small"
        onClick={() => {
          navigate(`./travel-request/?protocolSituationalEncounterId=${situationalEncounters[0].id}`);
        }}
      >
        Add unexpected Travel
      </Button>
    );
  } else {
    return (
      <Menu
        align="start"
        direction="left"
        portal={true}
        menuButton={
          <Button variant="outlined" size="small">
            Add unexpected Travel
          </Button>
        }
      >
        <MenuGroup>
          {situationalEncounters.map(enc => (
            <MenuItem
              key={enc.id}
              onClick={() => {
                navigate(`./travel-request/?protocolSituationalEncounterId=${enc.id}`);
              }}
              title={enc.name.length > 50 ? enc.name : null}
              className="bold-text"
            >
              {enc.name}
            </MenuItem>
          ))}
        </MenuGroup>
      </Menu>
    );
  }
}

function isTravelStudy(types) {
  return types?.includes(STDT_TRAVEL);
}
