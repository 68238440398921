import { useCallback, useState } from 'react';
import { isEqual, isFunction } from 'lodash';
import { get } from 'lodash/object';

import { useCurrentUser } from 'components/root/Container/CurrentUserContainer';

const useSessionStorageWithRole = (key, initialValue) => {
  const { roleBasedId } = useCurrentUser();

  const [storedValue, setStoredValue] = useState(() => {
    const filterString = sessionStorage.getItem(key) || '{}';
    const filterElement = JSON.parse(filterString);
    const item = get(filterElement, roleBasedId);

    return item || initialValue;
  });

  const setValue = useCallback(
    value => {
      setStoredValue(storedValue => {
        if (isEqual(value, storedValue)) {
          return storedValue;
        }
        const valueToStore = isFunction(value) ? value(storedValue) : value;
        const filterString = sessionStorage.getItem(key) || '{}';
        const filterElement = JSON.parse(filterString);
        filterElement[roleBasedId] = valueToStore;

        sessionStorage.setItem(key, JSON.stringify(filterElement));
        return valueToStore;
      });
    },
    [key, roleBasedId]
  );
  return [storedValue, setValue];
};

export default useSessionStorageWithRole;
