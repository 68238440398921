import React, { Component } from 'react';
import jsplumb from 'jsplumb';
import { findIndex, uniqBy } from 'lodash/array';
import { cloneDeep, isArray, isEmpty, isEqual, isString } from 'lodash/lang';
import moment from 'moment';

import { ProtocolApi, ProtocolBranchesApi } from '../../../../../api';
import common from '../../../../../common/common';
import Section from '../../../../../common/data-display/Section/Section';
import Input from '../../../../../common/data-entry/Input';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';
import { withAppInfo } from '../../../../../common/hocs/withAppInfo';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { SOURCE_DATA_SETUP_SAVED } from '../../../../../constants/notificationMessages';
import { endWindow, startWindow } from '../../../../../constants/protocolConstant';
import {
  optionPaintStyle,
  outline,
  primaryPaintStyle,
  warningPaintStyle
} from '../../../../../constants/protocolPaintStyles';
import { scPurple80 } from '../../../../../constants/systemColors';
import { pendoTrack } from '../../../../../pendo/PendoUtils';
import { PageInfoHeader } from '../../../../PageInfoHeader/PageInfoHeader';
import { generateUrlByKey } from '../../../../root/router';
import { withNavigate, withParams } from '../../../../root/router/legacyComponentCompatability';

import CommentSection from './CommentSection/CommentSection';
import EncounterDetails from './EncounterDetailsModal/EncounterDetailsModal';
import ChangeProtocolEncounterOrderingModal from './EncounterSetup/ChangeProtocolEncounterOrderingModal/ChangeProtocolEncounterOrderingModal';
import {
  sortEpochsAndEncountersByOriginSequence,
  sortEpochsAndEncountersByProtocolSequence
} from './EncounterSetup/services';
import EpochDetailsModal from './EpochDetailsModal/EpochDetailsModal';
import AdverseEventLogWrongGroupAssignConfigModal from './ProtocolGroupsSetup/AdverseEventLogWrongGroupAssignConfigModal';
import PublishModal from './PublishModal/PublishModal';
import ProtocolEpoch from './ProtocolEpoch';
import { getEncounterAbbreviation, removeRelationInOtherEncounters } from './protocolSetupService';

import 'jsplumb/css/jsplumbtoolkit-defaults.css';
import './ProtocolSetup.scss';

class ProtocolSetup extends Component {
  constructor(props) {
    super(props);

    this.jsPlumbInstance = null;
    this.state = {
      setupRules: [],
      protocolName: '',
      currentProtocolName: '',
      currentVersion: '',
      versionId: '',
      terminologyVersionSet: [],
      studyName: '',
      protocolId: '',
      epochsRelationsBroken: [],
      encountersRelationsBroken: [],
      comment: '',
      updatedComment: '',
      unexpectedEncountersSetup: [],
      situationalEncountersSetup: []
    };
    this.allEndPoints = {};
    this.errorMsg = null;
    this.primaryConnections = {};
  }

  removeRelations(source, target) {
    this.jsPlumbInstance.select({ source, target }).delete();
    this.reconstructPrimaryConnections(source);
  }

  findEpochNodeKeysWhichHasNotConnectionWithCurrent(epochList, epoch, currentEpochNodeKey) {
    const nodeKeysList = [],
      isEpochAbsentInPreviousEpochLine = !this.getPreviousEpochs(epoch)
        .map(({ nodeKey }) => nodeKey)
        .includes(currentEpochNodeKey);
    if (isEpochAbsentInPreviousEpochLine) {
      nodeKeysList.push(epoch.nodeKey);
      epoch.child
        .filter(nodeKey => !isEqual(nodeKey, endWindow))
        .forEach(nodeKey => {
          const epoch = this.findEpochByNodeKey(epochList, nodeKey);
          nodeKeysList.push(
            ...this.findEpochNodeKeysWhichHasNotConnectionWithCurrent(epochList, epoch, currentEpochNodeKey)
          );
        });
    }
    return nodeKeysList;
  }

  findEpochByNodeKey(epochList, loseChildEpochId) {
    return epochList.find(({ nodeKey }) => isEqual(loseChildEpochId, nodeKey));
  }

  deleteRelationsBetweenAllConnectedEncounter(setupRules, loseChildEpochId, loseParentEpochId) {
    const loseChildEpoch = this.findEpochByNodeKey(setupRules, loseChildEpochId),
      loseParentEpoch = this.findEpochByNodeKey(setupRules, loseParentEpochId),
      previousEpochs = this.getPreviousEpochs(loseChildEpoch),
      epochIdList = setupRules.map(({ nodeKey }) => nodeKey);
    [...previousEpochs, loseChildEpoch].forEach(epoch => {
      const epochForDeleteEncounters = this.findEpochNodeKeysWhichHasNotConnectionWithCurrent(
          setupRules,
          loseParentEpoch,
          epoch.nodeKey
        ),
        encounters = epoch.encounters.map(({ enNodeKey }) => enNodeKey),
        filter = epochIdList.filter(node => !epochForDeleteEncounters.includes(node));
      this.removeRelationInOtherEncounters(setupRules, encounters, filter);
    });
  }

  removeAllRelationsWithAnotherEpochEncounter(loseChildEpochId, loseParentEpochId) {
    this.setState(({ setupRules }) => {
      this.deleteRelationsBetweenAllConnectedEncounter(setupRules, loseChildEpochId, loseParentEpochId);
      const { epochsRelationsBroken, encountersRelationsBroken } = this.checkAllEncountersAndEpochsForValid(setupRules);
      return {
        setupRules,
        epochsRelationsBroken,
        encountersRelationsBroken
      };
    });
  }

  removeUiConnection = et => {
    const source = et.component.source.id;
    const target = et.component.target.id;
    this.removeRelations(source, target);
    !isEqual(source, startWindow) &&
      !isEqual(target, endWindow) &&
      this.removeAllRelationsWithAnotherEpochEncounter(source, target);
  };

  componentDidMount() {
    this.initializeJsPlumb();
    this.getStudySetupDetails();
    window.addEventListener('resize', () => {
      try {
        this.jsPlumbInstance.repaintEverything();
      } catch (e) {
        console.error(e);
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => {
      try {
        this.jsPlumbInstance.repaintEverything();
      } catch (e) {
        console.error(e);
      }
    });
  }

  checkAllEncountersAndEpochsForValid(epochList) {
    const epochs = epochList
      .filter(epoch => isEmpty(epoch.parent) || isEmpty(epoch.child) || isEmpty(epoch.encounters))
      .map(epoch => epoch.nodeKey);

    const encounters = epochList
      .map(ep =>
        ep.encounters
          .filter(encounter => !encounter.nonProtocol && !this.validateEncounter(encounter, ep))
          .map(encounter => encounter.enNodeKey)
      )
      .flat();
    return { epochsRelationsBroken: epochs, encountersRelationsBroken: encounters };
  }

  addEncounter = (pEle, nonProtocol, childCount) => {
    const epochsList = this.state.setupRules;
    const index = findIndex(epochsList, x => {
      return x.nodeKey === pEle;
    });
    if (index > -1) {
      const encounter = this.getNewEncounterConfig(pEle, nonProtocol, childCount);
      const oldEpochData = epochsList[index];
      const copyEncounters = [...oldEpochData.encounters];
      copyEncounters.push(encounter);
      const newEpochData = cloneDeep(oldEpochData);
      newEpochData.encounters = copyEncounters;
      epochsList[index] = newEpochData;

      this.setState(
        () => {
          const { epochsRelationsBroken, encountersRelationsBroken } = this.checkAllEncountersAndEpochsForValid(
            epochsList
          );
          return { epochsRelationsBroken, encountersRelationsBroken, setupRules: epochsList };
        },
        () => {
          this.jsPlumbInstance.repaintEverything();
        }
      );
    }
  };

  initializeJsPlumb() {
    jsplumb.jsPlumb.ready(() => {
      const arrowOverlay = ['Arrow', { location: 0.7 }, { foldback: 0.7, width: 14 }];
      const removeConnectionOverlay = [
        'Custom',
        {
          create() {
            const el = document.createElement('span');
            el.innerHTML = "<i class='glyphicon glyphicon-remove-circle'></i>";
            return el;
          },
          location: 0.4,
          events: {
            click: et => {
              this.removeUiConnection(et);
              this.setState(({ setupRules }) => {
                return this.checkAllEncountersAndEpochsForValid(setupRules);
              });
            }
          }
        }
      ];
      const connectionOverlays = [arrowOverlay, removeConnectionOverlay];
      this.jsPlumbInstance = jsplumb.jsPlumb.getInstance({
        DragOptions: { cursor: 'pointer', zIndex: 2000 },
        PaintStyle: optionPaintStyle,
        EndpointHoverStyle: { fill: 'orange' },
        HoverPaintStyle: { stroke: 'orange' },
        EndpointStyle: { width: 20, height: 16, fill: scPurple80 },
        Endpoint: ['Dot', { radius: 11 }],
        MaxConnections: -1,
        ConnectionOverlays: connectionOverlays,
        Anchors: ['RightMiddle', 'LeftMiddle'],
        Container: 'canvas'
      });

      // start end setting
      this.allEndPoints.startWindow = [
        undefined,
        this.jsPlumbInstance.addEndpoint(startWindow, {
          draggable: false,
          anchor: 'Right',
          isSource: true,
          isTarget: false
        })
      ];
      this.allEndPoints.endWindow = [
        this.jsPlumbInstance.addEndpoint(endWindow, {
          draggable: false,
          anchor: 'Left',
          isSource: false,
          isTarget: true
        })
      ];
    });
  }

  removeDataConnection = info => {
    try {
      // checking for is that statwindows
      if (info.sourceId === startWindow) {
        const prevSt = this.state.setupRules;
        const idx = findIndex(prevSt, o => {
          return o.nodeKey === info.targetId;
        });
        const pidx = findIndex(prevSt[idx].parent, c => {
          return c === startWindow;
        });
        if (pidx > -1) {
          prevSt[idx].parent.splice(pidx, 1);
          this.setState({ setupRules: prevSt });
        }
      } else {
        // getting source id's  childs
        const prevSt = this.state.setupRules;
        const idx = findIndex(prevSt, o => {
          return o.nodeKey === info.sourceId;
        });
        const childIdx = findIndex(prevSt[idx].child, c => {
          return c === info.targetId;
        });
        if (childIdx > -1) {
          prevSt[idx].child.splice(childIdx, 1);
          this.setState({ setupRules: prevSt });
        }

        // getting target id's  parents
        const pidx = findIndex(prevSt, o => {
          return o.nodeKey === info.targetId;
        });
        const parentIdx = findIndex(prevSt[pidx].parent, c => {
          return c === info.sourceId;
        });
        if (parentIdx > -1) {
          prevSt[pidx].parent.splice(parentIdx, 1);
          this.setState({ setupRules: prevSt });
        }
      }
    } catch (e) {}
  };

  onConnectionCreated = info => {
    const prevSt = this.state.setupRules;
    const targetId = info.targetId;
    const idPt = findIndex(prevSt, nx => {
      return nx.nodeKey === targetId;
    });
    const sourceId = info.sourceId;
    if (idPt > -1) {
      if (prevSt[idPt].parent.indexOf(sourceId) === -1) {
        prevSt[idPt].parent.push(sourceId);
        this.setState({ setupRules: prevSt });
      }
    }

    // setting the childId
    const idChild = findIndex(prevSt, nx => {
      return nx.nodeKey === sourceId;
    });
    if (idChild > -1) {
      if (prevSt[idChild].child.indexOf(targetId) === -1) {
        prevSt[idChild].child.push(targetId);
        this.setState({ setupRules: prevSt });
      }
    }
    info.connection.bind('click', this.onConnectionClick);
    this.reconstructPrimaryConnections(sourceId);
  };

  reconstructPrimaryConnections(sourceId) {
    const connections = this.jsPlumbInstance.getConnections({ source: sourceId });
    if (connections.length === 1) {
      const connection = connections[0];
      connection.setPaintStyle(primaryPaintStyle);
      const primaryConnections = cloneDeep(this.primaryConnections);
      primaryConnections[connection.sourceId] = connection.targetId;
      this.primaryConnections = primaryConnections;
    } else {
      connections.forEach(connection => connection.setPaintStyle(optionPaintStyle));
      const primaryConnections = cloneDeep(this.primaryConnections);
      delete primaryConnections[sourceId];
      this.primaryConnections = primaryConnections;
    }
    this.isPrimaryBranchesSetupValid();
    this.setState(({ setupRules }) => this.checkAllEncountersAndEpochsForValid(setupRules));
  }

  deleteEpochEncounter = (epochId, encId) => {
    try {
      ModalBoxes.confirm({
        content: 'Are you sure you want to delete this record?',
        confirmButton: 'Yes',
        cancelButton: 'No'
      }).then(
        () => {
          this.epochEncounterDelete(epochId, encId);
        },
        () => {}
      );
    } catch (e) {}
  };

  epochEncounterDelete(epochId, encId) {
    const epochsList = this.state.setupRules;
    const eleIndex = findIndex(epochsList, o => {
      return isEqual(o.nodeKey, epochId);
    });
    if (eleIndex > -1) {
      // getting encounter index
      const encounterIndex = findIndex(epochsList[eleIndex].encounters, en => {
        return isEqual(en.enNodeKey, encId);
      });

      const epochsListWithRemovedRelationsInOtherEncounters = removeRelationInOtherEncounters(epochsList, [encId]);

      if (encounterIndex > -1) {
        epochsListWithRemovedRelationsInOtherEncounters[eleIndex].encounters.splice(encounterIndex, 1);
      }
      this.setState(
        () => {
          const { epochsRelationsBroken, encountersRelationsBroken } = this.checkAllEncountersAndEpochsForValid(
            epochsListWithRemovedRelationsInOtherEncounters
          );
          return {
            epochsRelationsBroken,
            encountersRelationsBroken,
            setupRules: epochsListWithRemovedRelationsInOtherEncounters
          };
        },
        () => {
          this.removeT0EncounterRefrence(epochId, encId, 'encounter');
        }
      );
    }
  }

  removeRelationInOtherEncounters(epochList, enNodeKeysForDelete, epochWhichWillNotLoseRelations = []) {
    epochList.forEach(({ encounters, nodeKey }, epochIndex) => {
      if (!epochWhichWillNotLoseRelations.includes(nodeKey)) {
        encounters.forEach(function({ parentEnNodeKeys }, encounterIndex) {
          epochList[epochIndex].encounters[encounterIndex].parentEnNodeKeys = parentEnNodeKeys.filter(function(
            parentEnNodeKey
          ) {
            return !enNodeKeysForDelete.includes(parentEnNodeKey);
          });
        });
      }
    });
  }

  getEncounterDetails = (epochId, enc, encId) => {
    try {
      const filterEpoch = this.state.setupRules.find(n => n.nodeKey === epochId);
      if (!isEmpty(filterEpoch)) {
        const filterEncounter = filterEpoch.encounters.find(d => d.enNodeKey === encId);
        this.encounterDetailsPopUpHandeler(filterEncounter, epochId);
      }
    } catch (e) {}
  };

  getPreviousEpochs(epoch) {
    let prevEpochs = [];
    epoch.parent.forEach(epochID => {
      const found = this.state.setupRules.find(e => e.nodeKey === epochID);
      if (epochID === startWindow) {
        return prevEpochs;
      }
      prevEpochs = prevEpochs.concat(found).concat(this.getPreviousEpochs(found));
    });
    return prevEpochs;
  }

  isMustBeStartOption(previousEncounters, parents) {
    return isEmpty(previousEncounters.filter(encounter => !encounter.nonProtocol)) && parents.includes(startWindow);
  }

  getPreviousEpochEncounterList(encounterInfo, epochId) {
    try {
      const start = { info: { enNodeKey: 'start', parentNodeKeys: [] }, name: 'Start' };
      let tempArr = [];
      const filterEpoch = this.state.setupRules.filter(n => n.nodeKey === epochId);
      if (filterEpoch.length > 0) {
        tempArr = tempArr.concat(this.getPreviousEncountersInEpoch(filterEpoch, encounterInfo));
        const epochs = this.getPreviousEpochs(filterEpoch[0]);
        const listEp = uniqBy(epochs, 'nodeKey');
        if (this.isMustBeStartOption(tempArr, filterEpoch[0].parent)) {
          tempArr.push(start);
          this.state.setupRules
            .filter(epoch => epoch.nodeKey === epochId)[0]
            .encounters.filter(encounter => encounter.enNodeKey === encounterInfo.enNodeKey)[0].fromStartWindow = true;
        } else {
          this.state.setupRules
            .filter(epoch => epoch.nodeKey === epochId)[0]
            .encounters.filter(encounter => encounter.enNodeKey === encounterInfo.enNodeKey)[0].fromStartWindow = false;
        }

        listEp &&
          listEp.forEach(epList => {
            epList.encounters.forEach(encDt => {
              if (`${encDt.nonProtocol}` === 'false') {
                const obj = {
                  id: common.getRandomNumber(),
                  name: `${epList.name}- ${encDt.encounterName}`, // encounterName displayName
                  info: encDt
                };
                tempArr.push(obj);
              }
            });
          });
      }
      return tempArr;
    } catch (e) {
      console.log('Exceptions', e);
    }
  }

  getPreviousEncountersInEpoch(filterEpoch, encounterInfo) {
    const tempArr = [];
    const encIdx = findIndex(filterEpoch[0].encounters, k => {
      const extractEnNodeKey = isArray(encounterInfo) ? encounterInfo[0].enNodeKey : encounterInfo.enNodeKey;
      return k.enNodeKey === extractEnNodeKey;
    });
    if (encIdx > 0) {
      for (let i = 0; i < encIdx; i++) {
        if (`${filterEpoch[0].encounters[i].nonProtocol}` === 'false') {
          const obj = {
            id: common.getRandomNumber(),
            name: `${filterEpoch[0].name}- ${filterEpoch[0].encounters[i].encounterName}`, // encounterName displayName
            info: filterEpoch[0].encounters[i]
          };
          tempArr.push(obj);
        }
      }
    }
    return tempArr;
  }

  // encounter details pop up
  encounterDetailsPopUpHandeler(enInfo, epochId) {
    ModalBoxes.open({
      component: (
        <EncounterDetails
          previewMode={false}
          onSaveEncounter={rec => {
            const { setupRules } = this.state;
            const idx = findIndex(setupRules, nx => {
              return nx.nodeKey === epochId;
            });
            if (idx > -1) {
              const idxEnc = findIndex(setupRules[idx].encounters, dx => {
                return dx.enNodeKey === enInfo.enNodeKey;
              });
              if (idxEnc > -1) {
                const oldEncName = enInfo.encounterName;
                rec.displayName = getEncounterAbbreviation(rec.encounterName, rec.displayName);
                Object.assign(setupRules[idx].encounters[idxEnc], rec);
                const { epochsRelationsBroken, encountersRelationsBroken } = this.checkAllEncountersAndEpochsForValid(
                  setupRules
                );
                this.setState({ setupRules: setupRules, epochsRelationsBroken, encountersRelationsBroken }, () => {
                  // Updating encounter title and abbreviation
                  if (enInfo && enInfo.enNodeKey) {
                    const enElRef = document.getElementById(enInfo.enNodeKey);
                    enElRef.children[1].setAttribute('title', rec.encounterName);
                  }
                  // check if encounter name not changed
                  if (oldEncName !== rec.encounterName) {
                    this.updateEncounterDisplayNameOnCanvas(rec.enNodeKey, rec.encounterName, rec.displayName);
                  }
                });
              }
            }
          }}
          enInfo={enInfo}
          previousEpochEncounterList={this.getPreviousEpochEncounterList(enInfo, epochId)}
        />
      )
    });
  }

  updateEncounterDisplayNameOnCanvas = (epochEncounterId, encounterName, displayName) => {
    const encounterBox = document.querySelector(`#${epochEncounterId} span[title]`);
    encounterBox.innerHTML = displayName;
  };

  epochDetailsPopUpCickHandler = (selectedEpochData, nodeId) => {
    ModalBoxes.open({
      component: (
        <EpochDetailsModal
          onSave={rec => {
            const refEle = document.getElementById(`${nodeId}_title`);
            refEle.innerText = rec.name ? common.getSubString(rec.name, 18) : nodeId;
            refEle.setAttribute('title', rec.name);
            const prevSt = this.state.setupRules;
            const idx = findIndex(prevSt, nx => {
              return nx.nodeKey === nodeId;
            });
            if (idx > -1) {
              Object.assign(prevSt[idx], rec);
              this.setState({ setupRules: prevSt });
              this.jsPlumbInstance.repaintEverything();
            }
          }}
          selectedEpochData={selectedEpochData}
        />
      )
    });
  };

  removeT0EncounterRefrence(epochKey, encounterKey, type) {
    const prevEpochState = this.state.setupRules;
    prevEpochState?.forEach(epData => {
      epData.encounters.forEach(function({ parentEnNodeKeys }) {
        if (isArray(parentEnNodeKeys) && !isEmpty(parentEnNodeKeys)) {
          parentEnNodeKeys.filter(function(parentEnNodeKey) {
            return !(
              parentEnNodeKey &&
              (type === 'epoch'
                ? epochKey && parentEnNodeKey.indexOf(epochKey) > -1
                : encounterKey && parentEnNodeKey === encounterKey)
            );
          });
        }
      });
    });

    this.setState({ setupRules: prevEpochState });
  }

  getNewEncounterConfig(pEle, nonProtocol, childCount) {
    return {
      encounterName: `E${childCount}`,
      encounterDesc: '',
      fromStartWindow: false,
      fromVisit: '',
      toVisit: '',
      fromType: '',
      toType: '',
      type: '',
      displayName: `E`,
      enNodeKey: `${pEle}_E${childCount}_${common.getRandomNumber()}`,
      elements: [],
      enDisplayName: `E${childCount}`,
      nonProtocol: nonProtocol,
      parentEnNodeKeys: [],
      encounterIdentifier: ''
    };
  }

  // get study protocol details
  getStudySetupDetails() {
    ProtocolBranchesApi.getBranches(this.props.params.protocolIdentity).then(res => {
      res.data.forEach(primaryBranch => {
        this.primaryConnections[primaryBranch.source] = primaryBranch.target;
      });
      ProtocolApi.getPreviewProtocol(this.props.params.studyId, this.props.params.protocolIdentity, 'Draft').then(
        res => {
          if (res.status === 200 && res.data && typeof res.data === 'object') {
            const sortedEpochData = sortEpochsAndEncountersByProtocolSequence(res.data.epoch);
            this.setState(
              {
                currentVersion: res.data.version,
                protocolName: res.data.name ? res.data.name : this.props.params.protocolName,
                currentProtocolName: res.data.name ? res.data.name : this.props.params.protocolName,
                versionId: res.data.versionId,
                terminologyVersionSet: res.data.terminologyVersionSet,
                protocolId: res.data.uniqueIdentifier,
                originSetupRules: res.data.epoch,
                setupRules: sortedEpochData,
                prevSetupRules: JSON.stringify(sortedEpochData),
                studyIsGroupAssign: res.data.studyIsGroupAssign,
                studyName: res.data.studyName,
                comment: res.data.comment,
                updatedComment: res.data.comment,
                unexpectedEncountersSetup: res.data.unexpectedEncountersSetup,
                situationalEncountersSetup: res.data.situationalEncountersSetup
              },
              () => {
                this.connectEpochEndPoints();
                this.checkforPublish();
              }
            );
          }
        }
      );
    });
  }

  // add new epoch
  addNewEpoch = position => {
    const count = this.state.setupRules.length + 1;
    const epochId = `epoch_${common.getRandomNumber()}`;
    const epochInfo = {
      name: `Epoch${count}`,
      description: '',
      parent: [],
      child: [],
      encounters: [],
      position: position,
      nodeKey: epochId,
      epochIdentifier: ''
    };

    this.setState(({ setupRules }) => {
      const newRules = [...setupRules, epochInfo];
      const { epochsRelationsBroken, encountersRelationsBroken } = this.checkAllEncountersAndEpochsForValid(newRules);
      return { epochsRelationsBroken, encountersRelationsBroken, setupRules: newRules };
    });
  };

  createEpochElemensOnCanvas = () => {
    if (this.state.setupRules && this.state.setupRules.length) {
      pendoTrack('Epoch in protocol changed', {
        pageName: window.location.pathname
      });
      return this.state.setupRules.map((epochInfo, epochIdx) => {
        return (
          <ProtocolEpoch
            key={epochInfo.nodeKey}
            epochIdx={epochIdx}
            epochInfo={epochInfo}
            addEpochDetails={this.addEpochDetails}
            addEncounter={this.addEncounter}
            destruct={this.deleteEpoch}
            getEncounterDetails={this.getEncounterDetails}
            deleteEpochEncounter={this.deleteEpochEncounter}
            addEndpointsForAnEpoch={this.addEndpointsForAnEpoch}
            makeEpochDraggable={this.makeEpochDraggable}
            epochsRelationsBroken={this.state.epochsRelationsBroken}
            encountersRelationsBroken={this.state.encountersRelationsBroken}
            openChangeProtocolEncounterOrderingModal={this.openChangeProtocolEncounterOrderingModal}
          />
        );
      });
    }
  };

  makeEpochDraggable = elId => {
    this.jsPlumbInstance.draggable(elId, {
      drag: event => {
        try {
          const node = event.el;

          if (parseInt(node.style.top) < 0) {
            node.style.top = '0px';
          }
          if (parseInt(node.style.left) < 0) {
            node.style.left = '0px';
          }

          const prevSt = this.state.setupRules;

          const idx = findIndex(prevSt, o => {
            return o.nodeKey === elId;
          });
          if (idx > -1) {
            prevSt[idx].position.left = event.pos[0];
            prevSt[idx].position.top = event.pos[1];
            this.setState({ setupRules: prevSt });
          }
        } catch (e) {
          console.log('error Occure ', e);
        }
      }
    });
  };

  addEndpointsForAnEpoch = elId => {
    // configure some drop options for use by all endpoints.
    const dropOptionsConfig = {
      tolerance: 'touch',
      hoverClass: 'dropHover',
      activeClass: 'dragActive'
    };
    const sharedConfig = {
      dropOptions: dropOptionsConfig,
      ...outline,
      beforeDrop: this.isConnectionAlreadyCreated
    };
    this.allEndPoints[elId] = [
      this.jsPlumbInstance.addEndpoint(elId, {
        anchor: 'LeftMiddle',
        ...sharedConfig,
        isSource: false,
        isTarget: true
      }),
      this.jsPlumbInstance.addEndpoint(elId, {
        anchor: 'RightMiddle',
        ...sharedConfig,
        isSource: true,
        isTarget: false
      })
    ];
  };

  isConnectionAlreadyCreated = params => {
    if (params.sourceId === params.targetId) {
      return false;
    }
    const allConnections = this.jsPlumbInstance.getAllConnections();
    const filterCount = allConnections.filter(o => {
      return o.sourceId === params.sourceId && o.targetId === params.targetId;
    });
    return filterCount.length <= 0;
  };

  // creating elements on canvas and make connections
  connectEpochEndPoints() {
    this.state.setupRules.forEach(ep => {
      if (ep.parent.includes(startWindow)) {
        this.connectEndpoint(startWindow, ep.nodeKey);
      }
      ep.child.forEach(epochChildId => {
        this.connectEndpoint(ep.nodeKey, epochChildId);
      });
    });
    this.jsPlumbInstance.batch(() => {
      this.jsPlumbInstance.bind('connection', this.onConnectionCreated);
      this.jsPlumbInstance.bind('connectionDetached', this.removeDataConnection);
    });
  }

  connectEndpoint(sourceId, targetId) {
    let paintStyle;
    if (this.primaryConnections[sourceId] === targetId) {
      paintStyle = primaryPaintStyle;
    } else {
      paintStyle = optionPaintStyle;
    }
    const source = this.allEndPoints[sourceId][1];
    let target = this.allEndPoints[targetId][0];
    const connection = this.jsPlumbInstance.connect({ source, target, paintStyle });
    connection.bind('click', this.onConnectionClick);
  }

  onConnectionClick = conn => {
    const clickedSourceId = conn.sourceId;
    const clickedTargetId = conn.targetId;

    const copyPrimaryConnections = cloneDeep(this.primaryConnections);
    copyPrimaryConnections[clickedSourceId] = clickedTargetId;
    this.primaryConnections = copyPrimaryConnections;
    const sourceConnections = this.jsPlumbInstance.getConnections({ source: clickedSourceId });
    sourceConnections.forEach(connection => {
      if (connection !== conn) {
        connection.setPaintStyle(optionPaintStyle);
      } else {
        connection.setPaintStyle(primaryPaintStyle);
      }
    });
  };

  navigateToSetupProtocol() {
    this.props.navigate(generateUrlByKey('Setup Protocol'));
  }

  // for canceling the changes
  cancelProtocolSetup = () => {
    const encounterArr = [];
    try {
      if (this.state.setupRules.length) {
        const oldRules = JSON.parse(this.state.prevSetupRules);
        const currentSetup = this.state.setupRules;

        if (oldRules.length === currentSetup.length) {
          currentSetup.forEach((info, idx) => {
            if (info.encounters.length !== oldRules[idx].encounters.length) {
              encounterArr.push(false);
            }
          });

          if (encounterArr.indexOf(false) > -1) {
            this.checkForRedirect();
          } else {
            this.navigateToSetupProtocol();
          }
        } else {
          this.checkForRedirect();
        }
      } else {
        this.checkForRedirect();
      }
    } catch (e) {}
  };

  // for redirecting page
  checkForRedirect() {
    ModalBoxes.confirm({
      content:
        'You have entered the data on this page. If you navigate away from this page without first saving your data, the changes will be lost',
      title: 'Are you sure?',
      confirmButton: 'Yes',
      cancelButton: 'No'
    }).then(
      () => {
        this.navigateToSetupProtocol();
      },
      () => {}
    );
  }

  // check for publishing setup
  checkforPublish() {
    if (this.validateProtocolSetup('publish', true)) {
      this.setState({ isReadyToPublish: true });
    } else {
      this.setState({ isReadyToPublish: false });
    }
  }

  isPrimaryBranchesSetupValid() {
    let isChildrenValid = true;
    for (const epoch of this.state.setupRules) {
      const epochId = epoch.nodeKey;
      if (this.primaryConnections[epochId] === undefined) {
        this.jsPlumbInstance
          .getConnections({ source: epochId })
          .forEach(connection => connection.setPaintStyle(warningPaintStyle));
        isChildrenValid = false;
      }
    }
    const isStartWindowValid = this.primaryConnections[startWindow] !== undefined;
    if (!isStartWindowValid) {
      this.jsPlumbInstance
        .getConnections({ source: startWindow })
        .forEach(connection => connection.setPaintStyle(warningPaintStyle));
    }
    const isValid = isChildrenValid && isStartWindowValid;
    if (!isValid) {
      this.errorMsg = 'Primary branches is not set up';
    }
    return isValid;
  }

  // validating  the setup for save
  validateProtocolSetup(type, initial = false) {
    let isValid;
    const validationArray = [];
    const isPrimaryBranchesSetupValid = this.isPrimaryBranchesSetupValid();
    let connectedToStart = false;
    let connectedToEnd = false;
    validationArray.push(isPrimaryBranchesSetupValid);
    const setupRules = this.state.setupRules;
    const prevSetupRules = JSON.parse(this.state.prevSetupRules);
    if (setupRules && setupRules.length) {
      for (let i = 0, x = setupRules.length; i < x; ++i) {
        const hasEncounter = !!setupRules[i].encounters.length;
        const hasEpochConnected = !!(setupRules[i].parent.length && setupRules[i].child.length);

        if (!hasEncounter) {
          this.errorMsg = `No encounter at ${setupRules[i].name}`;
        }
        if (!hasEpochConnected) {
          this.errorMsg = `${setupRules[i].name} is not connected`;
        }

        for (let n = 0, l = setupRules[i].encounters.length; n < l; ++n) {
          const isEncounterFilledCorrectly = this.validateEncounter(setupRules[i].encounters[n], setupRules[i]);
          validationArray.push(isEncounterFilledCorrectly);
          !isEncounterFilledCorrectly &&
            (this.errorMsg = `Encounter details are missing on ${setupRules[i].name} -${setupRules[i].encounters[n].displayName}`);
        }

        if (setupRules[i].name) {
          validationArray.push(true);
        } else {
          validationArray.push(false);
          this.errorMsg = `Epoch details are missing on ${setupRules[i].name}`;
        }

        if (hasEncounter && hasEpochConnected) {
          validationArray.push(true);
        } else {
          validationArray.push(false);
          this.errorMsg = `Epoch details are missing on ${setupRules[i].name}`;
        }
        if (type === 'publish' && setupRules[i].encounters.length) {
          for (let n = 0, l = setupRules[i].encounters.length; n < l; ++n) {
            const isEncounterDataNotChanged = this.validateEncounterDataChanged(
              setupRules[i].encounters[n],
              setupRules[i],
              prevSetupRules
            );
            validationArray.push(isEncounterDataNotChanged);
            if (!isEncounterDataNotChanged) {
              // empty string here is added to prevent showing default notification
              this.errorMsg = '';
              this.alertPopUpHandeler({ title: 'Please save Source Data Setup before publish' });
            }
            if (setupRules[i].encounters[n].elements.length) {
              validationArray.push(true);
            } else {
              validationArray.push(false);
              this.errorMsg = `Unable to publish setup, Epoch: ${setupRules[i].name} Encounter ${setupRules[i].encounters[n].displayName} has no itemgroup. 
                  Please save Source Data setup before proceeding to Encounter setup and assign ItemGroup to Encounter.`;
            }
          }
          if (this.state.unexpectedEncountersSetup.length <= 0) {
            validationArray.push(false);
            this.errorMsg = 'Unable to publish setup, no item groups attached to unexpected encounter';
          } else {
            validationArray.push(true);
          }
        }
        if (setupRules[i].parent.includes('startWindow')) {
          connectedToStart = true;
        }
        if (setupRules[i].child.includes('endWindow')) {
          connectedToEnd = true;
        }
      }
      if (type === 'publish' && !this.state.updatedComment?.trim()) {
        validationArray.push(false);
        this.errorMsg = '';
        !initial && this.alertPopUpHandeler({ title: 'Please enter a protocol version comment prior to publishing.' });
      }
      if (
        type === 'publish' &&
        (this.state.currentProtocolName !== this.state.protocolName || this.state.updatedComment !== this.state.comment)
      ) {
        validationArray.push(false);
        this.errorMsg = '';
        this.alertPopUpHandeler({ title: 'Please save Source Data Setup before publish' });
      }
      if (!connectedToStart) {
        this.errorMsg = 'No epoch is associated with the start';
      }
      if (!connectedToEnd) {
        this.errorMsg = 'No epoch is associated with the end';
      }
      isValid = !validationArray.includes(false) && connectedToStart && connectedToEnd;
    } else {
      isValid = false;
    }

    return isValid;
  }

  validateEncounter(enc, epoch) {
    const epochEncounerList = this.getPreviousEpochEncounterList(enc, epoch.nodeKey);
    const isPreviousEncountersNonProtocolOrAbsent = isEmpty(epochEncounerList);
    const areRequiredFieldsFilled =
      !!enc.encounterName && !!enc.fromVisit && !!enc.toVisit && !!enc.toType && !!enc.fromType;
    const haveEncounterReference = !isEmpty(enc?.parentEnNodeKeys);
    const isEncounterInFirstEpochWithoutEpochParent =
      epoch.parent && epoch.parent.length === 1 && epoch.parent.includes(startWindow);
    const isEncounterTheFirst = isEncounterInFirstEpochWithoutEpochParent && isPreviousEncountersNonProtocolOrAbsent;
    const isEncounterLinkedWithStart = epoch.parent.includes(startWindow);
    if (enc.parentEnNodeKeys.includes('start') && !epoch.parent.includes(startWindow)) {
      enc.parentEnNodeKeys = enc.parentEnNodeKeys.filter(key => key !== 'start');
    }
    return (
      (areRequiredFieldsFilled &&
        (haveEncounterReference ||
          isEncounterTheFirst ||
          isPreviousEncountersNonProtocolOrAbsent ||
          isEncounterLinkedWithStart)) ||
      enc.nonProtocol
    );
  }

  validateEncounterDataChanged(enc, epoch, prevSetupRules) {
    const prevEpoch = prevSetupRules.find(prevEpoch => epoch.epochIdentifier === prevEpoch.epochIdentifier);
    const epochNameWasChanged = epoch.name !== prevEpoch.name;
    const prevEnc = prevEpoch.encounters.find(prevEnc => prevEnc.encounterIdentifier === enc.encounterIdentifier);
    let encWasChanged;
    if (enc && prevEnc) {
      encWasChanged =
        enc.fromVisit !== prevEnc.fromVisit ||
        enc.toVisit !== prevEnc.toVisit ||
        !isEqual(enc.parentEnNodeKeys, prevEnc.parentEnNodeKeys) ||
        enc.fromType !== prevEnc.fromType ||
        enc.toType !== prevEnc.toType ||
        enc.encounterName !== prevEnc.encounterName;
    } else {
      encWasChanged = true;
    }

    return !epochNameWasChanged && !encWasChanged;
  }

  handleErrorMessage = () => {
    if (isString(this.errorMsg)) {
      // if errorMsg is an empty string - nothing will be shown
      // if errorMsg is not empty - it will be shown
      !!this.errorMsg && NotificationManager.error(this.errorMsg);
    } else {
      // if errorMsg is null - default message will be shown
      NotificationManager.error('Invalid Source Data Setup');
    }
  };
  // save protocol setup
  saveProtocolHandler = () => {
    try {
      if (this.state.setupRules.length > 0 && this.validateProtocolSetup('save')) {
        const data = this.getDataForSave();
        ProtocolApi.saveEpochProtocol(this.props.params.studyId, this.props.params.protocolIdentity, data)
          .then(res => {
            if (res.status.toString() === '200') {
              this.setState(prevState => ({
                prevSetupRules: JSON.stringify(this.state.setupRules),
                currentProtocolName: prevState.protocolName,
                comment: prevState.updatedComment?.trim(),
                updatedComment: prevState.updatedComment?.trim()
              }));

              NotificationManager.success(SOURCE_DATA_SETUP_SAVED);
            }
          })
          .catch(error => {
            NotificationManager.error(error.response.data.message);
          });
      } else {
        this.handleErrorMessage();
      }
    } catch (e) {}
  };

  // publish the setup
  publishProtocolHandler = () => {
    try {
      if (this.state.setupRules.length > 0 && this.validateProtocolSetup('publish')) {
        const modalBox = ModalBoxes.open({
          title: 'Publish Date',
          component: (
            <PublishModal
              onSave={res => {
                if (res.publishDate) {
                  const { studyName } = this.state;
                  const data = { publishDate: common.formatDate(res.publishDate, 'YYYY-MM-DD') };
                  ProtocolApi.publishProtocol(this.props.params.studyId, this.props.params.protocolIdentity, data)
                    .then(() => {
                      NotificationManager.success(
                        `Source Data publish  on - ${moment(res.publishDate).format('DD/MMM/YYYY')}`
                      );
                      modalBox.close();
                      this.navigateToSetupProtocol();
                      NotificationManager.info(`Encounter status update for ${studyName} study scheduled`);
                      NotificationManager.info(`Adverse Event Log statuses update for ${studyName} study scheduled`);
                    })
                    .catch(error => {
                      const errorMessage = error.response.data.message;
                      if (
                        +error?.response?.status === 400 &&
                        errorMessage.includes('GroupAssignAdverseEventLogSignPermissionException')
                      ) {
                        modalBox.close();
                        const pagePath = 'Setup Protocol.Source Data Setup.Groups Setup';
                        ModalBoxes.open({
                          component: (
                            <AdverseEventLogWrongGroupAssignConfigModal
                              onGroupSetupRedirect={() => this.navigateTo(pagePath)}
                            />
                          ),
                          className: 'eds-adverse-event-log-group-assign-modal-box'
                        });
                      } else {
                        NotificationManager.error(error.response.data.message);
                      }
                    });
                }
              }}
            />
          )
        });
      } else {
        this.handleErrorMessage();
      }
    } catch (e) {}
  };

  getDataForSave() {
    const sortedEpochData = sortEpochsAndEncountersByOriginSequence(this.state.setupRules, this.state.originSetupRules);
    return {
      protocolId: this.props.params.versionId,
      studyIdentifier: this.props.params.studyId,
      uniqueIdentifier: this.props.params.protocolIdentity,
      sourceIdentifier: null,
      language: 'EN',
      version: this.state.currentVersion,
      status: 'Draft',
      lastModifiedOn: new Date(),
      lastModifiedBy: 'Admin',
      epoch: sortedEpochData,
      unexpectedEncountersSetup: this.state.unexpectedEncountersSetup,
      situationalEncountersSetup: this.state.situationalEncountersSetup,
      terminologyVersionSet: this.state.terminologyVersionSet,
      primaryConnections: this.primaryConnections,
      name: this.state.protocolName ? this.state.protocolName : 'Source Data',
      versionId: this.state.versionId ? this.state.versionId : 0,
      comment: this.state.updatedComment?.trim()
    };
  }

  // validation for setup changes
  hasSetupChanged() {
    let hasValid = true;
    const encounterArr = [];
    try {
      if (this.state.setupRules.length) {
        const oldRules = JSON.parse(this.state.prevSetupRules);
        const currentSetup = this.state.setupRules;

        if (oldRules.length === currentSetup.length) {
          currentSetup.forEach((info, idx) => {
            if (info.encounters.length !== oldRules[idx].encounters.length) {
              encounterArr.push(false);
            }
          });

          if (encounterArr.indexOf(false) > -1) {
            hasValid = false;
          }
        } else {
          hasValid = false;
        }
      }
      return hasValid;
    } catch (e) {}
  }

  navigateTo(pagePath) {
    const path = generateUrlByKey(pagePath, {
      studyId: this.props.params.studyId,
      protocolIdentity: this.props.params.protocolIdentity
    });
    return this.props.navigate(path);
  }

  encounterSetupHandler = () => {
    try {
      if (this.hasSetupChanged() && this.state.setupRules.length > 0) {
        const pagePath = 'Setup Protocol.Source Data Setup.Encounter Setup';
        return this.navigateTo(pagePath);
      } else {
        this.alertPopUpHandeler({ title: 'Please save Source Data setup before proceeding to Encounter setup' });
      }
    } catch (e) {}
  };

  groupsSetupHandler = () => {
    try {
      if (this.hasSetupChanged() && this.state.setupRules.length > 0) {
        const pagePath = 'Setup Protocol.Source Data Setup.Groups Setup';
        return this.navigateTo(pagePath);
      } else {
        this.alertPopUpHandeler({ title: 'Please save Source Data setup before proceeding to Groups setup' });
      }
    } catch (e) {}
  };

  elementSetupHandler = () => {
    if (this.hasSetupChanged() && this.state.setupRules.length > 0) {
      const pagePath = 'Setup Protocol.Source Data Setup.Item Groups List';
      return this.navigateTo(pagePath);
    } else {
      this.alertPopUpHandeler({ title: 'Please save Source Data Setup before proceeding to ItemGroup setup' });
    }
  };

  publishSetupHandler = () => {
    if (this.hasSetupChanged() && this.state.setupRules.length > 0) {
      this.publishProtocolHandler();
    } else {
      this.alertPopUpHandeler({ title: 'Please save Source Data Setup before publish' });
    }
  };

  changeProtocolNameHandler = name => {
    this.setState({ protocolName: name });
  };

  alertPopUpHandeler(info) {
    ModalBoxes.confirm({ title: 'Alert', content: info.title, confirmButton: 'Ok', cancelButton: false }).then(
      () => {},
      () => {}
    );
  }

  onCanvasScroll = () => {
    const conavasScrollPos = document.getElementById('canvas').scrollLeft;
    const endWinPos = document.getElementById(endWindow);
    endWinPos.style.right = `${-conavasScrollPos}px`;
    return this.jsPlumbInstance ? this.jsPlumbInstance.repaintEverything() : '';
  };

  onDragOver = ev => {
    ev.preventDefault();
  };

  onDrop = ev => {
    try {
      if (ev.dataTransfer.getData('text') === 'dragEpoc') {
        this.addNewEpoch({ left: ev.nativeEvent.offsetX, top: ev.nativeEvent.offsetY });
      }
      ev.preventDefault();
    } catch (e) {
      console.log(e);
    }
  };

  deleteEpoch = epochId => {
    const { setupRules: epochsList } = this.state,
      epoch = epochsList.find(({ nodeKey }) => isEqual(nodeKey, epochId));
    if (epoch) {
      const parents = epoch.parent;
      this.removeAllRelations(epochsList, epochId);
      const newEpochList = epochsList.filter(({ nodeKey }) => !isEqual(nodeKey, epochId));
      this.setState({ setupRules: newEpochList }, () => {
        const inst = this.jsPlumbInstance;
        inst.deleteConnectionsForElement(epochId);
        inst.removeAllEndpoints(epochId);
        parents.forEach(parentSourceId => this.reconstructPrimaryConnections(parentSourceId));
        this.removeT0EncounterRefrence(epochId, null, 'epoch');
      });
    } else {
      console.log('some issue ');
    }
  };

  removeAllRelations(epochsList, epochId) {
    epochsList
      .map(({ nodeKey, parent }) => nodeKey && { [nodeKey]: parent })
      .filter(epochIdToParents =>
        Object.values(epochIdToParents)
          .flat()
          .includes(epochId)
      )
      .forEach(epochIdToParents => {
        const epochIdKey = Object.keys(epochIdToParents).find(key => key);
        this.removeRelations(epochId, epochIdKey);
        this.deleteRelationsBetweenAllConnectedEncounter(epochsList, epochId, epochIdKey);
      });
  }

  removeEpochEncounters(epochsList, epochId) {
    epochsList
      .filter(ep => isEqual(ep.nodeKey, epochId))
      .map(({ encounters }) => encounters.map(({ enNodeKey }) => enNodeKey).flat())
      .flat()
      .forEach(en => this.epochEncounterDelete(epochId, en));
  }

  addEpochDetails = e => {
    const filterEpoch = this.state.setupRules.filter(n => n.nodeKey === e);
    this.epochDetailsPopUpCickHandler(filterEpoch, e);
  };

  openChangeProtocolEncounterOrderingModal = epochId => {
    ModalBoxes.open({
      component: (
        <ChangeProtocolEncounterOrderingModal
          epochId={epochId}
          epochs={this.state.setupRules}
          onConfirm={setupRules => {
            this.setState({ setupRules });
          }}
        />
      )
    });
  };

  render() {
    const {
      cancelProtocolSetup,
      saveProtocolHandler,
      publishSetupHandler,
      changeProtocolNameHandler,
      elementSetupHandler,
      encounterSetupHandler,
      groupsSetupHandler,
      createEpochElemensOnCanvas,
      onDragOver,
      onDrop,
      onCanvasScroll
    } = this;
    const { protocolName, currentVersion, studyName, studyIsGroupAssign } = this.state;
    return (
      <>
        <PageInfoHeader
          objectRecordLabel={studyName}
          pageInfo={
            <PageInfoHeader.CollapsibleList>
              <PageInfoHeader.AdditionalInfo tooltip="Source data name">{protocolName}</PageInfoHeader.AdditionalInfo>
              <PageInfoHeader.AdditionalInfo tooltip="Version">{currentVersion}</PageInfoHeader.AdditionalInfo>
            </PageInfoHeader.CollapsibleList>
          }
          right={
            <ButtonGroup>
              <Button size="h40" priority="low" onClick={cancelProtocolSetup}>
                Cancel
              </Button>
              <Button size="h40" priority="medium" onClick={saveProtocolHandler}>
                Save
              </Button>
              <Button size="h40" priority="high" onClick={publishSetupHandler}>
                Publish
              </Button>
            </ButtonGroup>
          }
        />
        <CommentSection
          comment={this.state.updatedComment}
          changeComment={({ target: { value } }) => this.setState({ updatedComment: value })}
        />
        <Section className="protocol-setup-page">
          <div className="protocol-setup-header">
            <Input
              label="Name"
              value={protocolName || ''}
              onChange={e => changeProtocolNameHandler(e.target.value)}
              validate={false}
            />
            <ButtonGroup>
              <Button size="h40" priority="high" onClick={elementSetupHandler}>
                Item Groups
              </Button>
              <Button size="h40" priority="high" onClick={encounterSetupHandler}>
                Encounters
              </Button>
              {this.props.appInfo.features.groupsSetupEnabled && studyIsGroupAssign && (
                <Button size="h40" priority="high" onClick={groupsSetupHandler}>
                  Groups
                </Button>
              )}
            </ButtonGroup>
            <div
              title="Drag to add new Epoch"
              style={{ background: 'url(assets/images/icons/epoch-icon.png) no-repeat 7px 4px' }}
              onDragStart={onDragStart}
              draggable
              className="draggable draggable-epoch epochicon"
            >
              Epoch
            </div>
          </div>
          <div
            className="jtk-demo-canvas canvas-wide protocol-setup-container jtk-surface jtk-surface-nopan"
            id="canvas"
            onDragOver={onDragOver}
            onDrop={onDrop}
            onScroll={onCanvasScroll}
          >
            <div className="start_window" id="startWindow">
              Start
            </div>
            <div className="end_window" id="endWindow">
              End
            </div>
            {createEpochElemensOnCanvas()}
            <div className="col-md-12 row m-0 justify-content-start my-3" />
          </div>
        </Section>
      </>
    );
  }
}

function onDragStart(ev) {
  ev.dataTransfer.setData('text', 'dragEpoc');
}

export default withAppInfo(withParams(withNavigate(ProtocolSetup)));
