import { createContext, useContext, useReducer } from 'react';

import { useCurrentUser } from 'components/root/Container/CurrentUserContainer';

import reducer from './reducer';

const StateContext = createContext(null);
const DispatchContext = createContext(null);

export function EuiFiltersProvider({ children, cachePrefix }) {
  const { roleBasedId } = useCurrentUser();
  const [state, dispatch] = useReducer(reducer, {
    cacheKey: `${cachePrefix}-${roleBasedId}`
  });

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}

export function withEuiFiltersProvider(Component, { cachePrefix }) {
  return function WithEuiFiltersProvider(props) {
    return (
      <EuiFiltersProvider cachePrefix={cachePrefix}>
        <Component {...props} />
      </EuiFiltersProvider>
    );
  };
}

export function useEuiFiltersState() {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error('useEuiFiltersState must be used within a EuiFiltersContext');
  }
  return context;
}

export function useEuiFiltersDispatch() {
  const dispatch = useContext(DispatchContext);

  if (dispatch === undefined) {
    throw new Error('useEuiFiltersDispatch must be used within a EuiFiltersContext');
  }

  return dispatch;
}
