import { useState } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import PatientTravelWorklistApi from 'api/patient/PatientTravelWorklistApi';
import ModalBoxes from 'common/feedback/ModalBoxes/ModalBoxes';
import Button from 'common/general/Button';
import ButtonGroup from 'common/general/ButtonGroup';
import { onRequestError } from 'services/handlers';

export default function EditNoteModal({ row, modalBox }) {
  const [saving, setSaving] = useState(false);
  const [note, setNote] = useState(row.note || '');
  const [isNoteRead, setIsNoteRead] = useState(row.isNoteRead || false);
  return (
    <>
      <ModalBoxes.Header>Note</ModalBoxes.Header>
      <ModalBoxes.Body>
        <Box sx={{ flexGrow: 1 }} component="form">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="note"
                value={note}
                onChange={function({ target }) {
                  setNote(target.value);
                }}
                fullWidth
                label="Note"
                multiline
                maxRows={4}
                variant="standard"
                inputProps={{ maxLength: 200 }}
                helperText={`${note.length}/200`}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isNoteRead}
                    onChange={function({ target }) {
                      setIsNoteRead(target.checked);
                    }}
                  />
                }
                label="Mark Note as Read"
              />
            </Grid>
          </Grid>
        </Box>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            Cancel
          </Button>
          <Button loading={saving} onClick={onSave}>
            Save
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );

  function onSave() {
    setSaving(true);
    PatientTravelWorklistApi.updateWorklistRow(row.id, {
      note,
      markAsRead: isNoteRead || false
    })
      .then(function({ data }) {
        modalBox.close(data);
      })
      .catch(function(error) {
        setSaving(false);
        onRequestError(error);
      });
  }
}

EditNoteModal.size = 'w500';
