import { reduce } from 'lodash/collection';
import { isEmpty, isEqual } from 'lodash/lang';
import { has } from 'lodash/object';

import { FRS_LOADED, FRS_READY } from '../reducer';

export function setInitialStateProcessor(state, action) {
  const { type, payload } = action;
  const cacheString = sessionStorage.getItem(state.cacheKey) || '{}';
  const cache = JSON.parse(cacheString);

  if (isEmpty(payload)) {
    return state;
  }

  return { ...state, ...payload, cache };
}

export function initializeProcessor(state, action) {
  const { type, payload } = action;
  const { key, source, value } = payload;

  const nextState = { ...state };

  if (has(payload, 'source')) {
    nextState[key] = {
      ...nextState[key],
      source
    };
  }

  if (has(payload, 'value')) {
    nextState[key] = {
      ...nextState[key],
      value
    };
  }

  nextState.schema = {
    ...nextState.schema,
    [key]: {
      ...nextState.schema[key],
      status: FRS_LOADED
    }
  };

  return nextState;
}

export function syncCacheProcessor(state, action) {
  const { type, payload: cache } = action;

  return reduce(
    cache,
    function(nextState, value, key) {
      if (has(nextState, key)) {
        if (!isEmpty(value)) {
          nextState[key] = {
            ...nextState[key],
            ...value
          };
        }
        nextState.schema = {
          ...nextState.schema,
          [key]: {
            ...nextState.schema[key],
            status: FRS_READY
          }
        };
      }
      return nextState;
    },
    { ...state }
  );
}

export function setValueProcessor(state, action) {
  const { type, payload } = action;
  const { key, value } = payload;
  const previousFilterState = state[key];
  if (isEqual(value, previousFilterState.value)) {
    return state;
  }
  return {
    ...state,
    [key]: {
      ...previousFilterState,
      value
    }
  };
}
