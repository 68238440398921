import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { isEmpty } from 'lodash/lang';

import Checkbox from '../../../../../../common/data-entry/InputSelectors/Checkbox';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../common/general/ButtonGroup';
import { getEncounterAbbreviation } from '../protocolSetupService';

import './SituationalEncounterModal.scss';

export default function SituationalEncounterModal({
  modalBox,
  situationalEncounters,
  updateSituationalEncounter,
  initialName
}) {
  const [name, setName] = useState(initialName || null);
  const resolveSmsOptIn = () => situationalEncounters.find(e => e.name === initialName)?.smsOptIn ?? true;
  const [smsOptIn, setSmsOptIn] = useState(resolveSmsOptIn());
  function addSituationalEncounter() {
    const shortName = getEncounterAbbreviation(name, true);
    const situationalEncounter = {
      name: name,
      shortName: shortName,
      sequence: situationalEncounters.length + 1,
      elements: [],
      smsOptIn: smsOptIn
    };
    updateSituationalEncounter(situationalEncounter);
    modalBox.close();
  }

  const hasError = React.useMemo(
    () =>
      name?.length > 200 ||
      situationalEncounters.some(enc => enc.name?.trim() === name?.trim() && name?.trim() !== initialName?.trim()),
    [initialName, name, situationalEncounters]
  );

  function getErrorMessage() {
    if (name?.length > 200) {
      return 'Encounter name should not be longer than 200 characters';
    }
    return `Situational encounter with name "${name?.trim()}" already exists`;
  }

  return (
    <>
      <ModalBoxes.Header>Encounter Details</ModalBoxes.Header>
      <ModalBoxes.Body>
        <TextField
          error={hasError}
          helperText={hasError ? getErrorMessage() : ''}
          fullWidth
          required
          variant={'standard'}
          size={'small'}
          label={'Encounter Name'}
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <div className="situational-form-row">
          <Checkbox
            label="SMS reminders"
            checked={smsOptIn}
            onChange={({ target }) => {
              setSmsOptIn(target.checked);
            }}
            disabled={false}
            data-testid="situational-encounter-sms-opt-in-checkbox"
          />
        </div>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="low" onClick={modalBox.close}>
            Cancel
          </Button>
          <Button disabled={hasError || isEmpty(name?.trim())} onClick={addSituationalEncounter}>
            Save
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
}

SituationalEncounterModal.className = 'add-situational-encounter-modal';
SituationalEncounterModal.size = 'w800';
