import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';

import Button from '../../../../common/general/Button';
import ButtonGroup from '../../../../common/general/ButtonGroup';
import { toFormattedBill } from '../../../../services/financial';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';
import BreadcrumbNavigation from '../../../root/Container/Layout/BreadcrumbNavigation/BreadcrumbNavigation';
import { calculateAmounts } from '../Reconcile/Reconcile';
import { Amount } from '../shared/amount-view/Amount/Amount';

import { ApprovalTable } from './ApprovalTable/ApprovalTable';
import { ReadyForApprovalContext, withReadyForApprovalContext } from './ReadyForApprovalContext';

const ReadyForApproval = () => {
  const navigate = useNavigate();
  const { onReadyForApproval, events } = useContext(ReadyForApprovalContext);
  return (
    <div className="eds-modal-boxes">
      <div className="eds-modal-box">
        <div className="eds-modal-box-overlay" />
        <Box className="eds-modal-box-container" position="absolute">
          <>
            <BreadcrumbNavigation />
            <PageInfoHeader
              name="Site Payments: "
              pageInfo={
                <React.Fragment>
                  <PageInfoHeader.AdditionalInfo title="Payment Eligible">
                    <Amount coinsAmount={calculateAmounts(events, 'siteAmount')} customCoinsFormat={toFormattedBill} />
                  </PageInfoHeader.AdditionalInfo>
                  <PageInfoHeader.AdditionalInfo title="Selected for Approval">
                    <Amount
                      coinsAmount={calculateAmounts(events, 'approvedAmount', false)}
                      customCoinsFormat={toFormattedBill}
                    />
                  </PageInfoHeader.AdditionalInfo>
                </React.Fragment>
              }
            />
            <ApprovalTable />
            <Box display="flex" flexDirection="row-reverse" padding="0 25px 25px 25px">
              <ButtonGroup>
                <Button size="h28" priority="medium" onClick={() => navigate('/site-payments')}>
                  Cancel
                </Button>
                <Button size="h28" onClick={onReadyForApproval}>
                  Ready for Approval
                </Button>
              </ButtonGroup>
            </Box>
            <IconButton
              sx={{ position: 'absolute', top: '17px', right: '17px', zIndex: 1001 }}
              onClick={() => navigate('/site-payments')}
            >
              <CloseIcon />
            </IconButton>
          </>
        </Box>
      </div>
    </div>
  );
};

export default withReadyForApprovalContext(ReadyForApproval);
