import React from 'react';

import './ExpenseInfoItem.scss';

export const ExpenseInfoItem = ({ label, text }) => {
  return (
    <div className="expense-fixed-info-item" data-testid="expense-info-item">
      <span className="item-label">{label}</span>
      <div className="item-text">{text}</div>
    </div>
  );
};
