import axios from 'axios';

const ROOT = 'api/v1/finance';

const FinBudgetExpenseFixedApi = {
  findTriggersForActualBudget(studyId, siteId, ssuId, date) {
    return axios.get(`${ROOT}/expenses/fixed/triggers?studyId=${studyId}&siteId=${siteId}&ssuId=${ssuId}&date=${date}`);
  },
  getExpenseVariableTriggers(studyId, siteId, ssuId, date) {
    return axios.get(
      `${ROOT}/expenses/variables/triggers?studyId=${studyId}&siteId=${siteId}&ssuId=${ssuId}&date=${date}`
    );
  },

  getFilteredExpenseFixed(ssuId) {
    return axios.get(`${ROOT}/study-sites/${ssuId}/expenses/fixed`);
  },

  getFilteredExpensesVariable(ssuId) {
    return axios.get(`${ROOT}/study-sites/${ssuId}/expenses/variables`);
  },

  findAllExpenseFixed(filterProperty) {
    return axios.post(`${ROOT}/expenses/fixed/download`, filterProperty, {
      responseType: 'arraybuffer'
    });
  },

  create(ssuId, finBudgetExpenseFixed) {
    return axios.post(`${ROOT}/expenses/fixed?ssuId=${ssuId}`, finBudgetExpenseFixed);
  },

  createExpenseVariable(ssuId, expenseVariable) {
    return axios.post(`${ROOT}/expenses/variables?ssuId=${ssuId}`, expenseVariable);
  },

  getExpenseFixedClientAmount(ssuId, finTriggerId, finBudgetId) {
    return axios.get(
      `${ROOT}/expenses/fixed/triggers/amount?ssuId=${ssuId}&finTriggerId=${finTriggerId}&finBudgetId=${finBudgetId}`
    );
  },

  getExpenseVariableMaximumAmount(ssuId, studyId, siteId, finTriggerId, finBudgetId) {
    return axios.get(
      `${ROOT}/expenses/variables/triggers/amounts?ssuId=${ssuId}&studyId=${studyId}&siteId=${siteId}&finTriggerId=${finTriggerId}&finBudgetId=${finBudgetId}`
    );
  },

  updateCommentForExpenseFixed(ssuId, commentResponse) {
    return axios.post(`${ROOT}/expenses/fixed/comment?ssuId=${ssuId}`, commentResponse);
  },

  getExpenseVariableDetails(expenseVariableId, ssuId) {
    return axios.get(`${ROOT}/study-sites/${ssuId}/expenses/variables/${expenseVariableId}`);
  },

  updateExpenseVariable(ssuId, expenseVariableId, expenseVariable) {
    return axios.put(`${ROOT}/expenses/variables/${expenseVariableId}?ssuId=${ssuId}`, expenseVariable);
  },

  getAttachedFile(fileId, ssuId, hideLoader = true) {
    return axios({
      url: `${ROOT}/expenses/variables/files/${fileId}?ssuId=${ssuId}`,
      method: 'GET',
      responseType: 'blob',
      hideLoader
    });
  }
};

export default FinBudgetExpenseFixedApi;
