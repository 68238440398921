import { useEffect } from 'react';
import Select from 'common/data-entry/Select';

import {
  initializeProcessor,
  setValueProcessor,
  syncCacheProcessor
} from 'components/eui/EuiFiltersContext/processors';
import { FRS_LOADED, FRS_READY, FRS_UNINITIALIZED } from 'components/eui/EuiFiltersContext/reducer';

import './StudyPcn.scss';

export default function StudyPcn({ uniqKey, state, dispatch, getStudies = defaultGetOptions }) {
  const fState = state[uniqKey];
  const fCache = state.cache[uniqKey];
  const fStatus = state.schema[uniqKey].status;

  useEffect(
    function() {
      if (fStatus === FRS_UNINITIALIZED) {
        getStudies().then(function(payload) {
          dispatch({
            type: 'PERFORM',
            processor: initializeProcessor,
            payload: {
              key: uniqKey,
              ...payload
            }
          });
        });
      }
    },
    [uniqKey, dispatch, fStatus, getStudies]
  );

  useEffect(
    function() {
      if (fStatus !== FRS_LOADED) {
        return;
      }

      dispatch({
        type: 'PERFORM',
        processor: syncCacheProcessor,
        payload: { [uniqKey]: fCache }
      });
    },
    [dispatch, uniqKey, fStatus, fCache]
  );

  if (fStatus !== FRS_READY) return null;

  const { source, value } = fState;

  return (
    <>
      <Select
        className="eui-filter-control-study-pcn"
        dataSource={source}
        value={value}
        onChange={selectedOption => {
          dispatch({
            type: 'PERFORM',
            processor: setValueProcessor,
            payload: {
              key: uniqKey,
              value: selectedOption
            }
          });
        }}
        label="Study"
        searchable={true}
        closeOnSelectedOptionClick={false}
        deselectOnSelectedOptionClick={false}
        clearable={false}
        validate={false}
        customFilterFunction={function(e, index, collection, searchString) {
          return `${e.pcn} ${e.name}`.toLowerCase().includes(searchString);
        }}
        customOptionTemplateFunction={function(e) {
          return (
            <>
              <div className="eui-filter-control-study-pcn-label">{e?.pcn}</div>
              <div>{e?.name}</div>
            </>
          );
        }}
        customSelectedValueTemplateFunction={function([selectedValue]) {
          if (!selectedValue) {
            return null;
          }
          return (
            <>
              <span>{selectedValue?.name}</span>
              <span className="eui-filter-control-study-pcn-label"> ({selectedValue.pcn})</span>
            </>
          );
        }}
      />
    </>
  );
}

function defaultGetOptions() {
  return Promise.resolve();
}
