import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withBouncer } from 'bouncer';
import { parse } from 'query-string';

import {
  addAccessTokenInterceptor,
  addCognitoAccessTokenInterceptor,
  addForbiddenResponseInterceptor
} from '../../../api/interceptors';

import Container, { withCurrentUserContainerConnected, withReserveEnvironmentStyle } from './Container';

const mapAuth0StateToProps = state => ({ appInfo: state?.appInfo || { features: {} } });
const mergeProps = (state, dispatch, ownProps) => ({
  component: Container,
  withCurrentUserContainerConnected,
  withAmplify: withReserveEnvironmentStyle,
  ...state,
  ...ownProps
});

const WithBouncerOrAmplify = ({ component, withCurrentUserContainerConnected, withAmplify, appInfo, ...rest }) => {
  const [isSpecialLink, setIsSpecialLink] = useState(false);

  const {
    auth0ClientId,
    auth0IssuerUri,
    auth0Audience,
    auth0Connection,
    auth0LogoutUrl,
    features: { auth0Enabled = false }
  } = appInfo;

  useEffect(() => {
    if (window.location.pathname === '/reset-password') {
      const urlParameters = window?.location?.search || '';
      const { code, username } = parse(urlParameters);
      if (!!code && !!username) {
        setIsSpecialLink(true);
      } else {
        setIsSpecialLink(false);
      }
    } else if (window.location.pathname === '/new-user') {
      const urlParameters = window?.location?.search || '';
      const { pw, username } = parse(urlParameters);
      if (!!pw && !!username) {
        setIsSpecialLink(true);
      } else {
        setIsSpecialLink(false);
      }
    }
    return () => {
      setIsSpecialLink(false);
    };
  }, [setIsSpecialLink]);

  const postLoginCallback = (getAccessToken, logout) => {
    addAccessTokenInterceptor(getAccessToken);
  };

  // We recieve auth0LogoutUrl from the appInfo api request and it looks like:
  //
  // https://intelligo.yadi.yada.amazoncognito.com/logout?redirect_uri=
  //
  // we need to assign the last part of the url query `redirect_uri` with the
  // origin of this app's url
  const fullLogoutUrl = `${auth0LogoutUrl}${window.location.origin}`;

  let additionalAuth0Props = null;
  if (auth0IssuerUri && typeof auth0IssuerUri === 'string') {
    const url = new URL(auth0IssuerUri);
    const domain = url?.hostname;
    if (domain) {
      additionalAuth0Props = { domain };
    }
  }

  const LoginComponent =
    (auth0Enabled &&
      !isSpecialLink &&
      withBouncer(
        withCurrentUserContainerConnected(component),
        auth0ClientId,
        auth0Audience,
        auth0Connection,
        fullLogoutUrl,
        true,
        postLoginCallback,
        null,
        additionalAuth0Props
      )) ||
    withAmplify(component);

  if (!auth0Enabled) {
    addCognitoAccessTokenInterceptor();
  }

  addForbiddenResponseInterceptor(auth0Enabled);
  return <LoginComponent {...rest} />;
};

export default connect(mapAuth0StateToProps, null, mergeProps)(WithBouncerOrAmplify);
