import React from 'react';
import cx from 'classnames';

import IconButton from '../../../../../../../../common/buttons/IconButton';
import common from '../../../../../../../../common/common';
import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import CustomFieldConfiguration from '../../../CustomFieldConfiguration/CustomFieldConfiguration';
import EditFormFields from '../../../EditFormFields';
import { customClassObject } from '../../customClassObject';
import FieldPreview from '../CustomFieldsGenerator/FieldPreview';

import { EditFormAnswerView } from './EditFormAnswerView';
import { OptionalCheckbox } from './OptionalCheckbox';

import './OptionalField.scss';

export function OptionalField({
  item,
  index,
  formLabelData,
  labelIndex,
  getCheckboxForTableView,
  getInvestigatorFieldCheckboxForTableView,
  getExpenseFixedCheckboxForTableView,
  onChangeLabelTextHandler,
  inputs,
  previewDataList,
  updateInputsAndPreviewData,
  editMode,
  terminologyVersionGroupNumber,
  isInvestigatorFieldAvailable,
  isAdverseEventLog,
  isLogViewCheckboxAvailable,
  domain,
  updateState
}) {
  item.isChecked = item.isChecked ? item.isChecked : false;
  const uniqueId = common.getRandomNumber();
  const OptionalTableChckUniqId = `table${common.getRandomNumber()}`;
  const fixedFieldChckUuid = `table${common.getRandomNumber()}`;
  const { isChecked, updatedQuestion, sequence, label, isExtensible, inputType, decode, question } = item;

  return (
    <div key={index} className="item-group-field-row">
      <div
        className={cx('d-flex align-items-center', {
          'col-md-3': domain !== 'ICF',
          'col-md-7': domain === 'ICF'
        })}
      >
        <div className="d-flex">
          {!item?.mandatoryField && (
            <>
              <IconButton
                className="glyphicon glyphicon-edit mr-2"
                suit="glyphicon"
                color="blue"
                title="Edit CDASH Item"
                onClick={() => onEditFormFields(item, labelIndex, index, 'optional')}
              >
                edit
              </IconButton>
              <OptionalCheckbox
                isChecked={isChecked}
                uniqueId={uniqueId}
                item={item}
                labelIndex={labelIndex}
                disabled={item?.mandatoryField}
                index={index}
                inputs={inputs}
                decode={decode}
                updateInputsAndPreviewData={updateInputsAndPreviewData}
              />
            </>
          )}
        </div>
        {inputType !== 'reminder' && (
          <label className={cx('display_inline ml-1 mb-0 ', { 'overflow-ellipsis': domain !== 'ICF' })}>
            {domain !== 'ICF' ? question : updatedQuestion || question}
          </label>
        )}
      </div>
      {domain !== 'ICF' && (
        <div className="col-md-2 pl-0">
          <input
            className="form-control select-width"
            value={(item.hasOwnProperty('updatedQuestion') && updatedQuestion) || ''}
            type="text"
            disabled
          />
        </div>
      )}
      {inputType !== 'checkbox' ? (
        <FieldPreview
          labelText={label}
          data={item}
          formId={inputs[0].formId}
          formLabelId={formLabelData.formLableId}
          customClassObject={customClassObject}
          itemDefIndex={index}
          onChangeLabelTextHandler={e => onChangeLabelTextHandler(e.target.value, labelIndex, index, false)}
        />
      ) : (
        <div className="col-md-3 pl-0" />
      )}

      <div
        className={`col-md-auto pr-4 itemSeq itemMinWidth d-flex ${
          isExtensible === 0 ? 'pl19' : 'pl-0'
        } align-items-center`}
      >
        {/* Edit button with an option to select predefined items for dropdown.
                    Exist only in optional AccordionItem */}
        <EditFormAnswerView
          isExtensible={isExtensible}
          updateInputsAndPreviewData={updateInputsAndPreviewData}
          item={item}
          labelIndex={labelIndex}
          index={index}
          inputs={inputs}
          terminologyVersionGroupNumber={terminologyVersionGroupNumber}
          editMode={editMode}
        />
        {!item?.mandatoryField && (
          <CustomFieldConfiguration
            item={item}
            allInputs={inputs}
            onChange={() => updateState(updateInputsAndPreviewData(inputs))}
          />
        )}
        {/* Item Sequence block similar can be found in getCustomField */}
        {domain !== 'ICF' && (
          <>
            <i className={`material-icons formseqicon`} title="Form View">
              library_books
            </i>
            <input
              type="text"
              readOnly
              value={sequence ? Number(sequence) : ''}
              title="Form Item Sequence"
              className="form-control itemSeqWidth"
            />
          </>
        )}
      </div>
      {isAdverseEventLog() &&
        getExpenseFixedCheckboxForTableView(item, fixedFieldChckUuid, labelIndex, index, 'optional')}
      {isInvestigatorFieldAvailable() &&
        !isICFAutoPopulatedField(domain, item) &&
        getInvestigatorFieldCheckboxForTableView(item, labelIndex, index, 'optional')}
      {isLogViewCheckboxAvailable &&
        domain !== 'ICF' &&
        getCheckboxForTableView(item, OptionalTableChckUniqId, labelIndex, index, 'optional')}
    </div>
  );

  function onEditFormFields(item, labelIndex, questionIndex, type) {
    const modalBox = ModalBoxes.open({
      component: (
        <EditFormFields
          onSave={data => {
            if (data) {
              type === 'optional'
                ? (inputs[0][type][labelIndex].itemDefinitionList[questionIndex].updatedQuestion =
                    data.updatedQuestionFieldName)
                : (inputs[0][type][labelIndex].customItemDefinationList[questionIndex].updatedQuestion =
                    data.updatedQuestionFieldName);
              updateInputsAndPreviewData(inputs);
              ModalBoxes.close(modalBox.id);
            }
          }}
          itemDefinationData={item}
          fullData={previewDataList}
        />
      )
    });
  }

  function isICFAutoPopulatedField(domain, item) {
    return domain === 'ICF' && ['ICF IRB Approval Date', 'ICF Version Number'].includes(item.name);
  }
}
