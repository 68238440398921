import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { union } from 'lodash/array';
import { isEmpty } from 'lodash/lang';
import moment from 'moment/moment';

import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../../common/general/Button';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../../../../constants/dateFormat';
import { toFormattedBill } from '../../../../../../../../services/financial';

import './PaidStipendItemModal.scss';

const columns = [
  { field: 'eventNumber', headerName: 'Event ID', width: 80 },
  { field: 'itemGroupName', headerName: 'Description', width: 200 },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 120,
    valueFormatter({ value }) {
      return toFormattedBill(value);
    }
  },
  {
    field: 'eventItemStatus',
    headerName: 'Payment Status',
    flex: 1,
    minWidth: 185,
    renderCell({ value }) {
      return <div className="MuiDataGrid-cellContent">{value || '—'}</div>;
    }
  }
];

function PaidStipendItemModal({ modalBox, viewOnlyMod, encounter, itemsData, onStipendPay, patientInfo }) {
  const { encounterName, sitePatientEncounterId } = encounter;
  const [encounterDate, setEncounterDate] = useState(moment(encounter?.encounterDate || undefined));
  const apiRef = useGridApiRef();
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const allowedSelectedRows = useMemo(
    function() {
      if (isEmpty(itemsData) || isEmpty(rowSelectionModel)) {
        return null;
      }
      return itemsData.filter(function(row) {
        if (row.eventItemStatus !== 'PROJECTED') {
          return false;
        }
        return rowSelectionModel.includes(getRowId(row));
      });
    },
    [itemsData, rowSelectionModel]
  );

  useEffect(
    function() {
      if (isEmpty(itemsData)) {
        return;
      }
      const selectedRowIdsByDefault = itemsData
        .filter(function({ eventItemStatus }) {
          return eventItemStatus !== 'PROJECTED';
        })
        .map(row => getRowId(row));
      if (isEmpty(selectedRowIdsByDefault)) {
        return;
      }
      setRowSelectionModel(function(state) {
        return union(state, selectedRowIdsByDefault);
      });
    },
    [itemsData]
  );

  const cardIsActive = !!patientInfo?.cardInfo?.registered;
  const submitButtonDisabled = !allowedSelectedRows?.length || !cardIsActive;

  return (
    <>
      <ModalBoxes.Header className="modal-header">{viewOnlyMod ? 'Paid Stipend' : 'Pay Stipend'}</ModalBoxes.Header>
      <ModalBoxes.Body className="modal-body">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="body1" className="encounter-name">
              {encounterName}
            </Typography>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <DesktopDatePicker
              label="Encounter Date"
              value={moment(encounterDate)}
              onChange={setEncounterDate}
              format={DD_SLASH_MMM_SLASH_YYYY}
              maxDate={moment()}
              disabled={viewOnlyMod || !!sitePatientEncounterId}
              views={['year', 'month', 'day']}
              slotProps={{
                textField: {
                  variant: 'standard',
                  className: 'date-picker'
                }
              }}
            />
          </Grid>
          {!cardIsActive && (
            <Grid item xs={12}>
              <Typography variant="body1" color="error">
                Valid PID not found. A valid PID (Proxy ID) must exist on the profile in order to submit patient
                stipend. Please edit the patient profile and add a valid PID before the stipend is paid.
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <DataGridPro
              getRowId={getRowId}
              apiRef={apiRef}
              rows={itemsData || []}
              columns={columns}
              className="items-table"
              checkboxSelection={!viewOnlyMod}
              onRowSelectionModelChange={function(newRowSelectionModel) {
                setRowSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={rowSelectionModel}
              isRowSelectable={params => params.row.eventItemStatus === 'PROJECTED'}
              disableSelectionOnClick={viewOnlyMod}
              disableRowSelectionOnClick={viewOnlyMod}
              autoHeight
            />
          </Grid>
        </Grid>
      </ModalBoxes.Body>
      {!viewOnlyMod && (
        <ModalBoxes.Footer>
          <Button
            disabled={submitButtonDisabled}
            priority="high"
            onClick={() => {
              onStipendPay(encounter, encounterDate, allowedSelectedRows);
              modalBox.close();
            }}
          >
            Pay stipends
          </Button>
        </ModalBoxes.Footer>
      )}
    </>
  );
}

function getRowId(row) {
  return row.eventItemId || row.eventName + row.itemGroupKey;
}

PaidStipendItemModal.className = 'paid-stipend-item-modal';
PaidStipendItemModal.size = 'w800';

export default PaidStipendItemModal;
