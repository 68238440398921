import { map } from 'lodash/collection';

export const TR_PENDING = 'PENDING';
export const TR_BOOKED = 'BOOKED';
export const TR_DENIED = 'DENIED';
export const TR_CANCELED = 'CANCELED';
export const TR_DRAFT = 'DRAFT';

export const travelStatusMap = {
  [TR_CANCELED]: 'Canceled',
  [TR_PENDING]: 'Submitted - Pending',
  [TR_BOOKED]: 'Booked',
  [TR_DENIED]: 'Denied',
  [TR_DRAFT]: 'Not Submitted - Draft'
};

export const travelStatuses = map(travelStatusMap, function(name, id) {
  return { id, name };
});

export const TRST_FLIGHT = 'FLIGHT';
export const TRST_GROUND_TRANSPORTATION = 'GROUND_TRANSPORTATION';
export const TRST_HOTEL = 'HOTEL';

export const travelServiceTypeMap = {
  [TRST_FLIGHT]: 'Flight',
  [TRST_GROUND_TRANSPORTATION]: 'Ground transportation',
  [TRST_HOTEL]: 'Hotel'
};

export const travelServiceTypes = map(travelServiceTypeMap, function(name, id) {
  return { id, name };
});

export const TRT_EARLY_MORNING = 'EARLY_MORNING';
export const TRT_LATE_MORNING = 'LATE_MORNING';
export const TRT_AFTERNOON = 'AFTERNOON';
export const TRT_EVENING = 'EVENING';

export const travelTimeMap = {
  [TRT_EARLY_MORNING]: 'Early morning',
  [TRT_LATE_MORNING]: 'Late morning',
  [TRT_AFTERNOON]: 'Afternoon',
  [TRT_EVENING]: 'Evening'
};

export const travelTimeList = map(travelTimeMap, function(name, id) {
  return { id, name };
});
