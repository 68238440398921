import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, MenuItem, SubMenu } from '@szhsin/react-menu';
import cx from 'classnames';
import { forEach, includes } from 'lodash/collection';
import { isArray } from 'lodash/lang';

import { ALL } from '../../../../../constants/accessToPages';
import { pendoTrack } from '../../../../../pendo/PendoUtils';

import { MenuItems } from './MenuConfig';

export function checkRoleHasAccess(menuCode, user) {
  return includes(user?.accessToPages, menuCode);
}

export function getFilteredMenuItems(user, featureMap) {
  function filterSubItems(MenuItem) {
    if (accessAllowed(user, MenuItem.access) && featureEnabled(MenuItem.feature, featureMap)) {
      if (MenuItem.subItems) {
        MenuItem.subItems = MenuItem.subItems
          .map(SubItem => {
            return filterSubItems(SubItem);
          })
          .filter(e => e);
      }
      return MenuItem;
    }
    return null;
  }
  return MenuItems.map(MenuItem => {
    return filterSubItems(MenuItem, user);
  }).filter(e => e);
}

export function accessAllowed(user, access) {
  if (isArray(access)) {
    return access.some(e => includes(user?.accessToPages, e));
  }
  if (access) {
    return includes(user?.accessToPages, access);
  }
  return access === ALL;
}

function featureEnabled(feature, featureMap) {
  return !feature || !featureMap || featureMap[feature];
}

function getPath(element) {
  if (element.subItems) {
    return element.subItems.flatMap(e => getPath(e));
  } else {
    return element.path;
  }
}

function isActive(item, active) {
  const allPaths = item.subItems.flatMap(el => getPath(el));
  return allPaths.includes(active);
}

const navMenu = 'NAVIGATION_MENU';

function withoutSpaces(str) {
  return str.replace(/\s/g, '');
}
export function generateMenuItems(menuItems, user, active, maxVisibleItemsCount, useIds) {
  const MainMenuItems = menuItems.slice(0, maxVisibleItemsCount);
  const MoreMenuItems = menuItems.slice(maxVisibleItemsCount, 100);
  const CombinedMenuItems = MainMenuItems;
  if (MoreMenuItems.length > 0) {
    CombinedMenuItems.push({
      name: maxVisibleItemsCount > 0 ? 'More' : 'Menu',
      path: '',
      access: ALL,
      subItems: MoreMenuItems
    });
  }

  return CombinedMenuItems.map(MenuItem => {
    const id = withoutSpaces(`${navMenu}_${MenuItem.name}`);
    const restProps = useIds ? { 'data-id': id } : {};
    if (MenuItem.subItems) {
      return (
        <Menu
          key={MenuItem.name}
          menuButton={
            <li
              className={cx('eui-nav-item menu', { active: isActive(MenuItem, active) })}
              key={MenuItem.name}
              {...restProps}
            >
              {MenuItem.name}
            </li>
          }
        >
          {MenuItem.subItems.map(SubItem => {
            return getDropdownMenuItem(SubItem, user, active, useIds, MenuItem.name);
          })}
        </Menu>
      );
    } else {
      return (
        <li className={cx('eui-nav-item', { active: MenuItem.path === active })} key={MenuItem.name}>
          <NavLink
            {...restProps}
            className="eui-nav-link empty"
            onClick={() => {
              pendoTrack('Changed location by menu click', {
                pathName: MenuItem.path,
                buttonName: MenuItem.name
              });
              setDefaultFilterItemsToStorage();
            }}
            to={MenuItem.path}
            key={MenuItem.path}
          >
            {MenuItem.name}
          </NavLink>
        </li>
      );
    }
  });
}

export function getDropdownMenuItem(item, user, active, useIds, parentName) {
  const ref = React.createRef();
  let nestedId;
  if (['More', 'Menu'].includes(parentName)) {
    nestedId = withoutSpaces(`${navMenu}_${item.name}`);
  } else {
    nestedId = withoutSpaces(`${navMenu}_${parentName}_${item.name}`);
  }
  const restProps = useIds ? { 'data-id': nestedId } : {};
  if (!item.subItems) {
    const props = {};
    if (item.onClick) {
      props.onClick = () => {
        pendoTrack('Changed location by menu click', { url: item.path, menuItemName: item.name });
        item.onClick();
      };
    }
    return (
      <MenuItem key={item.path} {...restProps}>
        <NavLink className="eui-nav-link" to={item.path} {...props}>
          {item.name}
        </NavLink>
      </MenuItem>
    );
  } else {
    return (
      <SubMenu
        itemRef={ref}
        label={<span {...restProps}>{item.name}</span>}
        itemClassName={cx('', { active: isActive(item, active) })}
        key={item.name}
      >
        {item.subItems.map(a => getDropdownMenuItem(a, user, active, true, item.name))}
      </SubMenu>
    );
  }
}

function setItemsToStorage(items) {
  forEach(items, (v, k) => {
    localStorage.setItem(k, JSON.stringify(v));
  });
}

/*TODO: need to investigate and find better solution*/
export function setDefaultFilterItemsToStorage() {
  setItemsToStorage({
    fromMenu: true,
    Patient_StudyName: { id: 'ALL', name: 'All Studies' },
    Patient_SiteName: { id: 'ALL', name: 'All Sites' },
    Patient_Status: { id: 'ALL', name: 'All Status' }
  });
}
