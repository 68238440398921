import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { isEmpty } from 'lodash/lang';
import { get } from 'lodash/object';

import { TaskApi, UserApiApi } from '../../../../../api';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import Icon from '../../../../../common/general/Icon';
import { useActiveTask, useActiveTaskUpdate } from '../../../../../store/activeTask';
import { useCurrentRoute } from '../../../router';
import { getCurrentRouteByLocation } from '../../../router/service';
import HistoryBlock, { TASKS_CONTAINER } from '../../HistoryBlock';

import TaskCreate from './Task/TaskCreate/TaskCreate';
import TaskView from './Task/TaskView/TaskView';
import TasksSupervisor from './TasksSupervisor/TasksSupervisor';

import './TasksContainer.scss';

let lastNonClosedTaskIds = null;
let patientIdRelatedToActiveTask = null;

export default function TasksContainer() {
  const navigate = useNavigate();
  const currentRoute = useCurrentRoute();
  const activeTask = useActiveTask();
  const updateActiveTask = useActiveTaskUpdate();

  const [allStudySites, setAllStudySites] = useState([]);
  const [allAssignees, setAllAssignees] = useState([]);

  const patientId = currentRoute?.params?.patientId;

  useEffect(function() {
    TaskApi.getAllStudySites().then(({ data: studySites }) => {
      setAllStudySites(studySites);
    });
    UserApiApi.getAllEligibleAssignees().then(({ data }) => {
      setAllAssignees(
        data.map(e => {
          e.name = `${e.firstName} ${e.lastName}`;
          return e;
        })
      );
    });
  }, []);

  useEffect(
    function() {
      return TasksSupervisor.onCreate(function() {
        updateActiveTask({ taskId: null, ssuId: null, isHidden: false });
      })
        .onOpen(function({ taskId, ssuId, keepIsHidden }) {
          const payload = { taskId, ssuId };
          if (!keepIsHidden) {
            payload.isHidden = false;
          }
          lastNonClosedTaskIds = { taskId, ssuId };
          updateActiveTask(payload);
        })
        .onClose(function() {
          if (activeTask.isChanged) {
            showConfirmDialog()
              .then(() => {
                updateActiveTask(null);
                if (activeTask.taskId) {
                  lastNonClosedTaskIds = null;
                } else if (lastNonClosedTaskIds) {
                  TasksSupervisor.open(lastNonClosedTaskIds.taskId, lastNonClosedTaskIds.ssuId);
                }
              })
              .catch(() => {});
          } else {
            updateActiveTask(null);
            if (activeTask.taskId) {
              lastNonClosedTaskIds = null;
            } else if (lastNonClosedTaskIds) {
              TasksSupervisor.open(lastNonClosedTaskIds.taskId, lastNonClosedTaskIds.ssuId);
            }
          }
        })
        .onSetHidden(function({ isHidden }) {
          updateActiveTask({ isHidden });
        })
        .subscribe();
    },
    [activeTask.isChanged, activeTask.taskId, updateActiveTask]
  );

  useEffect(() => {
    const taskId = get(currentRoute, 'searchParams.taskId', null);
    const taskSsuId = get(currentRoute, 'searchParams.taskSsuId', null);
    if (taskId && taskSsuId) {
      TasksSupervisor.open(taskId, taskSsuId);
      const searchParams = currentRoute.searchParams;
      delete searchParams.taskId;
      delete searchParams.taskSsuId;
      if (isEmpty(searchParams)) {
        navigate(currentRoute.pathname);
        currentRoute.searchParams = searchParams;
        currentRoute.search = '';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    function() {
      if (activeTask.isClosed) {
        return;
      }
      patientIdRelatedToActiveTask = patientId;
    },
    [activeTask.isClosed, patientId]
  );

  useEffect(
    function() {
      if (activeTask.isClosed) return;
      return HistoryBlock.block(TASKS_CONTAINER, function(discard, location, action) {
        if (action === 'REPLACE') return true;
        if (activeTask.isChanged) {
          showConfirmDialog()
            .then(() => {
              updateActiveTask(null);
              discard();
            })
            .catch(() => {});
        } else {
          if (isActiveTaskCloseNeededOnRedirect(location)) {
            sessionStorage.setItem('OPENED_TASK_MAP', JSON.stringify(null));
            updateActiveTask(null);
          }
          return true;
        }
        return false;
      });
    },
    [navigate, activeTask.isClosed, activeTask.isChanged, updateActiveTask]
  );

  if (activeTask.isClosed) return null;

  return (
    <div className={cx('eds-tasks-container', { 'eds-tasks-container-hidden': activeTask.isHidden })}>
      {activeTask.isViewMode ? (
        <TaskView key={activeTask.updateTimestamp} allStudySites={allStudySites} allAssignees={allAssignees} />
      ) : activeTask.isCreateMode ? (
        <TaskCreate allStudySites={allStudySites} allAssignees={allAssignees} />
      ) : null}
      <Button
        id="hide-task-button"
        priority="medium"
        size="h40"
        className="eds-tasks-container-show-hide"
        onClick={function() {
          TasksSupervisor.setHidden(!activeTask.isHidden);
        }}
      >
        <Icon>{activeTask.isHidden ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}</Icon>
      </Button>
    </div>
  );
}

function showConfirmDialog() {
  return ModalBoxes.confirm({
    content: 'Are you sure you want to discard the changes made to this task?',
    title: 'Discard Task Changes',
    confirmButton: 'Yes',
    cancelButton: 'No'
  });
}

function isActiveTaskCloseNeededOnRedirect(location) {
  const nextRoute = getCurrentRouteByLocation(location);
  const pageKey = nextRoute?.key || '';
  const nextPatientId = nextRoute?.params?.patientId;
  return (
    !pageKey.includes('Patient Studies') || isAnotherPatientId(patientIdRelatedToActiveTask, nextPatientId, location)
  );
}

function isAnotherPatientId(patientIdRelatedToActiveTask, patientId, location) {
  if (!patientIdRelatedToActiveTask) {
    return false;
  }
  if (!patientId) {
    return !location.pathname.includes(patientIdRelatedToActiveTask);
  }
  return patientIdRelatedToActiveTask !== patientId;
}
