import { useCallback, useEffect, useMemo } from 'react';
import PatientTravelWorklistApi from 'api/patient/PatientTravelWorklistApi';
import Loader from 'common/elements/Loader/Loader';
import { differenceBy } from 'lodash/array';
import { debounce } from 'lodash/function';
import { isEmpty } from 'lodash/lang';

import ApplyAndResetButtons from 'components/ApplyAndResetButtons/ApplyAndResetButtons';
import BasicMultiselect from 'components/eui/EuiFiltersContext/controls/BasicMultiselect/BasicMultiselect';
import StudySiteMultiselect from 'components/eui/EuiFiltersContext/controls/StudySiteMultiselect/StudySiteMultiselect';
import { useEuiFiltersDispatch, useEuiFiltersState } from 'components/eui/EuiFiltersContext/EuiFiltersContext';
import { setInitialStateProcessor } from 'components/eui/EuiFiltersContext/processors';
import { FRS_READY } from 'components/eui/EuiFiltersContext/reducer';
import { PageInfoHeader } from 'components/PageInfoHeader/PageInfoHeader';

import { INITIAL_FILTERS_STATE } from '../constants';

import Assigned from './Assigned/Assigned';

export default function FiltersSection({ gridData, onApply, applyDisabled }) {
  const state = useEuiFiltersState();
  const dispatch = useEuiFiltersDispatch();

  const { schema } = state;

  const status = state.schema?.status;

  const getStudySites = useCallback(function() {
    return PatientTravelWorklistApi.getStudySitesWithTravels().then(({ data: source }) => ({ source }));
  }, []);

  const debounceCache = useMemo(function() {
    return debounce(cb => cb(), 500);
  }, []);

  useEffect(
    function() {
      if (status !== FRS_READY) {
        return;
      }
      debounceCache(function() {
        dispatch({
          type: 'PERFORM',
          processor: cacheFiltersProcessor
        });
      });
    },
    [debounceCache, dispatch, gridData, status]
  );

  useEffect(
    function() {
      if (status) return;
      dispatch({
        type: 'PERFORM',
        processor: setInitialStateProcessor,
        payload: {
          ...INITIAL_FILTERS_STATE
        }
      });
    },
    [dispatch, status]
  );

  useEffect(
    function() {
      if (isEmpty(gridData)) return;
      const allAssignees = gridData.map(function({ assigneePersonnelId, assigneePersonnelName }) {
        return { id: assigneePersonnelId, name: assigneePersonnelName };
      });

      dispatch({
        type: 'PERFORM',
        processor: setAssignedProcessor,
        payload: allAssignees
      });
    },
    [dispatch, gridData]
  );

  if (isEmpty(schema)) return null;

  return (
    <PageInfoHeader>
      <div className="general-header-wrapper" style={{ minHeight: 71 }}>
        <BasicMultiselect uniqKey="statuses" state={state} dispatch={dispatch} label="Status" />
        <StudySiteMultiselect uniqKey="ssu" state={state} dispatch={dispatch} getStudySites={getStudySites} />
        <Assigned uniqKey="assigned" state={state} dispatch={dispatch} />
        {status === FRS_READY ? (
          <ApplyAndResetButtons applyClassName="apply-btn" onApply={onApply} applyDisabled={applyDisabled} />
        ) : (
          <Loader dark={true} />
        )}
      </div>
    </PageInfoHeader>
  );
}

function cacheFiltersProcessor(state) {
  const cache = {
    statuses: {
      value: state?.statuses?.value
    },
    ssu: {
      selectedStudyMap: state?.ssu?.selectedStudyMap,
      selectedSiteMap: state?.ssu?.selectedSiteMap
    },
    assigned: {
      value: state?.assigned?.value
    }
  };

  sessionStorage.setItem(state.cacheKey, JSON.stringify(cache));
  return state;
}

function setAssignedProcessor(state, action) {
  const { type, payload } = action;

  const notExistInFilters = differenceBy(payload, state.assigned?.source, 'id');

  if (isEmpty(notExistInFilters)) {
    return state;
  }
  return {
    ...state,
    assigned: {
      ...state.assigned,
      source: [...state.assigned.source, ...notExistInFilters],
      value: [...state.assigned.value, ...notExistInFilters]
    }
  };
}
