import {
  TR_PENDING,
  travelStatuses,
  travelStatusMap
} from 'components/pages/patient-source/Patients/PatientInfo/EncountersSection/TravelSection/travelConstants';

export const INITIAL_FILTERS_STATE = {
  statuses: {
    source: [...travelStatuses],
    value: [{ id: TR_PENDING, name: travelStatusMap[TR_PENDING] }]
  },
  ssu: {
    studySites: [],
    studies: [],
    sites: [],
    selectedStudyMap: {},
    selectedSiteMap: {},
    selectedStudies: [],
    selectedSites: []
  },
  assigned: {
    source: [{ id: null, name: 'Empty' }],
    value: null
  }
};
