import { useSelector } from 'react-redux';
import { get } from 'lodash/object';

export default function useAppInfo() {
  return useSelector(({ appInfo }) => appInfo);
}

export const FF_PATIENT_REIMBURSEMENT = 'patientReimbursementEnabled';
export const FF_PATIENT_TRAVEL = 'patientTravelEnabled';

export function useFeatureFlag(flag) {
  const appInfo = useAppInfo();
  return get(appInfo, ['features', flag], false);
}
