import React from 'react';
import { Accordion } from 'react-accessible-accordion';
import { isEmpty } from 'lodash/lang';

import Checkbox from '../../../../../../../common/data-entry/InputSelectors/Checkbox';
import { scBlue100 } from '../../../../../../../constants/systemColors';
import { InvestigatorFieldsCheckbox } from '../../InvestigatorFields/InvestigatorFieldsCheckbox/InvestigatorFieldsCheckbox';
import { isAllItemSelected, isReadOnlyField } from '../../itemGroupSetupService';

import { FieldsToSelect } from './FieldsToSelect/FieldsToSelect';
import { PredefinedItemsToSelect } from './PredefinedItemsToSelect';

import './ItemGroupSetupContent.scss';

export function ItemGroupSetupContent({
  hideIsTableOptions,
  inputs,
  isElligoPatientStatusDomain,
  displayTableType,
  onChangePredefinedFields,
  updateInputsAndPreviewData,
  isStipendItemGroup,
  previewDataList,
  editMode,
  updateState,
  domainId,
  domainArray,
  changeSequenceCounter,
  sequenceCounter,
  setCombinedLabelList,
  terminologyVersionGroupNumber,
  isLogType,
  combinedLabelList,
  domain
}) {
  const optionalFields = combinedLabelList.filter(e => !e.isCustom);
  const customFields = combinedLabelList.filter(e => e.isCustom).flatMap(e => e.customItemDefinationList);
  const fixedFields = [...optionalFields, ...customFields].filter(e => e.fixedField);
  const show = !isEmpty(inputs[0]?.optional) || !isElligoPatientStatusDomain;

  return (
    <div className="col element-question p-0">
      <div className="col-xs-12 mt-3">
        <Accordion accordion={false} className="accordion">
          {show && (
            <FieldsToSelect
              terminologyVersionGroupNumber={terminologyVersionGroupNumber}
              inputs={inputs}
              getCheckboxForTableView={getCheckboxForTableView}
              getInvestigatorFieldCheckboxForTableView={getInvestigatorFieldCheckboxForTableView}
              getExpenseFixedCheckboxForTableView={getExpenseFixedCheckboxForTableView}
              updateInputsAndPreviewData={updateInputsAndPreviewData}
              isStipendItemGroup={isStipendItemGroup}
              previewDataList={previewDataList}
              editMode={editMode}
              updateState={updateState}
              onChangeLabelTextHandler={onChangeLabelTextHandler}
              domainId={domainId}
              changeSequenceCounter={changeSequenceCounter}
              domainArray={domainArray}
              sequenceCounter={sequenceCounter}
              isElligoPatientStatusDomain={isElligoPatientStatusDomain}
              setCombinedLabelList={setCombinedLabelList}
              hideIsTableOptions={hideIsTableOptions}
              isLogType={isLogType}
              domain={domain}
            />
          )}
          {displayTableType && (
            <PredefinedItemsToSelect inputs={inputs} onChangePredefinedFields={onChangePredefinedFields} />
          )}
        </Accordion>
      </div>
    </div>
  );

  function onChangeLabelTextHandler(text, labelIndex, itemIndex, isCustom) {
    if (isCustom) {
      inputs[0].custom[labelIndex].customItemDefinationList[itemIndex].inputValue = text;
    } else {
      inputs[0].optional[labelIndex].itemDefinitionList[itemIndex].inputValue = text;
    }
    updateInputsAndPreviewData(inputs);
  }

  function onTableCheck(checked, item, pindex, labelIndex, index, type) {
    if (type === 'custom') {
      inputs[pindex][type][labelIndex].customItemDefinationList[index].isTable = checked;
    } else {
      inputs[pindex][type][labelIndex].itemDefinitionList[index].isTable = checked;
    }
    if (type === 'optional' && checked) {
      inputs[pindex][type][labelIndex].itemDefinitionList[index].isChecked = checked;
      inputs[pindex].allOptionalChecked = isAllItemSelected(inputs[pindex].optional[labelIndex].itemDefinitionList);
    }
    updateInputsAndPreviewData(inputs);
  }

  function onIsInvestigatorFieldChange(checked, item, pindex, labelIndex, index, type) {
    if (type === 'custom') {
      inputs[pindex][type][labelIndex].customItemDefinationList[index].investigatorField = checked;
    } else {
      inputs[pindex][type][labelIndex].itemDefinitionList[index].investigatorField = checked;
    }
    if (type === 'optional' && checked) {
      inputs[pindex][type][labelIndex].itemDefinitionList[index].isChecked = checked;
    }
    updateInputsAndPreviewData(inputs);
  }

  function onIsFixedFieldChange(checked, item, pindex, labelIndex, index, type) {
    if (type === 'custom') {
      inputs[pindex][type][labelIndex].customItemDefinationList[index].fixedField = checked;
    } else {
      inputs[pindex][type][labelIndex].itemDefinitionList[index].fixedField = checked;
    }
    if (type === 'optional' && checked) {
      inputs[pindex][type][labelIndex].itemDefinitionList[index].isChecked = checked;
    }
    updateInputsAndPreviewData(inputs);
  }

  function getCheckboxForTableView(item, tableChckUniqId, labelIndex, index, type) {
    // If not a 'Procedure' display table view checkbox
    if (!hideIsTableOptions && !isLogType) {
      return (
        <div className="col-md-2 pl-0 position-relative d-flex align-items-center">
          <i className="material-icons tableseqicon p-0" title="Table View">
            border_all
          </i>
          <Checkbox
            color={scBlue100}
            id={tableChckUniqId}
            checked={item.isTable}
            onChange={({ target }) => {
              onTableCheck(target?.checked, item, 0, labelIndex, index, type);
            }}
          />
        </div>
      );
    }
  }

  function getInvestigatorFieldCheckboxForTableView(item, labelIndex, index, type) {
    return (
      <>
        <div className="col p-0 position-relative d-flex align-items-center justify-content-center">
          <InvestigatorFieldsCheckbox
            item={item}
            onInvestigatorFieldChange={value => {
              onIsInvestigatorFieldChange(value, item, 0, labelIndex, index, type);
            }}
          />
        </div>
      </>
    );
  }

  function getExpenseFixedCheckboxForTableView(item, fixedFieldCheckUuid, labelIndex, index, type) {
    return (
      <>
        <div className="col p-0 position-relative d-flex align-items-center justify-content-center">
          {!isReadOnlyField(item.inputType) && (
            <Checkbox
              color={scBlue100}
              id={fixedFieldCheckUuid}
              checked={item.fixedField}
              disabled={!item?.fixedField && !isEmpty(fixedFields) && fixedFields.length >= 2}
              onChange={({ target }) => {
                onIsFixedFieldChange(target?.checked, item, 0, labelIndex, index, type);
              }}
            />
          )}
        </div>
      </>
    );
  }
}
