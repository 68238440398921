import React from 'react';

import Button from '../../../../common/general/Button';
import WithMultiRoleCheck from '../../../UserRoleSwitcher/WithMultiRoleCheck';

import { viewAllProgressNotes } from './NoteService';

export default function SummaryColumnContent({
  hasAccessToView,
  hasAccessToEdit,
  link,
  sitePatientIdentifier,
  studySiteStatus,
  reviewButtonDisabled,
  notesInfo
}) {
  return (
    <React.Fragment>
      <span className="tooltip-cell">
        <WithMultiRoleCheck
          Component={Button}
          ssuPatientId={sitePatientIdentifier}
          disabled={reviewButtonDisabled}
          size="h28"
          to={link}
          priority="high"
        >
          Review
        </WithMultiRoleCheck>
      </span>
      {hasAccessToView && (
        <Button
          size="h28"
          priority="high"
          className="ml-2"
          onClick={() => viewAllProgressNotes(sitePatientIdentifier, studySiteStatus, hasAccessToEdit, notesInfo)}
        >
          Notes
        </Button>
      )}
    </React.Fragment>
  );
}

SummaryColumnContent.defaultProps = {
  reviewButtonDisabled: false
};
