import { isEmpty, isString } from 'lodash/lang';

export default function setCity(state, { type, payload: { path, value } }) {
  return {
    ...state,
    values: {
      ...state.values,
      [path]: {
        ...state.values[path],
        ...prepareValue(value)
      }
    }
  };
}

function prepareValue(value) {
  if (!isString(value) && !isEmpty(value)) {
    return {
      city: value?.city || '',
      countryId: value.countryId || '',
      regionId: value.regionId || ''
    };
  }
  return {
    city: value || ''
  };
}
