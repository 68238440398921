import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { PatientTravelApi } from '../../../../../../../api';
import { useCurrentRoute } from '../../../../../../root/router';
import PaymentsSectionHeader from '../PaymentsSection/PaymentsSectionHeader/PaymentsSectionHeader';

import { AddUnexpectedTravelButton } from './AddUnexpectedTravelButton';
import useTravelGridData from './useTravelGridData';

import './TravelSection.scss';

export default function TravelSection() {
  const navigate = useNavigate();
  const currentRoute = useCurrentRoute();
  const ssuPatientId = currentRoute.params?.ssuPatientId;
  const [loading, setLoading] = useState(true);
  const [columns, data, setData] = useTravelGridData();

  const loadData = useCallback(
    function() {
      if (!ssuPatientId) return;
      setLoading(true);
      PatientTravelApi.getPatientTravelRequests(ssuPatientId).then(res => {
        setData(res.data);
        setLoading(false);
      });
    },
    [ssuPatientId, setData]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className={'travel-section'}>
      <PaymentsSectionHeader title="Travel Requests">
        <AddUnexpectedTravelButton />
      </PaymentsSectionHeader>
      <DataGridPro
        loading={loading}
        getRowId={getRowId}
        rowHeight={38}
        rows={data}
        columns={columns}
        disableRowSelectionOnClick
        localeText={{ noRowsLabel: 'No results to display' }}
        onRowClick={function({ row }) {
          if (!row.id) {
            return;
          }
          navigate(
            `./travel-request/${row.id}?ssuPatientEncounterId=${row.sitePatientEncounterId ||
              ''}&protocolEncounterKey=${row.protocolEncounterKey || ''}`
          );
        }}
      />
    </div>
  );

  function getRowId(row) {
    return row.sitePatientEncounterId || row.protocolEncounterKey;
  }
}
