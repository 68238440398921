import { useEffect } from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';

import AddressFields from '../AddressFields/AddressFields';
import AddressValidator from '../AddressFields/AddressValidator';

export default function PreferredPickupAddress({ preferredPickupAddress, values, errors, actions, readOnly = false }) {
  const { pickupAddress } = values;
  const { setAddressType, setAddressField, setCountryId, setCity } = actions;

  useEffect(
    function() {
      setAddressType({
        path: 'pickupAddress',
        addressType: preferredPickupAddress?.type || 'PATIENT_HOME_ADDRESS',
        address: preferredPickupAddress,
        initial: true
      });
      AddressValidator.revalidate('pickupAddress');
    },
    [setAddressType, preferredPickupAddress]
  );

  return (
    <Grid
      container
      spacing={3}
      alignItems="center"
      sx={{ pt: 2, backgroundColor: '#ffffff', mt: 2, borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.12)' }}
    >
      <Grid xs={12}>
        <Typography variant="subtitle2">Preferred Pickup Address</Typography>
      </Grid>
      <Grid xs={12} container spacing={3} alignItems="flex-end">
        <Grid container spacing={2} alignItems="flex-start" xs>
          <Grid xs={9} sx={{ p: 0 }}>
            <FormControl disabled={readOnly}>
              <RadioGroup
                row
                value={pickupAddress.type}
                onChange={function({ target }) {
                  const payload = { path: 'pickupAddress', addressType: target.value };
                  if (payload.addressType === 'PATIENT_HOME_ADDRESS') {
                    payload.address = { ...preferredPickupAddress, type: target.value };
                  }
                  setAddressType(payload);
                  AddressValidator.revalidate('pickupAddress');
                }}
              >
                <FormControlLabel sx={{ margin: 0 }} value="PATIENT_HOME_ADDRESS" control={<Radio />} label="Home" />
                <FormControlLabel sx={{ margin: 0 }} value="OTHER" control={<Radio />} label="Other" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid xs={3}></Grid>
          <AddressFields
            readOnly={readOnly}
            path="pickupAddress"
            values={values}
            errors={errors}
            setAddressField={setAddressField}
            setCountryId={setCountryId}
            setCity={setCity}
          />
        </Grid>
        <Grid xs="auto">
          <TextField
            disabled={readOnly}
            name="pickupAddressComments"
            value={values.pickupAddressComments}
            onChange={actions.handleChange}
            fullWidth
            label="Comments"
            multiline
            maxRows={4}
            variant="standard"
            inputProps={{ maxLength: 100 }}
            sx={{ minWidth: 300 }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
