import { filter, reduce } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';
import moment from 'moment';

import { yearMonthDayFormat } from '../../../constants/dateFormat';

import {
  filterPcnsBasedOnSiteIds,
  filterPcnsByStudyIdsAndSsus,
  filterSitesByStudyIdsAndCanceledSsus,
  filterSitesByStudyIdsAndSsus,
  filterStudiesBasedOnSiteIds,
  filterStudiesByPcnIdsAndSsus,
  filterStudyTypesBasedOnSelectedStudyIds,
  filterTherapeuticAreasBasedOnSelectedStudyIds
} from './modeReportsFiltersService';
import { DATE_FORMAT } from './modeReportsServices';

export function reducer(state, action) {
  const { ssus, initialData, filters, selectedValues } = state || {};

  if (!ssus || !filters || !selectedValues || !initialData) return state;

  const reducers = {
    SET_SSUS: function() {
      return { ...state, ssus: action.payload };
    },

    INIT_DATA: function() {
      const { initialData, filters, selectedValues } = prepareModeParameters(action.payload);
      return {
        ...state,
        initialData,
        filters,
        selectedValues
      };
    },

    SET_DATA_FILTER_SITES_BY_CANCELED_SSUS: function() {
      const sites = filterSitesByStudyIdsAndCanceledSsus(
        action.payload.value.studies,
        action.payload.value.sites,
        ssus
      );
      return {
        ...state,
        initialData: { ...initialData, ...{ sites } },
        filters: { ...filters, ...{ sites } },
        selectedValues: { ...selectedValues, ...{ sites } }
      };
    },

    SET_DATE_TO_NOW: function() {
      const now = new moment().format(yearMonthDayFormat);
      const currentValue = selectedValues[action.payload.filterKey];
      return {
        ...state,
        initialData: { ...initialData, [action.payload.filterKey]: now },
        filters: { ...filters, [action.payload.filterKey]: now },
        selectedValues: { ...selectedValues, [action.payload.filterKey]: !isEmpty(currentValue) ? currentValue : now }
      };
    },

    SET_DATA_WITH_START_AND_END_DATE_DEFAULT_6_MONTH_AGO: function() {
      const defaultDate6MonthAgo = new moment()
        .subtract(6, 'months')
        .startOf('month')
        .format(yearMonthDayFormat);
      const currentValue = selectedValues[action.payload.filterKey];
      return {
        ...state,
        initialData: { ...initialData, [action.payload.filterKey]: defaultDate6MonthAgo },
        filters: { ...filters, [action.payload.filterKey]: defaultDate6MonthAgo },
        selectedValues: {
          ...selectedValues,
          [action.payload.filterKey]: !isEmpty(currentValue) ? currentValue : defaultDate6MonthAgo
        }
      };
    },

    SET_DATA_WITH_START_AND_END_DATE_DEFAULT_WEEK_AGO: function() {
      const defaultDate1WeekAgo = new moment().subtract(1, 'week').format(yearMonthDayFormat);
      return {
        ...state,
        initialData: { ...initialData, [action.payload.filterKey]: defaultDate1WeekAgo },
        filters: { ...filters, [action.payload.filterKey]: defaultDate1WeekAgo },
        selectedValues: { ...selectedValues, [action.payload.filterKey]: defaultDate1WeekAgo }
      };
    },

    SET_DATA_WITH_START_AND_END_DATE_DEFAULT_1_YEAR_AGO: function() {
      const defaultDate1YearAgo = new moment().subtract(1, 'year').format(yearMonthDayFormat);
      const currentValue = selectedValues[action.payload.filterKey];
      return {
        ...state,
        initialData: { ...initialData, [action.payload.filterKey]: defaultDate1YearAgo },
        filters: { ...filters, [action.payload.filterKey]: defaultDate1YearAgo },
        selectedValues: {
          ...selectedValues,
          [action.payload.filterKey]: !isEmpty(currentValue) ? currentValue : defaultDate1YearAgo
        }
      };
    },

    SET_DATA_WITH_START_AND_END_DATE_DEFAULT_3_MONTHS_AGO: function() {
      const defaultDate3MonthsAgo = new moment().subtract(3, 'months').format(yearMonthDayFormat);
      const currentValue = selectedValues[action.payload.filterKey];
      return {
        ...state,
        initialData: { ...initialData, [action.payload.filterKey]: defaultDate3MonthsAgo },
        filters: { ...filters, [action.payload.filterKey]: defaultDate3MonthsAgo },
        selectedValues: {
          ...selectedValues,
          [action.payload.filterKey]: !isEmpty(currentValue) ? currentValue : defaultDate3MonthsAgo
        }
      };
    },

    SET_DATA_WITH_START_AND_END_DATE_DEFAULT_BEGINNING_OF_CURRENT_MONTH: function() {
      const defaultDate3MonthsAgo = new moment().startOf('month').format(yearMonthDayFormat);
      return {
        ...state,
        initialData: { ...initialData, [action.payload.filterKey]: defaultDate3MonthsAgo },
        filters: { ...filters, [action.payload.filterKey]: defaultDate3MonthsAgo },
        selectedValues: { ...selectedValues, [action.payload.filterKey]: defaultDate3MonthsAgo }
      };
    },

    ON_STUDY_METRICS_STUDY_CHANGE: function() {
      const pcn = filterPcnsByStudyIdsAndSsus(state.ssus, action.payload.value, state.filters.pcn);
      const therapeutic_areas = filterTherapeuticAreasBasedOnSelectedStudyIds(
        initialData.therapeutic_areas,
        action.payload.value,
        ssus
      );
      return {
        ...state,
        selectedValues: { ...selectedValues, pcn, therapeutic_areas, studies: action.payload.value },
        filters: { ...filters, therapeutic_areas }
      };
    },

    ON_STUDY_METRICS_PCN_CHANGE: function() {
      const studies = filterStudiesByPcnIdsAndSsus(ssus, initialData.studies, action.payload.value);
      const therapeutic_areas = filterTherapeuticAreasBasedOnSelectedStudyIds(
        initialData.therapeutic_areas,
        studies,
        ssus
      );
      return {
        ...state,
        selectedValues: { ...selectedValues, pcn: action.payload.value, therapeutic_areas, studies },
        filters: { ...filters, therapeutic_areas }
      };
    },

    ON_SELECT_CHANGE: function() {
      return {
        ...state,
        selectedValues: { ...selectedValues, [action.payload.key]: action.payload.value }
      };
    },

    ON_SELECT_DATE_CHANGE: function() {
      return {
        ...state,
        selectedValues: { ...selectedValues, [action.payload.key]: action.payload.value.format(DATE_FORMAT) }
      };
    },

    ON_SELECT_DATE_CLEAR: function() {
      return {
        ...state,
        selectedValues: { ...selectedValues, [action.payload.key]: '' }
      };
    },

    CLEAR_DATA: function() {
      return {
        initialData: {},
        filters: {},
        selectedValues: {},
        ssus
      };
    },

    ON_STUDY_SELECT_CHANGE: function() {
      const pcn = filterPcnsByStudyIdsAndSsus(ssus, action.payload.value, initialData?.pcn);
      const sites = filterSitesByStudyIdsAndCanceledSsus(action.payload.value, initialData?.sites, ssus);
      return {
        ...state,
        selectedValues: { studies: action.payload.value, pcn, sites },
        filters: { ...filters, sites }
      };
    },

    ON_PCN_SELECT_CHANGE: function() {
      const filteredStudy = filterStudiesByPcnIdsAndSsus(
        ssus,
        filters.studies,
        action.payload.value,
        selectedValues.studies
      );
      const filteredSites = filterSitesByStudyIdsAndCanceledSsus(filteredStudy, initialData?.sites, ssus);
      return {
        ...state,
        selectedValues: { studies: filteredStudy, pcn: action.payload.value, sites: filteredSites },
        filters: { ...filters, sites: filteredSites }
      };
    },

    ON_STUDY_MULTISELECT_CHANGE: function() {
      const pcn = filterPcnsByStudyIdsAndSsus(state.ssus, action.payload.value, state.filters.pcn);
      const sites = filterSitesByStudyIdsAndSsus(state.ssus, action.payload.value, state.initialData.sites);
      return {
        ...state,
        selectedValues: { ...selectedValues, studies: action.payload.value, pcn, sites },
        filters: { ...state.filters, ...{ sites: sites } }
      };
    },

    ON_STUDY_MULTISELECT_CHANGE_NON_FILTERED: function() {
      const pcn = filterPcnsByStudyIdsAndSsus(state.ssus, action.payload.value, state.filters.pcn);
      const sites = filterSitesByStudyIdsAndSsus(state.ssus, action.payload.value, state.initialData.sites);
      return {
        ...state,
        selectedValues: { ...selectedValues, studies: action.payload.value, pcn, sites },
        filters: { ...state.filters }
      };
    },

    ON_STUDY_MULTISELECT_CHANGE_FILTER_CANCELED_SSUS: function() {
      const pcn = filterPcnsByStudyIdsAndSsus(state.ssus, action.payload.value, state.filters.pcn);
      const sites = filterSitesByStudyIdsAndCanceledSsus(action.payload.value, state.initialData.sites, state.ssus);
      return {
        ...state,
        selectedValues: { ...selectedValues, studies: action.payload.value, pcn, sites },
        filters: { ...state.filters, ...{ sites: sites } }
      };
    },

    ON_PCN_MULTISELECT_CHANGE: function() {
      const studies = filterStudiesByPcnIdsAndSsus(state.ssus, state.filters.studies, action.payload.value);
      const sites = filterSitesByStudyIdsAndSsus(state.ssus, studies, state.initialData.sites);
      return {
        ...state,
        selectedValues: { ...selectedValues, studies, pcn: action.payload.value, sites },
        filters: { ...filters, ...{ sites } }
      };
    },

    ON_PCN_MULTISELECT_CHANGE_NON_FILTERED: function() {
      const studies = filterStudiesByPcnIdsAndSsus(state.ssus, state.filters.studies, action.payload.value);
      const sites = filterSitesByStudyIdsAndSsus(state.ssus, studies, state.initialData.sites);
      return {
        ...state,
        selectedValues: { ...selectedValues, studies, pcn: action.payload.value, sites },
        filters: { ...filters }
      };
    },

    ON_SITE_SELECT_STUDIES_PCNS: function() {
      const studies = filterStudiesBasedOnSiteIds(initialData.studies, action.payload.value, state.ssus);
      const pcn = filterPcnsBasedOnSiteIds(initialData.pcn, action.payload.value, state.ssus);
      return {
        ...state,
        selectedValues: { ...selectedValues, studies, pcn, sites: action.payload.value },
        filters: { ...filters, studies, pcn }
      };
    },

    ON_SITE_SELECT_STUDIES_PCNS_NON_FILTERED: function() {
      const studies = filterStudiesBasedOnSiteIds(initialData.studies, action.payload.value, state.ssus);
      const pcn = filterPcnsBasedOnSiteIds(initialData.pcn, action.payload.value, state.ssus);
      return {
        ...state,
        selectedValues: { ...selectedValues, studies, pcn, sites: action.payload.value },
        filters: { ...filters }
      };
    },

    ON_PCN_MULTISELECT_CHANGE_FILTER_CANCELED_SSUS: function() {
      const studies = filterStudiesByPcnIdsAndSsus(state.ssus, state.filters.studies, action.payload.value);
      const sites = filterSitesByStudyIdsAndCanceledSsus(studies, state.initialData.sites, state.ssus);
      return {
        ...state,
        selectedValues: { ...selectedValues, studies, pcn: action.payload.value, sites },
        filters: { ...filters, ...{ sites } }
      };
    },

    ON_STUDY_CHANGE_WITH_STUDY_TYPE_AND_AREAS: function() {
      const pcn = filterPcnsByStudyIdsAndSsus(ssus, action.payload.value, filters.pcn);
      const sites = filterSitesByStudyIdsAndSsus(ssus, action.payload.value, initialData.sites);
      const study_type = filterStudyTypesBasedOnSelectedStudyIds(initialData.study_type, action.payload.value, ssus);
      const therapeutic_areas = filterTherapeuticAreasBasedOnSelectedStudyIds(
        initialData.therapeutic_areas,
        action.payload.value,
        ssus
      );
      return {
        ...state,
        selectedValues: { ...selectedValues, studies: action.payload.value, pcn, sites, study_type, therapeutic_areas },
        filters: { ...filters, sites, study_type, therapeutic_areas }
      };
    },

    ON_STUDY_CHANGE_WITH_STUDY_TYPE_AND_AREAS_FILTER_CANCELED_SSUS: function() {
      const pcn = filterPcnsByStudyIdsAndSsus(ssus, action.payload.value, filters.pcn);
      const sites = filterSitesByStudyIdsAndCanceledSsus(action.payload.value, initialData.sites, ssus);
      const study_type = filterStudyTypesBasedOnSelectedStudyIds(initialData.study_type, action.payload.value, ssus);
      const therapeutic_areas = filterTherapeuticAreasBasedOnSelectedStudyIds(
        initialData.therapeutic_areas,
        action.payload.value,
        ssus
      );
      return {
        ...state,
        selectedValues: { ...selectedValues, studies: action.payload.value, pcn, sites, study_type, therapeutic_areas },
        filters: { ...filters, sites, study_type, therapeutic_areas }
      };
    },

    ON_STUDY_CHANGE_WITH_SITE: function() {
      const sites = filterSitesByStudyIdsAndSsus(state.ssus, action.payload.value, state.initialData.sites);
      return {
        ...state,
        selectedValues: { ...selectedValues, studies: action.payload.value, sites },
        filters: { ...filters, sites }
      };
    },

    ON_PCN_CHANGE_WITH_STUDY_TYPE_AND_AREAS: function() {
      const studies = filterStudiesByPcnIdsAndSsus(ssus, initialData.studies, action.payload.value);
      const sites = filterSitesByStudyIdsAndSsus(ssus, studies, initialData.sites);
      const study_type = filterStudyTypesBasedOnSelectedStudyIds(initialData.study_type, studies, ssus);
      const therapeutic_areas = filterTherapeuticAreasBasedOnSelectedStudyIds(
        initialData.therapeutic_areas,
        studies,
        ssus
      );
      return {
        ...state,
        selectedValues: { ...selectedValues, studies, pcn: action.payload.value, sites, study_type, therapeutic_areas },
        filters: { ...filters, sites, study_type, therapeutic_areas }
      };
    },

    ON_PCN_CHANGE_WITH_STUDY_TYPE_AND_AREAS_FILTER_CANCELED_SSUS: function() {
      const studies = filterStudiesByPcnIdsAndSsus(ssus, initialData.studies, action.payload.value);
      const sites = filterSitesByStudyIdsAndCanceledSsus(studies, initialData.sites, ssus);
      const study_type = filterStudyTypesBasedOnSelectedStudyIds(initialData.study_type, studies, ssus);
      const therapeutic_areas = filterTherapeuticAreasBasedOnSelectedStudyIds(
        initialData.therapeutic_areas,
        studies,
        ssus
      );
      return {
        ...state,
        selectedValues: { ...selectedValues, studies, pcn: action.payload.value, sites, study_type, therapeutic_areas },
        filters: { ...filters, sites, study_type, therapeutic_areas }
      };
    }
  };

  const reducerFunction = reducers[action.type];
  return reducerFunction ? reducerFunction() : state;
}

function prepareModeParameters(modeParameters) {
  const { initialData, lastSelectedData } = splitModeParameters(modeParameters);
  console.log(initialData, lastSelectedData);
  return {
    initialData: { ...initialData },
    filters: { ...initialData },
    selectedValues: getSelectedValues(initialData, lastSelectedData)
  };
}

function splitModeParameters(modeParameters) {
  return reduce(
    modeParameters,
    function(accumulator, value, key) {
      if (key.includes('last_selected_')) {
        accumulator.lastSelectedData[key.replace('last_selected_', '')] = value;
      } else {
        accumulator.initialData[key] = value;
      }
      return accumulator;
    },
    {
      initialData: {},
      lastSelectedData: {}
    }
  );
}

function getSelectedValues(initialData, lastSelectedData) {
  return reduce(
    initialData,
    function(accumulator, value, key) {
      const lastSelected = lastSelectedData[key];
      if (['start_date', 'end_date'].includes(key)) {
        accumulator[key] = !isEmpty(lastSelected) ? moment(lastSelected[0]).format(yearMonthDayFormat) : value;
      } else {
        accumulator[key] = !isEmpty(lastSelected)
          ? filter(value, function({ id }) {
              return lastSelected.includes(id);
            })
          : value;
      }
      return accumulator;
    },
    {}
  );
}
