import axios from 'axios';

const ROOT = 'api/v1/setup';

const ProtocolApi = {
  getPreviewProtocol(studyId, protocolId) {
    return axios.get(`${ROOT}/studies/${studyId}/protocol/${protocolId}`);
  },
  getAuditTrailDetails(type, v1, v2) {
    return axios.get(`${ROOT}/audit/${type}/${v1}/${v2}`);
  },
  deleteDraftProtocol(studyId, uniqueIdentifier) {
    return axios.delete(`${ROOT}/protocols/${uniqueIdentifier}/${studyId}`);
  },
  getAllItemGroupsByStudy(studyId) {
    return axios.get(`${ROOT}/studies/${studyId}/itemGroups`);
  },
  getStudyProtocolBasicInfo(studyId, protocolId) {
    return axios.get(`${ROOT}/studies/${studyId}/protocol/${protocolId}/basic-info`);
  },
  getProtocols(data) {
    return axios.get(`${ROOT}/studies/${data}/protocol`);
  },
  createDraftProtocolForStudy(data) {
    return axios.post(`${ROOT}/studies/${data}/protocol`, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    });
  },
  saveEpochProtocol(studyId, protocolId, data) {
    return axios.post(`${ROOT}/studies/${studyId}/protocol/${protocolId}`, data);
  },
  publishProtocol(studyId, protocolId, data) {
    return axios.post(`${ROOT}/studies/${studyId}/protocol/${protocolId}/publish`, data);
  },
  getProtocolEncountersAndElementsForStudy(studyId, studySiteId) {
    return axios.get(`${ROOT}/studies/${studyId}/study-sites/${studySiteId}/protocols`, {
      hideLoader: true
    });
  },
  deleteItemGroupFromInitialDraftProtocol(studyId, protocolId, itemGroupKey) {
    return axios.delete(`${ROOT}/studies/${studyId}/protocols/${protocolId}/item-groups/${itemGroupKey}`);
  }
};

export default ProtocolApi;
