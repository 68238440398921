import { useMemo, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { PatientTravelWorklistApi } from 'api';
import { isEmpty } from 'lodash/lang';
import { onRequestError } from 'services/handlers';

export default function AssigneeChangeDropdown({ row, onChangeSuccess }) {
  const [loading, setLoading] = useState(false);
  const [availableAssignee, setAvailableAssignee] = useState([]);
  const { id: travelRequestId, assigneePersonnelId: assigneeId, assigneePersonnelName: assigneeName } = row;
  const [saving, setSaving] = useState(false);

  const selectedAssignee = useMemo(
    function() {
      const a = availableAssignee?.find(function({ id }) {
        return assigneeId === id;
      });
      if (a) {
        return a;
      }
      if (assigneeId) {
        return {
          id: assigneeId,
          name: assigneeName
        };
      }
      return null;
    },
    [availableAssignee, assigneeId, assigneeName]
  );

  return (
    <Autocomplete
      onOpen={() => {
        if (!isEmpty(availableAssignee)) {
          return;
        }
        setLoading(true);
        PatientTravelWorklistApi.getAssignees([row.ssuId]).then(({ data }) => {
          setAvailableAssignee([...data]);
          setLoading(false);
        });
      }}
      loading={loading}
      value={selectedAssignee}
      onChange={rowOnChange}
      fullWidth
      autoHighlight
      options={availableAssignee}
      disabled={saving}
      getOptionKey={option => option.id}
      getOptionLabel={option => option.name}
      renderInput={params => <TextField {...params} size="small" variant="standard" />}
    />
  );
  function rowOnChange({ target }, assignee) {
    const assigneeId = assignee?.id || null;
    setSaving(true);
    PatientTravelWorklistApi.updateWorklistRow(travelRequestId, { personnelId: assigneeId || 'NOT_ASSIGNED' })
      .then(function({ data }) {
        onChangeSuccess(data);
      })
      .catch(onRequestError)
      .finally(() => setSaving(false));
  }
}
