import { TRAVEL_REQUEST_WORKLIST } from 'constants/accessToPages';

import TravelRequest from 'components/pages/patient-source/Patients/PatientInfo/TravelRequest/TravelRequest';

import TravelRequestsWorklist from './TravelRequestsWorklist';

export const travelRequestsWorklistRouterConfig = {
  name: 'Travel Requests',
  path: '/travel-requests',
  rootBreadcrumbName: 'Finance',
  component: TravelRequestsWorklist,
  access: [TRAVEL_REQUEST_WORKLIST],
  inherited: [
    {
      name: 'Travel Request',
      path: '/:patientId/:ssuPatientId/:travelRequestId',
      component: TravelRequest,
      access: [TRAVEL_REQUEST_WORKLIST]
    }
  ]
};
