import {
  ACTIVITY,
  BSS,
  CALENDAR,
  CAS,
  DASHBOARDS,
  ENROLMENTS,
  EXTERNAL_INTEGRATION,
  FINANCE,
  HELP,
  INVOICE,
  ITEM_GROUP_TEMPLATES,
  LOAD_PATIENTS,
  MILESTONE_REPORT,
  PATIENT_BULK_UPDATE,
  PI_WORK_LIST,
  PL,
  PLA,
  PRE_SCREEN,
  PROJECTED_REVENUE,
  PS,
  REIMBURSEMENT_WORKLIST,
  REVENUE,
  SAS,
  SBS,
  SCHEDULING_WORK_LIST,
  SITE_BILL,
  SITE_PAYMENTS,
  SM_WORKLIST,
  SS,
  ST,
  STD,
  STUDY_ENROLMENTS,
  STUDY_EXPENSES,
  TASK_WORK_LIST,
  TIMELINE,
  TRANSACTION_LEDGER,
  TRAVEL_REQUEST_WORKLIST,
  VAS,
  WORK_LIST
} from 'constants/accessToPages';

import { setDefaultFilterItemsToStorage } from './MenuOptionsService';

export const MenuItems = [
  {
    name: 'Pre-Screen',
    access: PRE_SCREEN,
    subItems: [
      { name: 'Load Patients', access: LOAD_PATIENTS, path: '/load-patients' },
      { name: 'Patient Bulk Update', access: PATIENT_BULK_UPDATE, path: '/patient-bulk-update' },
      { name: 'Worklist', access: WORK_LIST, path: '/worklist' }
    ]
  },
  {
    name: 'Patient Source',
    access: [PS, TASK_WORK_LIST],
    subItems: [
      { name: 'Patients', access: PL, path: '/patients' },
      { name: 'CRA Review', access: PLA, path: '/patient-source-review' },
      { name: 'Study Manager Worklist', access: SM_WORKLIST, path: '/sm-work-list' },
      { name: 'Investigator Worklist', access: PI_WORK_LIST, path: '/pi-work-list' },
      { name: 'Scheduling Worklist', access: SCHEDULING_WORK_LIST, path: '/scheduling-work-list' },
      { name: 'Task Worklist', access: TASK_WORK_LIST, path: '/task-work-list' }
    ]
  },
  {
    name: 'Study',
    access: [ACTIVITY, TIMELINE, SS],
    subItems: [
      { name: 'Study Details', access: SS, path: '/study-details', onClick: setDefaultFilterItemsToStorage },
      { name: 'Timeline', access: TIMELINE, path: '/study-timeline', onClick: setDefaultFilterItemsToStorage },
      {
        name: 'Milestone Report',
        access: MILESTONE_REPORT,
        path: '/timeline-milestone-report',
        onClick: setDefaultFilterItemsToStorage
      },
      {
        name: 'Cycle Time Report',
        access: MILESTONE_REPORT,
        path: '/cycle-time-report',
        onClick: setDefaultFilterItemsToStorage
      },
      {
        name: 'Enrollment',
        access: STUDY_ENROLMENTS,
        path: '/study-enrollment',
        onClick: setDefaultFilterItemsToStorage
      },
      {
        name: 'Expenses',
        access: STUDY_EXPENSES,
        path: '/study-expenses',
        onClick: setDefaultFilterItemsToStorage
      }
    ]
  },
  {
    name: 'Setup',
    access: ST,
    subItems: [
      { name: 'Protocol', access: STD, path: '/study-protocol', onClick: setDefaultFilterItemsToStorage },
      {
        name: 'Item Group Templates',
        access: ITEM_GROUP_TEMPLATES,
        path: '/protocol-item-group-templates'
      },
      {
        name: 'Budget',
        access: SBS,
        path: '/study-budget'
      },
      { name: 'Publish Budget', access: BSS, path: '/publish-budget' },
      {
        name: 'Accounts',
        access: [CAS, SAS, VAS],
        path: '/accounts'
      },
      {
        name: 'External Integration',
        access: EXTERNAL_INTEGRATION,
        path: '',
        subItems: [
          { name: 'Platform Configuration', access: EXTERNAL_INTEGRATION, path: '/platform-configuration' },
          { name: 'Afterburner Mapping', access: EXTERNAL_INTEGRATION, path: '/afterburner-mapping' },
          { name: 'Mapping Templates', access: EXTERNAL_INTEGRATION, path: '/mapping-templates' },
          { name: 'Afterburner Log', access: EXTERNAL_INTEGRATION, path: '/afterburner-log' }
        ]
      }
    ]
  },
  {
    name: 'Finance',
    access: FINANCE,
    subItems: [
      {
        name: 'Ledger',
        access: TRANSACTION_LEDGER,
        path: '/transaction-ledger',
        onClick: setDefaultFilterItemsToStorage
      },
      { name: 'Revenue', access: REVENUE, path: '/revenue', onClick: setDefaultFilterItemsToStorage },
      {
        name: 'Invoice',
        access: INVOICE,
        path: '/invoice',
        onClick: setDefaultFilterItemsToStorage
      },
      { name: 'Site Bill', access: SITE_BILL, path: '/site-bill' },
      { name: 'Site Payments', access: SITE_PAYMENTS, path: '/site-payments', feature: 'sitePaymentsEnabled' },
      {
        name: 'Reimbursements',
        access: REIMBURSEMENT_WORKLIST,
        path: '/reimbursements',
        onClick: setDefaultFilterItemsToStorage
      },
      { name: 'Enrollment', access: ENROLMENTS, path: '/enrollments', onClick: setDefaultFilterItemsToStorage },
      {
        name: 'Projected Revenue',
        access: PROJECTED_REVENUE,
        path: '/projected-revenue',
        onClick: setDefaultFilterItemsToStorage
      },
      {
        name: 'Travel Requests',
        access: TRAVEL_REQUEST_WORKLIST,
        path: '/travel-requests',
        onClick: setDefaultFilterItemsToStorage
      }
    ]
  },
  {
    name: 'Dashboards',
    access: DASHBOARDS,
    path: '/dashboards'
  },
  {
    name: 'Calendar',
    path: '/calendar',
    access: CALENDAR
  },
  {
    name: 'Help',
    path: '/help',
    access: HELP
  }
];
