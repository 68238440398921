import { useCallback, useEffect, useMemo } from 'react';
import { StudyApi } from 'api';
import { first } from 'lodash/array';
import { sortBy } from 'lodash/collection';
import { debounce } from 'lodash/function';
import { isEmpty } from 'lodash/lang';

import StudyPcn from 'components/eui/EuiFiltersContext/controls/StudyPcn/StudyPcn';
import { useEuiFiltersDispatch, useEuiFiltersState } from 'components/eui/EuiFiltersContext/EuiFiltersContext';
import { setInitialStateProcessor } from 'components/eui/EuiFiltersContext/processors';
import { FRS_READY } from 'components/eui/EuiFiltersContext/reducer';

export default function FiltersSection({ onStudyChange }) {
  const state = useEuiFiltersState();
  const dispatch = useEuiFiltersDispatch();

  const { schema } = state;

  const status = state.schema?.status;

  const getStudies = useCallback(function() {
    return StudyApi.populateNonCanceledStudyList().then(function({ data }) {
      const source = sortBy(
        data.response.map(({ uniqueIdentifier: id, studyName: name, pcn }) => ({ id, name, pcn })),
        ['name']
      );
      const value = first(source);
      return {
        source,
        value
      };
    });
  }, []);

  const debounceCache = useMemo(function() {
    return debounce(cb => cb(), 500);
  }, []);

  useEffect(
    function() {
      if (status !== FRS_READY) {
        return;
      }

      onStudyChange(state.study?.value?.id);

      debounceCache(function() {
        dispatch({
          type: 'PERFORM',
          processor: cacheFiltersProcessor
        });
      });
    },
    [debounceCache, dispatch, onStudyChange, state, status]
  );

  useEffect(
    function() {
      if (status) return;
      dispatch({
        type: 'PERFORM',
        processor: setInitialStateProcessor,
        payload: {
          study: {
            source: [],
            value: null
          }
        }
      });
    },
    [dispatch, status]
  );

  if (isEmpty(schema)) return null;

  return <StudyPcn uniqKey="study" state={state} dispatch={dispatch} getStudies={getStudies} />;
}

function cacheFiltersProcessor(state) {
  const cache = {
    study: {
      value: state?.study?.value
    }
  };

  sessionStorage.setItem(state.cacheKey, JSON.stringify(cache));
  return state;
}
