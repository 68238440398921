import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { orderBy } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import PatientInfoPageApi from '../../../../../../../api/patient/PatientInfoPageApi';
import { LoadingHandler } from '../../../../../../../common/elements/Loader/LoadingHandler';
import { VIEW_LOG_DATA } from '../../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../../services/auth';
import AccessDeniedTooltip from '../../../../../../AccessDeniedTooltip/AccessDeniedTooltip';
import { generateUrlByKey, useCurrentRoute } from '../../../../../../root/router';
import WithMultiRoleCheck from '../../../../../../UserRoleSwitcher/WithMultiRoleCheck';

import './LogsWidget.scss';

export default function LogsWidget({ ssuPatientId }) {
  const [logs, setLogs] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(
    function() {
      if (!ssuPatientId) {
        return;
      }
      setLoading(true);
      PatientInfoPageApi.getSitePatientLogs(ssuPatientId).then(function(res) {
        setLogs(orderBy(res.data.logItemGroups, [log => log.name.toLowerCase()], ['asc']));
        setLoading(false);
      });
    },
    [ssuPatientId]
  );

  const currentRoute = useCurrentRoute();
  const getLinkOrElement = function({ name, itemGroupTemplateId, protocolStatus, openStatusRowsCount }) {
    const displayName = name + (protocolStatus === 'Retired' ? ' - OLD' : '');
    const count = openStatusRowsCount ? openStatusRowsCount + ' Open' : null;
    if (!userHasAccessTo(VIEW_LOG_DATA)) {
      return (
        <div key={itemGroupTemplateId} className={'elw-list-item'}>
          <AccessDeniedTooltip showContentOnly={true} placement="right" align={{ offset: [20, 0] }}>
            <span>{displayName}</span>
          </AccessDeniedTooltip>
        </div>
      );
    } else {
      return (
        <WithMultiRoleCheck
          Component={Link}
          ssuPatientId={currentRoute.params.ssuPatientId}
          to={generateUrlByKey(`${currentRoute?.key}.Logs`, {
            patientId: currentRoute.params.patientId,
            ssuPatientId: currentRoute.params.ssuPatientId,
            patientItemGroupId: itemGroupTemplateId
          })}
          replace={true}
          className="elw-list-item"
          key={itemGroupTemplateId}
        >
          {displayName}
          {count ? ' - ' : ''} <span className={'elw-list-item-count'}>{count ? count : ''}</span>
        </WithMultiRoleCheck>
      );
    }
  };

  return (
    <div className="eds-logs-widget">
      <div className="elw-header">LOGS</div>
      <div className="elw-list">
        <LoadingHandler complete={!loading}>
          {isEmpty(logs) ? (
            <div className="elw-list-no-data">There are no logs for this study.</div>
          ) : (
            logs.map(getLinkOrElement)
          )}
        </LoadingHandler>
      </div>
    </div>
  );
}
