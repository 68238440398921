import React from 'react';
import { Outlet } from 'react-router-dom';

import { SitePaymentsControl } from './SitePaymentsControl/SitePaymentsControl';
import { SitePaymentsTable } from './SitePaymentsTable/SitePaymentsTable';
import { withSitePaymentsContext } from './SitePaymentsContext';

import './SitePayments.scss';

function SitePaymentsPage() {
  return (
    <div className="eds-site-payments">
      <SitePaymentsControl />
      <SitePaymentsTable />
      <Outlet />
    </div>
  );
}

export default withSitePaymentsContext(SitePaymentsPage);
