import { useCurrentUser } from '../components/root/Container/CurrentUserContainer';
import { CURRENT_USER_LAST_ACTIVE_ROLE } from '../constants/localStorageConstants';
import { RESEARCH_STACK_GLOBAL_ROLES, SSU_ROLES } from '../constants/userRoles';

import { getTittleByRole } from './personnelService';
import { notifyAfterTabReload } from './postponedNotifications';

export function selectRole(role, url, beforeReload) {
  const currentUserLastActiveRole = localStorage.getItem(CURRENT_USER_LAST_ACTIVE_ROLE);
  if (currentUserLastActiveRole !== role) {
    localStorage.setItem(CURRENT_USER_LAST_ACTIVE_ROLE, role);

    beforeReload && beforeReload(role);
    if (url) {
      window.location.href = url;
      return;
    }
    window.location.reload();
  }
}

export function useUserActiveRoleIsGlobalAndSsuRolesExists() {
  const user = useCurrentUser(),
    userHasSsuRoles = user.roles.some(role => SSU_ROLES.includes(role));
  return RESEARCH_STACK_GLOBAL_ROLES.includes(user.activeRole) && userHasSsuRoles;
}

export function notifyOnStudyPermissionsRoleSwitch(role) {
  notifyAfterTabReload(
    'NOTIFY_ON_ROLE_SWITCH',
    'info',
    `Your role has been updated\nWe have updated your role to ${getTittleByRole(
      role
    )} based on your study permissions.`,
    'nm-multiline'
  );
}
