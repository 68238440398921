import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { uniqBy } from 'lodash/array';
import { isEmpty, isEqual, isUndefined } from 'lodash/lang';

import { FinBudgetEventApi, FinBudgetSiteApi, StudySiteApi } from '../../../../../../../../../api';
import Select from '../../../../../../../../../common/data-entry/Select';
import Button from '../../../../../../../../../common/general/Button';
import { SSUFilter } from '../../../../../../../../SSUFilter/SSUFilter';
import { SSUSelect } from '../../../../../../../../SSUFilter/SSUSelect';
import { ACTIVE } from '../../../../../../../setup/Budget/BudgetDetails/budgetConstant';
import { eventTypeLabel } from '../../../../addLedgerConstants';
import { AddLedgerContext } from '../../../../AddLedgerContext';

import './MissedBudgetFilters.scss';

export function MissedBudgetFilters() {
  const {
    setFilteredFinBudgetRows,
    studySite,
    setStudySite,
    type,
    setType,
    name,
    setName,
    studySiteList,
    setStudySiteList,
    finBudgetDetails,
    setFinBudgetDetails
  } = useContext(AddLedgerContext);
  const filterByType = useCallback(({ type: finRowType }) => !type || isEqual(eventTypeLabel[finRowType], type), [
    type
  ]);
  const filterByName = useCallback(
    ({ name: finRowName }) => !name || isEqual(removeSpacesAndSetUpperCases(finRowName), name),
    [name]
  );

  const eventTypes = useMemo(
    function() {
      const eventTypes = finBudgetDetails?.events.filter(filterByName).map(({ type }) => {
        const value = eventTypeLabel[type];
        return { name: value, id: value };
      });
      return uniqBy(eventTypes, 'name');
    },
    [finBudgetDetails, filterByName]
  );

  const eventNames = useMemo(
    function() {
      const eventNames = finBudgetDetails?.events
        .filter(filterByType)
        .map(({ name }) => ({ name, id: removeSpacesAndSetUpperCases(name) }));
      return uniqBy(eventNames, 'id');
    },
    [finBudgetDetails, filterByType]
  );

  const eventsNameIsEmpty = useMemo(
    function() {
      return isEmpty(eventNames);
    },
    [eventNames]
  );

  const eventsTypeIsEmpty = useMemo(
    function() {
      return isEmpty(eventTypes);
    },
    [eventTypes]
  );

  function searchBudgetEvents() {
    const filterProperty = { ...studySite, status: ACTIVE };
    FinBudgetSiteApi.findFinBudgetSiteByFilter(filterProperty).then(function({ data: finBudgetsSite }) {
      if (!isEmpty(finBudgetsSite)) {
        const budgetSite = finBudgetsSite.find(budget => budget);
        FinBudgetEventApi.findByBudget(budgetSite.finBudget.id).then(({ data: finEvents }) => {
          const events = finEvents.filter(
            ({ encounterOverride, type }) =>
              !encounterOverride && !['EXPENSE_FIXED', 'EXPENSE_VARIABLE', 'PATIENT_REIMBURSEMENT'].includes(type)
          );
          return setFinBudgetDetails({ budgetSite, events });
        });
      } else {
        setFinBudgetDetails({ events: [] });
      }
    });
  }

  useEffect(
    function() {
      setFilteredFinBudgetRows(finBudgetDetails?.events.filter(filterByType).filter(filterByName));
    },
    [type, name, finBudgetDetails, setFilteredFinBudgetRows, filterByName, filterByType]
  );

  const valueType = useMemo(defineValueType, [eventTypes, type]);
  const valueName = useMemo(defineValueName, [eventNames, name]);

  function onChangeDynamicFilters(value, setterFunction) {
    if (!isUndefined(value)) {
      setterFunction(value?.id);
    }
  }

  const getSsuProvider = useCallback(
    function() {
      if (isEmpty(studySiteList)) {
        return StudySiteApi.getAllStudySitesAndMap().then(({ data: studySites }) => {
          setStudySiteList(studySites);
          return studySites;
        });
      } else {
        return studySiteList;
      }
    },
    [studySiteList, setStudySiteList]
  );
  const studyIdProvider = useCallback(
    function() {
      return studySite.studyId;
    },
    [studySite]
  );
  const siteIdProvider = useCallback(
    function() {
      return studySite.siteId;
    },
    [studySite]
  );
  return (
    <div className="missed-budget-filters">
      <SSUFilter
        handleSSUFilterChange={handleSSUFilterChange}
        studyIdProvider={studyIdProvider}
        siteIdProvider={siteIdProvider}
        ssuProvider={getSsuProvider}
      >
        <SSUSelect />
      </SSUFilter>
      <Select
        disabled={eventsTypeIsEmpty}
        label={'Type'}
        dataSource={eventTypes}
        className="mbf-type"
        onChange={type => onChangeDynamicFilters(type, setType)}
        onClear={() => setType(null)}
        value={valueType}
        searchable
        validate={false}
      />
      <Select
        disabled={eventsNameIsEmpty}
        label={'Event Name'}
        dataSource={eventNames}
        className="mbf-event-name"
        onChange={name => onChangeDynamicFilters(name, setName)}
        onClear={() => setName(null)}
        value={valueName}
        searchable
        validate={false}
      />
      <Button className="mbf-search-button" size="h56" disabled={isEmpty(studySite)} onClick={searchBudgetEvents}>
        Search
      </Button>
    </div>
  );

  function removeSpacesAndSetUpperCases(name) {
    return name.replace(/\s/g, '').toUpperCase();
  }

  function handleSSUFilterChange(ssu, study, site) {
    if (study && site) {
      setStudySite({ studyId: study.uniqueIdentifier, siteId: site.uniqueIdentifier });
    } else {
      setStudySite({});
    }
  }

  function defineValueType() {
    return eventTypes.find(et => et.id === type) || null;
  }

  function defineValueName() {
    return eventNames.find(en => en.id === name) || null;
  }
}
