import React, { useEffect, useMemo, useState } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { omit } from 'lodash';
import { isEmpty } from 'lodash/lang';
import moment from 'moment';

import { FinBudgetExpenseFixedApi } from '../../../../../../api';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../../constants/dateFormat';
import { toCoins } from '../../../../../../services/financial';
import { onRequestError } from '../../../../../../services/handlers';
import { NumericFormatCustom } from '../../../../setup/Budget/BudgetDetails/modal/expense-variable/AddOrEditExpenseVariableEventModal';

import { ExpenseVariableUploadedFiles } from './ExpenceVariableUploadedFiles/ExpenseVariableUploadedFiles';
import { ExpenseVariableAmountSection } from './ExpenseVariableAmountSection/ExpenseVariableAmountSection';

const maxCommentLength = 255;

export const AddExpenseVariable = ({ selectedSSU, setNewExpenseVariableData, setIsAmountValid }) => {
  const [selectedDate, setSelectedDate] = useState(moment().utc(true));
  const [finTriggerExpenseFixed, setFinTriggerExpenseFixed] = useState([]);
  const [finBudgetSite, setFinBudgetSite] = useState(null);
  const [selectedFinTrigger, setSelectedFinTrigger] = useState(null);
  const [amount, setAmount] = useState('');
  const [maximumEventAmount, setMaximumEventAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [comment, setComment] = useState('');

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const currentVariance = useMemo(() => -maximumEventAmount + totalAmount + toCoins(amount), [
    totalAmount,
    maximumEventAmount,
    amount
  ]);

  const varianceValidation = useMemo(() => maximumEventAmount !== 0 && currentVariance > 0, [
    maximumEventAmount,
    currentVariance
  ]);

  const amountTooBig = useMemo(() => toCoins(amount) > 2147483600, [amount]);

  useEffect(() => {
    setNewExpenseVariableData({
      date: selectedDate,
      finTriggerId: selectedFinTrigger?.id ?? null,
      expenseAmount: toCoins(amount),
      comment: comment.trim(),
      files: uploadedFiles.map(file => omit(file, ['id', 'contentForPreview']))
    });
    setIsAmountValid(!varianceValidation && !amountTooBig);
  }, [
    amount,
    varianceValidation,
    comment,
    selectedDate,
    selectedFinTrigger?.id,
    setIsAmountValid,
    setNewExpenseVariableData,
    uploadedFiles,
    amountTooBig
  ]);

  useEffect(() => {
    setSelectedFinTrigger(null);
    setFinTriggerExpenseFixed([]);
    if (selectedDate?.isValid()) {
      FinBudgetExpenseFixedApi.getExpenseVariableTriggers(
        selectedSSU.studyIdentifier,
        selectedSSU.siteIdentifier,
        selectedSSU.ssuIdentifier,
        selectedDate.format('YYYY-MM-DD')
      ).then(({ data: { expenseVariableTriggers, finBudgetSite } }) => {
        setFinTriggerExpenseFixed(
          expenseVariableTriggers?.map(trigger => ({
            ...trigger,
            label: trigger.name
          })) ?? []
        );
        setFinBudgetSite(finBudgetSite);
      });
    }
  }, [selectedSSU, selectedDate]);

  useEffect(() => {
    if (selectedSSU?.ssuIdentifier && selectedFinTrigger && finBudgetSite?.finBudget?.id) {
      FinBudgetExpenseFixedApi.getExpenseVariableMaximumAmount(
        selectedSSU.ssuIdentifier,
        selectedSSU.studyIdentifier,
        selectedSSU.siteIdentifier,
        selectedFinTrigger.id,
        finBudgetSite.finBudget.id
      ).then(({ data: { maxAllowanceAmount, totalAmount } }) => {
        setMaximumEventAmount(maxAllowanceAmount);
        setTotalAmount(totalAmount);
      }, onRequestError);
    } else {
      setMaximumEventAmount(0);
    }
  }, [selectedSSU, selectedFinTrigger, finBudgetSite]);

  const amountErrorMessages = useMemo(() => {
    if (varianceValidation) {
      return 'The amount entered exceeds the remaining Variance.';
    }
    if (amountTooBig) {
      return 'Amount cannot exceed $21,474,836.';
    }
    return '';
  }, [varianceValidation, amountTooBig]);

  return (
    <Box display="flex" flexDirection="column" gap="16px" margin="20px 0">
      <DesktopDatePicker
        label="Event Date"
        value={selectedDate}
        onChange={newValue => setSelectedDate(newValue)}
        format={DD_SLASH_MMM_SLASH_YYYY}
        slotProps={{
          textField: { variant: 'standard', required: true }
        }}
        localeText={{ fieldMonthPlaceholder: params => (params.contentType === 'letter' ? 'MMM' : 'MM') }}
      />
      <Autocomplete
        noOptionsText={
          !isEmpty(finTriggerExpenseFixed) ? 'No Results Available' : 'No expenses available for this date'
        }
        options={finTriggerExpenseFixed}
        onChange={(event, value) => {
          setSelectedFinTrigger(value);
          setComment('');
          setMaximumEventAmount(0);
          setAmount('');
          setTotalAmount(0);
          setUploadedFiles([]);
        }}
        value={selectedFinTrigger}
        renderInput={params => (
          <TextField {...params} label="Expense - Variable Event Name" variant="standard" required />
        )}
      />
      {selectedFinTrigger && (
        <>
          <TextField
            label="Amount"
            required
            variant="standard"
            InputProps={{
              inputComponent: NumericFormatCustom
            }}
            value={amount}
            onChange={({ target: { value } }) => setAmount(value)}
            error={varianceValidation || amountTooBig}
            helperText={amountErrorMessages}
          />
          <ExpenseVariableAmountSection
            amount={toCoins(amount)}
            maximumEventAmount={maximumEventAmount}
            totalAmount={totalAmount + toCoins(amount)}
            variance={currentVariance}
          />
          <TextField
            label="Comment"
            variant="standard"
            value={comment}
            multiline
            onChange={({ target: { value } }) => setComment(value)}
            inputProps={{ maxLength: maxCommentLength }}
            helperText={`${comment?.length ?? 0}/${maxCommentLength}`}
            sx={{ '.MuiFormHelperText-root': { textAlign: 'end' } }}
          />
          <ExpenseVariableUploadedFiles uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} />
        </>
      )}
    </Box>
  );
};
