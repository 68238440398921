import AddOrEditEncounterBudgetEvent from '../../modal/encounter/AddOrEditEncounterBudgetEvent';
import AddOrEditEncounterItemGroupBudgetEvent from '../../modal/encounter-item-group/AddOrEditEncounterItemGroupBudgetEvent';
import AddOrEditEncounterStatusChangeEventModal from '../../modal/encounter-status-change/AddOrEditEncounterStatusChangeEventModal';
import AddOrEditExpenseFixedBudgetEvent from '../../modal/expense-fixed/AddOrEditExpenseFixedBudgetEvent';
import { AddOrEditExpenseVariableEventModal } from '../../modal/expense-variable/AddOrEditExpenseVariableEventModal';
import AddOrEditItemGroupGeneralBudgetEvent from '../../modal/item-group/AddOrEditItemGroupGeneralBudgetEvent';
import AddOrEditPatientReimbursementBudgetEvent from '../../modal/patient-reimbursement/AddOrEditPatientReimbursementBudgetEvent';
import AddOrEditPatientStipendBudgetEvent from '../../modal/patient-stipend/AddOrEditPatientStipendBudgetEvent';
import AddOrEditStatusChangeEventModal from '../../modal/status-change/AddOrEditStatusChangeEventModal';

export const modalTypes = {
  EXPENSE_FIXED: AddOrEditExpenseFixedBudgetEvent,
  EXPENSE_VARIABLE: AddOrEditExpenseVariableEventModal,
  ENCOUNTER: AddOrEditEncounterBudgetEvent,
  UNEXPECTED_ENCOUNTER: AddOrEditEncounterBudgetEvent,
  SITUATIONAL_ENCOUNTER: AddOrEditEncounterBudgetEvent,
  ENCOUNTER_ITEM_GROUP: AddOrEditEncounterItemGroupBudgetEvent,
  UNEXPECTED_ITEM_GROUP: AddOrEditEncounterItemGroupBudgetEvent,
  SITUATIONAL_ITEM_GROUP: AddOrEditEncounterItemGroupBudgetEvent,
  ITEM_GROUP_PATIENT_STIPEND: AddOrEditPatientStipendBudgetEvent,
  ITEM_GROUP_GENERAL: AddOrEditItemGroupGeneralBudgetEvent,
  STATUS_CHANGE: AddOrEditStatusChangeEventModal,
  RATIO_STATUS_CHANGE: AddOrEditStatusChangeEventModal,
  ENCOUNTER_STATUS_CHANGE: AddOrEditEncounterStatusChangeEventModal,
  PATIENT_REIMBURSEMENT: AddOrEditPatientReimbursementBudgetEvent
};
